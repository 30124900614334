import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';

import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import { UPDATE_EMAIL } from '@/hocs/searchPoolProfile/withUpdateEmail';
import {
  ATS_NAMES_BY_TYPE,
  SMARTRECRUITERS,
  TEAMTAILOR,
  WELCOMEKIT,
  RECRUITEE,
  WORKABLE,
  GREENHOUSE,
  ASHBY,
  PINPOINT,
} from '@/common/reveal';
import useAtsFilterOptions from '@/graphql/hooks/clients/useClientAtsFiltersOptions';
import { getTranslatedText } from '@/common';
import GenericButton from '@/components/Common/GenericButton';
import GenericModal from '@/components/Common/GenericModal';
import { UPLOAD_TO_ATS_JOB } from '../../../../graphql/searchPoolProfile';

import styles from './AddToATSJobModal.module.less';

const ATS_REQUIRING_EMAIL = [
  SMARTRECRUITERS,
  /* TEAMTAILOR, */
  WELCOMEKIT,
  WORKABLE,
  PINPOINT,
];

const ATS_WITH_JOB_SPECIFIC_STAGES = [
  RECRUITEE,
  TEAMTAILOR,
  WELCOMEKIT,
  GREENHOUSE,
  ASHBY,
  PINPOINT,
];

const AddToATSJobModal = ({
  clientId,
  searchPoolId,
  mainMissionId,
  profileId,
  onClose,
  ats,
  profileEmail,
}) => {
  const { t } = useTranslation();

  const { formState, formOptions, onSubmit, uploading } =
    useAddToATSJobModalFormState({
      clientId,
      searchPoolId,
      mainMissionId,
      profileId,
      onClose,
      ats,
      t,
    });

  if (_.contains(ATS_REQUIRING_EMAIL, ats) && !profileEmail) {
    return (
      <AddEmailModal
        t={t}
        onClose={onClose}
        ats={ats}
        searchPoolId={searchPoolId}
        profileId={profileId}
      />
    );
  }

  const atsName = ATS_NAMES_BY_TYPE[ats] || 'ATS';
  return (
    <GenericModal
      className={styles.atsModal}
      open
      onClose={onClose}
      size='tiny'
    >
      <Modal.Header>
        {t('reveal.addToATS.addContact', { ats: atsName })}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            selection
            search
            label={`${atsName} job`}
            options={formOptions.jobs}
            value={formState.jobId}
            onChange={(e, { value }) => formState.setJobId(value)}
            loading={formState.jobsLoading}
            selectOnBlur={false}
          />

          <Form.Dropdown
            selection
            search
            selectOnBlur={false}
            label='Job stage'
            options={formOptions.stages}
            value={formState.stageId}
            onChange={(e, { value }) => formState.setStageId(value)}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <div className={styles.actions}>
          <GenericButton size='big' primacy='secondary' onClick={onClose}>
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            disabled={formState.disabled || uploading}
            onClick={onSubmit}
          >
            {t('reveal.addToATS.submit')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

const useAddToATSJobModalFormState = ({
  clientId,
  searchPoolId,
  mainMissionId,
  profileId,
  onClose,
  ats,
  t,
}) => {
  const notifications = useNotificationSystem();
  const [jobId, setJobId] = useState(mainMissionId);
  const [stageId, setStageId] = useState();
  const { data: atsFilterOptionsData, loading: jobOptionsLoading } =
    useAtsFilterOptions(clientId, {
      fetchPolicy: 'network-only',
    });

  const sourceJobId = jobId;
  const jobOptions = getJobOptions({
    atsFilterOptionsData,
  });

  const stageOptions = getStageOptions({
    atsFilterOptionsData,
    jobId,
    ats,
  });

  const [uploadToATSJob, { loading: uploading }] =
    useMutation(UPLOAD_TO_ATS_JOB);

  useEffect(() => {
    setStageId(null);
  }, [jobId]);

  useEffect(() => {
    if (!stageId && !_.isEmpty(stageOptions)) {
      setStageId(stageOptions[0]?.value);
    }
  }, [stageId, stageOptions]);

  const onSubmit = async () => {
    try {
      const variables = {
        searchPoolId,
        input: {
          sourceJobId,
          sourceStageId: stageId,
          profileId,
        },
      };

      await uploadToATSJob({ variables });

      notifications.success(t('reveal.addToATS.success'));

      onClose();
    } catch (e) {
      notifications.error(t('reveal.addToATS.error'));
    }
  };

  const formState = {
    jobId,
    setJobId,
    jobsLoading: jobOptionsLoading,
    stageId,
    setStageId,
    disabled: !jobId || !stageId,
  };

  const formOptions = {
    jobs: jobOptions,
    stages: stageOptions,
  };

  return {
    formState,
    formOptions,
    onSubmit,
    uploading,
  };
};

export const getStageOptions = ({ atsFilterOptionsData, jobId, ats }) => {
  const filtersOptions =
    atsFilterOptionsData?.client?.revealProjects?.[0]?.connectors?.[0]
      ?.filterOptions || {};

  if (!_.contains(ATS_WITH_JOB_SPECIFIC_STAGES, ats)) {
    const stageOptions = _.map(filtersOptions?.stageNames, ({ id, name }) => ({
      text: name,
      value: id,
    }));
    return stageOptions;
  }

  if (!jobId) {
    return [];
  }
  const job = _.findWhere(filtersOptions?.jobs, { id: jobId });
  const stageOptions = _.map(job?.stageNames, ({ id, name }) => ({
    text: name,
    value: id,
  }));
  return stageOptions;
};

export const getJobOptions = ({ atsFilterOptionsData }) => {
  const archivedTranslated = getTranslatedText({
    default: 'Archived',
    fr: 'Archivé',
    de: 'Archiviert',
  });
  const filtersOptions =
    atsFilterOptionsData?.client?.revealProjects?.[0]?.connectors?.[0]
      ?.filterOptions || {};
  const isLocation = (locationName) =>
    !!locationName && locationName.toLowerCase().indexOf('no location') < 0;
  const jobOptions = _.map(
    filtersOptions?.jobs,
    ({ id, name, locationName, isArchived }) => ({
      text: `${name || ''}${
        isLocation(locationName) ? ` (${locationName})` : ''
      }${isArchived ? ` (${archivedTranslated})` : ''}`,
      value: id,
    }),
  );
  return jobOptions;
};

export const getUsersOptions = ({ atsFilterOptionsData }) => {
  const filtersOptions =
    atsFilterOptionsData?.client?.revealProjects?.[0]?.connectors?.[0]
      ?.filterOptions || {};
  const usersOptions = _.map(
    _.filter(filtersOptions?.users, (user) => user.firstname || user.lastname),
    ({ id, firstname, lastname }) => ({
      text: `${firstname || ''} ${lastname || ''}`,
      value: id,
    }),
  );
  return usersOptions;
};

const AddEmailModal = ({ onClose, ats, t, searchPoolId, profileId }) => {
  const notifications = useNotificationSystem();
  const [email, setEmail] = useState('');
  const [updateEmail, { loading: updatingEmail }] =
    useUpdateEmailMutation(searchPoolId);
  const onSubmit = async () => {
    try {
      await updateEmail(profileId, email);
      notifications.success(t('reveal.addToATS.updateEmail.success'));
    } catch (e) {
      notifications.error(t('reveal.addToATS.updateEmail.error'));
    }
  };
  const atsName = ATS_NAMES_BY_TYPE[ats] || 'ATS';
  return (
    <GenericModal open onClose={onClose} size='tiny'>
      <Modal.Header>
        {t('reveal.addToATS.addContact', { ats: atsName })}
      </Modal.Header>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          <div className='input-container'>
            <div className='input-label'>
              {t('reveal.profileEdition.email')}
            </div>
            <div className='input-description'>
              {t('reveal.addToATS.updateEmail.emailRequiredBeforeUpload', {
                ats: atsName,
              })}
            </div>
            <Form.Input
              fluid
              required
              value={email}
              onChange={(e) => setEmail(e?.target?.value)}
            />
          </div>
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <div className={styles.actions}>
          <GenericButton size='big' primacy='secondary' onClick={onClose}>
            {t('common.cancel')}
          </GenericButton>
          <GenericButton
            size='big'
            disabled={!email || updatingEmail}
            onClick={onSubmit}
          >
            {t('reveal.addToATS.updateEmail.update')}
          </GenericButton>
        </div>
      </Modal.Actions>
    </GenericModal>
  );
};

const useUpdateEmailMutation = (searchPoolId) => {
  const [mutate, { loading }] = useMutation(UPDATE_EMAIL);

  return [
    (profileId, email) =>
      mutate({
        variables: { searchPoolId, input: { id: profileId, email } },
      }),
    { loading },
  ];
};

export default AddToATSJobModal;
