import gql from 'graphql-tag';
import { QueryHookOptions, useQuery } from '@apollo/client';

import useClientId from '@/hooks/router/useClientId';
import { CustomNomenclature } from '@/types/client';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { getTranslatedText } from '@/common/helpers/translatableText';
import { useCallback } from 'react';
import TranslatableText from '@/graphql/fragments/TranslatableText';

const CLIENT_CUSTOM_NOMENCLATURE = gql`
  query getClientCustomNomenclature($clientId: ID!) {
    client(id: $clientId) {
      id
      customNomenclatures {
        translationKey
        value {
          ...TranslatableText
        }
      }
    }
  }

  ${TranslatableText}
`;

interface ClientWithCustomNomenclatures {
  id: string;
  customNomenclatures: CustomNomenclature[];
}

type SimpleTranslationRecord = Record<string, object | string>;

const buildJSONFromNomenclature = (nomenclatures: CustomNomenclature[]) => {
  const frJSON: SimpleTranslationRecord = {};
  const enJSON: SimpleTranslationRecord = {};
  const deJSON: SimpleTranslationRecord = {};

  // This works by mutating the values above. It is usually done with a reduce
  // but to simplify readability and optimize loop count, I opted for a simple for loop
  _.forEach(nomenclatures, ({ translationKey, value }) => {
    const parts = translationKey.split('.');
    let currentEN = enJSON;
    let currentFR = frJSON;
    let currentDE = deJSON;

    for (let i = 0; i < parts.length; i++) {
      const shouldUseValue = i === parts.length - 1;
      const part = parts[i];

      currentEN[part] = shouldUseValue
        ? getTranslatedText('en', value)
        : currentEN[part] || {};
      // ts-ignore because last value could be a string and instanceof has no effect
      // @ts-ignore
      currentEN = currentEN[part];

      currentFR[part] = shouldUseValue
        ? getTranslatedText('fr', value)
        : currentFR[part] || {};
      // @ts-ignore
      currentFR = currentFR[part];

      currentDE[part] = shouldUseValue
        ? getTranslatedText('de', value)
        : currentDE[part] || {};
      // @ts-ignore
      currentDE = currentDE[part];
    }
  });

  return { fr: frJSON, en: enJSON, de: deJSON };
};

export function useI18nOverrides() {
  const { i18n } = useTranslation();
  return useCallback(
    ({
      fr,
      en,
      de,
    }: {
      fr: SimpleTranslationRecord;
      en: SimpleTranslationRecord;
      de: SimpleTranslationRecord;
    }) => {
      i18n.addResourceBundle(
        'fr',
        'translations',
        fr,
        // deep => merge with existing translations
        true,
        // overwrite => replace if existing
        true,
      );

      i18n.addResourceBundle('en', 'translations', en, true, true);
      i18n.addResourceBundle('de', 'translations', de, true, true);
      i18n.changeLanguage(i18n.resolvedLanguage);
    },
    [i18n],
  );
}

export function useClientCustomTranslations(
  queryHookOptions: QueryHookOptions = {},
) {
  const clientId = useClientId();

  const overrideI18n = useI18nOverrides();

  return useQuery<{ client: ClientWithCustomNomenclatures }>(
    CLIENT_CUSTOM_NOMENCLATURE,
    {
      onCompleted: (data) => {
        const { customNomenclatures } = data.client;
        const { fr, en, de } = buildJSONFromNomenclature(customNomenclatures);
        overrideI18n({ fr, en, de });
      },
      ...queryHookOptions,
      variables: {
        clientId,
      },
    },
  );
}
