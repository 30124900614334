import {
  ArchivedState,
  PipelineState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';

export const states = [
  PipelineState.PENDING,
  PipelineState.IN_PROGRESS,
  PipelineState.REPLIED,
  PipelineState.INTERESTED,
  PipelineState.HIRED,
] as const;


export const segments = [...states, ArchivedState.ARCHIVED] as const;

export type Segment = typeof segments[number];
