import React, { useContext, type PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { getFullname } from '@/common/helpers/person';
import { getShortLanguage } from '@/common/utils/i18n';
import { getTranslatedText } from '@/common/helpers/translatableText';
import type { TimelineItem } from '@/types/contactData';
import type { Author } from '@/types/author';

import TimelineItemDiplaySettingsContext from '../TimelineItemDiplaySettingsContext';

import styles from './AuthorAndDate.module.less';

type AuthorAndDateProps = {
  timelineItem?: TimelineItem;
  author?: Author;
  date?: string | number | Date;
  atsLogo?: string;
};

const AuthorAndDate = ({
  timelineItem,
  author,
  date,
  atsLogo,
  children,
}: PropsWithChildren<AuthorAndDateProps>) => {
  const { t, i18n } = useTranslation();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const finalAuthor = author || timelineItem?.author;
  const finalDate = date || timelineItem?.date;
  const name = ['profile', 'recruiter'].includes(finalAuthor?.firstname ?? '')
    ? ''
    : getFullname(finalAuthor);
  const { showAgencies, currentLinkedProfileTitle } = useContext(
    TimelineItemDiplaySettingsContext,
  );

  if (!name && !finalDate) {
    return null;
  }

  const agencyTitle = timelineItem?.linkedProfileReference?.title;
  const agency = agencyTitle
    ? getTranslatedText(lang, agencyTitle)
    : currentLinkedProfileTitle;
  const agencySuffix = showAgencies ? `(${agency}) ` : '';

  return (
    <div className={styles.date}>
      {atsLogo && (
        <img className={styles.atsIcon} alt='ats logo' src={atsLogo} />
      )}
      {name && <b>{`${t('common.by')} ${name} ${agencySuffix}`}</b>}
      {finalDate && t('common.formattedDate', { date: new Date(+finalDate) })}
      {children}
    </div>
  );
};

export default AuthorAndDate;
