import React, {
  useMemo,
  useState,
  useCallback,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';
import {
  useApolloClient,
  useMutation,
  ApolloQueryResult,
} from '@apollo/client';
import _ from 'underscore';
import {
  useRouteMatch,
  useHistory,
  generatePath,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ADVANCE_TASK_FULL_PROFILE,
  COMPLETE_TASK_FULL_PROFILE,
  SNOOZE_TASK_FULL_PROFILE,
} from '@/graphql/tasks';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import {
  ContactFlowSequence,
  SearchPoolProfile,
} from '@/types/searchPoolProfile';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import useClientProfileCustomFields from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { ENRICHED_SEARCH_POOL_PROFILE_MISSIONS } from '@/graphql/enrichedSearchPoolProfile';
import useMergeTagsProfileContext from '@/common/mergeTags/useMergeTagsProfileContext';
import useMissionId from '@/hooks/router/useMissionId';

import useEnrichedCandidateViewProfile, {
  usePrefetchProfiles,
} from './useEnrichedCandidateViewProfile';

type Task = {
  id?: string;
  target?: {
    /**
     * Profile Id
     */
    id?: string;
  };
};

interface ContextInterface {
  profileId: string;
  loadingProfile: boolean;
  profileError: any;
  profile: SearchPoolProfile;
  previousTaskId: number;
  nextTaskId: number;
  tasksCount: number;
  currentTask: any;
  currentSequenceTask: any;
  currentTaskIndex: number;
  goToNextTask: () => void;
  goToPreviousTask: () => void;
  handleCompleteTask: (taskParam?: any, nextAction?: any) => void;
  completeTaskLoading: boolean;
  snoozeExplicitTask: (taskParam?: any) => void;
  advanceExplicitTask: (taskParam?: any) => void;
  onClose: () => void;
  refetch: () => Promise<ApolloQueryResult<any>>;
  currentSequence: ContactFlowSequence;
  currentSequenceWithDeepActionsSnippets: ContactFlowSequence;
  mergeTagsProfileContext: any;
  needMergeTagEditorRefresh: boolean;
  refreshMergeTagEditor: () => void;
  missionId?: string;
  setMissionId: Dispatch<SetStateAction<string | undefined>>;
}

interface ProviderProps {
  profileId?: string;
  nextProfileId?: string;
  previousProfileId?: string;
  tasks?: Task[];
  tasksIds?: any;
  searches?: any;
  activeProjectState?: any;
  clientId: string;
  needNetworkCall?: boolean;
  profileFromSource?: any;
  messagingClient?: any;
}

const CandidateViewContext = React.createContext<ContextInterface | null>(null);

const CandidateViewProvider = ({
  children,
  profileId,
  profileFromSource,
  tasks,
  tasksIds,
  searches,
  activeProjectState,
  clientId,
  needNetworkCall,
  messagingClient,
  ...props
}: PropsWithChildren<ProviderProps>) => {
  const defaultMissionId = useMissionId();
  const [missionId, setMissionId] = useState(defaultMissionId);
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const isPlugin = useIsPlugin();
  const history = useHistory();
  const location = useLocation();
  const notif = useNotificationSystem();
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();

  const { profileCustomFields } = useClientProfileCustomFields(clientId);
  const [completeTask, { loading: completeTaskLoading }] = useMutation(
    COMPLETE_TASK_FULL_PROFILE,
    {
      refetchQueries: [
        'getSearchPoolJobProfileResults',
        'getEnrichedSearchPoolProfile', // refetch profile because backend will surely update custom fields after form is completed
        'getTasksCount',
      ],
      update: (cache, data) => {
        cache.modify({
          id: cache.identify({
            __typename: 'Client',
            id: clientId,
          }),
          fields: {
            contactFlowTasks(value) {
              return _.reject(value, {
                __ref: data.data?.project?.completeTask?.task?.id,
              });
            },
            explicitTasks(value) {
              return _.reject(value, {
                __ref: data.data?.project?.completeTask?.task?.id,
              });
            },
          },
        });
      },
    },
  );
  const [snoozeTask] = useMutation(SNOOZE_TASK_FULL_PROFILE);
  const [advanceTask] = useMutation(ADVANCE_TASK_FULL_PROFILE);
  const { params, path } = useRouteMatch<{ selectedTaskId: string }>();
  const [needMergeTagEditorRefresh, setNeedMergeTagEditorRefresh] =
    useState(false);

  const profileTasks = useMemo(
    () => _.filter(tasks || [], (task) => task.target?.id === profileId),
    [tasks, profileId],
  );

  const currentTask = useMemo(() => {
    if (isPlugin && activeProjectState) {
      return activeProjectState.activeTask;
    }

    return (
      _.findWhere(tasks || [], { id: params?.selectedTaskId }) ||
      profileTasks?.[0] ||
      tasks?.[0]
    );
  }, [activeProjectState, tasks, profileTasks, params, isPlugin]);

  const ProfileId = profileId ?? (currentTask?.target?.id || '');
  const relevantCriteria = findRelevantCriteria(searches);

  const {
    loading: loadingProfile,
    error: profileError,
    data,
    refetch,
  } = useEnrichedCandidateViewProfile({
    searchPoolId: 'reveal',
    profileId: ProfileId,
    relevantCriteria,
    skip:
      !ProfileId || (profileFromSource && profileFromSource.id === ProfileId),
    needNetworkCall,
  });

  const refetchProfileMissionsOnly = React.useCallback(async () => {
    await apolloClient.query({
      query: ENRICHED_SEARCH_POOL_PROFILE_MISSIONS,
      variables: {
        searchPoolId: 'reveal',
        id: ProfileId,
        relevantCriteria,
        skip: !ProfileId,
      },
      fetchPolicy: 'network-only',
    });
  }, [apolloClient, ProfileId, relevantCriteria]);

  const profile =
    profileFromSource && profileFromSource.id === ProfileId
      ? profileFromSource
      : data?.searchPool?.enrichedProfile;

  React.useEffect(() => {
    if (!loadingProfile && messagingClient) {
      messagingClient.postMessageToContentScript({
        type: 'loadedProfile',
        data: { loadedProfile: profile || null },
      });
    }
    // eslint-disable-next-line
  }, [loadingProfile, profile]);

  const contactFlow = profile?.contactFlow || {};
  const resumeData = profile?.resumData || {};
  if (!ProfileId && !currentTask) {
    throw new Error(`Missing profileId and task`);
  }

  const currentTaskIndex = _.indexOf(tasksIds, currentTask?.id);
  const previousTaskId = currentTaskIndex > 0 && tasksIds[currentTaskIndex - 1];
  const nextTaskId = currentTaskIndex !== -1 && tasksIds[currentTaskIndex + 1];
  const nextTask = _.find(tasks || [], (task) => task?.id === nextTaskId);

  const previousTask = _.find(
    tasks || [],
    (task) => task?.id === previousTaskId,
  );
  const nextProfileId = props.nextProfileId ?? nextTask?.target?.id;
  const previousProfileId = props.previousProfileId ?? previousTask?.target?.id;

  const currentSequenceTask = useMemo(() => {
    const currTask = profile?.currentSequenceInfo?.currentTask;
    return currentTask?.id === currTask?.id ? currentTask : currTask;
    // eslint-disable-next-line
  }, [data, currentTask, profile?.currentSequenceInfo]);

  const mergeTagsProfileContext = useMergeTagsProfileContext({
    clientId,
    profile,
    missionId,
  });

  const currentSequencePreInstantiated = useMemo(() => {
    const currentSequence = _.findWhere(contactFlow?.sequences, {
      isCurrent: true,
    });
    if (!currentSequence) {
      return null;
    }
    return {
      ...currentSequence,
      actions: _.map(currentSequence.actions, (action) =>
        MergeTagsService.preInstantiateContactFlowAction({
          action,
          evaluatorContext: mergeTagsProfileContext,
        }),
      ),
    };
    // eslint-disable-next-line
  }, [contactFlow, profileCustomFields, resumeData]);

  const currentSequenceWithDeepActionsSnippets = useMemo(() => {
    const currentSequence = _.findWhere(contactFlow?.sequences, {
      isCurrent: true,
    });
    if (!currentSequence) {
      return null;
    }
    return {
      ...currentSequence,
      actions: _.map(currentSequence.actions, (action) =>
        MergeTagsService.preInstantiateContactFlowAction({
          action,
          evaluatorContext: mergeTagsProfileContext,
          withDeepSnippets: true,
        }),
      ),
    };
    // eslint-disable-next-line
  }, [contactFlow, profileCustomFields, resumeData]);

  const getNextProfileTask = useCallback(
    ({ completedTaskId }: { completedTaskId?: string }) => {
      const currentTaskId = (
        currentTask?.id ||
        currentTask?.actionId ||
        ''
      ).replace('task-for-', '');

      const dueExplicitTasks = _.filter(
        profile?.explicitTasks,
        ({ id, dueDate }) => {
          // Same check as in <TaskLink>
          return (
            (!dueDate || dueDate <= new Date().toISOString()) &&
            completedTaskId &&
            completedTaskId !== id
          );
        },
      );

      const nextExplicitTask = _.find(
        dueExplicitTasks,
        ({ id }) => id !== currentTaskId,
      );

      const currentSequence = _.findWhere(
        profile?.contactFlow?.sequences || [],
        {
          isCurrent: true,
        },
      );

      const currentTaskIndex = _.findIndex(
        currentSequence?.actions || [],
        (action) => {
          return action.actionId === currentTaskId;
        },
      );

      const nextSequenceTask = currentSequence?.actions[currentTaskIndex + 1];

      if (
        !nextSequenceTask ||
        nextSequenceTask.completion?.isCompleted ||
        nextSequenceTask.trigger.type !== 'manual-trigger'
      ) {
        return nextExplicitTask;
      }

      return nextSequenceTask;
    },
    [currentTask, profile],
  );

  const goToNextTask = (completedTaskId?: string) => {
    const nextProfileTask = getNextProfileTask({ completedTaskId });

    // Special case where the profile has an immediate task coming in
    if (
      isPlugin &&
      activeProjectState?.navMode === 'focus' &&
      nextProfileTask
    ) {
      return;
    }

    if (isPlugin && activeProjectState?.navMode === 'focus') {
      activeProjectState.goToNextTask();
      return;
    }

    const nextTaskPath = nextTaskId
      ? generatePath(path, { ...params, selectedTaskId: nextTaskId })
      : '#';
    if (nextTaskId) {
      history.push(nextTaskPath);
    } else {
      onClose();
    }
  };

  const goToPreviousTask = () => {
    const previousTaskPath = previousTaskId
      ? generatePath(path, { ...params, selectedTaskId: previousTaskId })
      : '#';
    if (previousTaskId) {
      history.push(previousTaskPath);
    }
  };

  const markTaskAsCompleted = useCallback(
    ({
      profileId, // eslint-disable-line
      taskId,
      sequenceId,
    }: {
      profileId: string;
      taskId: string;
      sequenceId: string;
    }) => {
      // Mark as completed distantly (from hiresweet.com to plugin in another tab)
      publishSubscriptionEvent('onTaskUpdated', {
        profileId,
        taskId,
        sequenceId,
      });

      // Mark as completed inside plugin
      if (isPlugin && activeProjectState) {
        activeProjectState.markTaskAsCompleted({ taskId });
      }
    },
    [isPlugin, activeProjectState, publishSubscriptionEvent],
  );

  const handleCompleteTask = async (taskParam?: any) => {
    try {
      await completeTask({
        variables: {
          input: {
            projectId: taskParam?.projectId,
            queueId: taskParam?.queueId,
            sequenceId: taskParam?.sequenceId,
            missionId: taskParam?.missionId,
            task: {
              id: taskParam?.id,
              type: taskParam?.type,
              ...(taskParam?.subtype && { subtype: taskParam.subtype }),
              ...(taskParam?.form && { form: taskParam.form }),
              ...(taskParam?.helpText && { helpText: taskParam.helpText }),
            },
            profileId: ProfileId,
          },
        },
      });
      await refetchProfileMissionsOnly();

      markTaskAsCompleted({
        profileId: ProfileId,
        taskId: taskParam?.id,
        sequenceId: taskParam?.sequenceId,
      });

      notif.success(
        t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
      );

      goToNextTask(taskParam.id);
    } catch (e) {
      console.error('Error marking task as completed', e);
      notif.error(t('reveal.candidatesView.timeline.explicitTasks.editError'));
    }
  };

  const snoozeExplicitTask = async (taskParam?: any) => {
    try {
      await snoozeTask({
        variables: {
          input: {
            id: taskParam?.id,
            dueDate: taskParam?.dueDate,
            sequenceId: taskParam?.sequenceId,
            missionId: taskParam?.missionId,
            profileId: ProfileId,
            ...(!_.isEmpty(taskParam?.snoozeReasonId) && {
              snoozeReasonId: taskParam.snoozeReasonId,
              snoozeReasonTitle: taskParam.snoozeReasonTitle,
            }),
            ...(taskParam?.snoozeComment && {
              snoozeComment: taskParam?.snoozeComment,
            }),
          },
        },
      });
      publishSubscriptionEvent('onTaskUpdated', {
        profileId: ProfileId,
        taskId: taskParam?.id,
        sequenceId: taskParam?.sequenceId,
      });
      notif.success(
        t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
      );
      if (currentTask?.id === taskParam?.id) {
        setTimeout(() => goToNextTask(), 500);
      }
    } catch (e) {
      notif.error(t('reveal.candidatesView.timeline.explicitTasks.editError'));
    }
  };

  const advanceExplicitTask = async (taskParam?: any) => {
    try {
      await advanceTask({
        variables: {
          input: {
            id: taskParam?.id,
            sequenceId: taskParam?.sequenceId,
            missionId: taskParam?.missionId,
            profileId: ProfileId,
          },
        },
      });
      publishSubscriptionEvent('onTaskUpdated', {
        profileId: ProfileId,
        taskId: taskParam?.id,
        sequenceId: taskParam?.sequenceId,
      });
      notif.success(
        t('reveal.candidatesView.timeline.explicitTasks.editSuccess'),
      );
    } catch (e) {
      notif.error(t('reveal.candidatesView.timeline.explicitTasks.editError'));
    }
  };

  const onCloseUrl = generatePath(
    path.replace('/focus/:selectedTaskId', ''),
    params,
  );
  const onClose = () => history.push({ ...location, pathname: onCloseUrl });

  // Prefetch next profile
  const nextProfileIds = React.useMemo(
    () => _.compact([nextProfileId]),
    [nextProfileId],
  );
  usePrefetchProfiles(nextProfileIds, { relevantCriteria }, 300);

  // Prefetch previous profile
  const previousProfileIds = React.useMemo(
    () => _.compact([previousProfileId]),
    [previousProfileId],
  );
  usePrefetchProfiles(previousProfileIds, { relevantCriteria }, 500);

  const refreshMergeTagEditor = () => {
    setNeedMergeTagEditorRefresh(true);
    setTimeout(() => {
      setNeedMergeTagEditorRefresh(false);
    }, 1000);
  };

  return (
    <CandidateViewContext.Provider
      value={{
        missionId,
        setMissionId,
        profileId: ProfileId,
        loadingProfile,
        profileError,
        profile,
        currentTask,
        currentSequenceTask,
        currentTaskIndex,
        tasksCount: (tasksIds || []).length,
        previousTaskId,
        nextTaskId,
        goToNextTask,
        goToPreviousTask,
        handleCompleteTask,
        completeTaskLoading,
        snoozeExplicitTask,
        advanceExplicitTask,
        onClose,
        refetch,
        currentSequence: currentSequencePreInstantiated,
        currentSequenceWithDeepActionsSnippets,
        mergeTagsProfileContext,
        needMergeTagEditorRefresh,
        refreshMergeTagEditor,
      }}
    >
      {children}
    </CandidateViewContext.Provider>
  );
};

function useCandidateViewContext() {
  const context = React.useContext(CandidateViewContext);
  if (!context) {
    throw new Error(
      'useCandidateViewContext must be used within a CandidateViewContextProvider',
    );
  }
  return context;
}

export const findRelevantCriteria = (searches: any, scores?: any) => {
  if (_.isEmpty(searches)) {
    return null;
  }
  if (searches.length === 1 || _.isEmpty(scores)) {
    return searches[0];
  }
  let maxScore = (scores || [])[0] || null;
  let maxIndex = 0;
  for (let index = 0; index < scores.length; index++) {
    const score = scores[index];
    if (score > maxScore) {
      maxScore = score;
      maxIndex = index;
    }
  }
  return (searches || [])[maxIndex] || null;
};

export { CandidateViewProvider, useCandidateViewContext };
