import * as Sentry from '@sentry/browser';
import _, { compose } from 'underscore';
import React, { useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { withTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { Form } from 'semantic-ui-react';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import InputControl from '@/components/InputControl';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import {
  REGEXP_GITHUB,
  REGEXP_LINKEDIN,
  REGEXP_STACKOVERFLOW,
} from '@/common/validators';
import { UPDATE_RESUME_DATA_IN_SEARCH_POOL } from '@/graphql/searchPoolProfile';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { createCustomFieldsPayload } from '@/common/customFields';
import contextToProps, { ContextToProps } from '@/hocs/contextToProps';
import CustomField from '@/revealComponents/CustomField';

import './CandidateEditForm.css';
import { DatePickerControl, SelectControl } from '@/components/FormFields';
import { Gender, genderArray } from '@/types/gender';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import GenericButton from '@/components/Common/GenericButton';
import useHumanContactCategorySubtypes from '@/graphql/hooks/clients/useHumanCategorySubtypes';
import { getTranslatedText } from '@/common';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

const isRequiredIfOtherEmpty = {
  is: _.isEmpty,
  then: yup.string().required(),
  otherwise: yup.string(),
};

const ProfileSchema = yup.object().shape(
  {
    firstname: yup.string().when('lastname', isRequiredIfOtherEmpty),
    lastname: yup.string().when('firstname', isRequiredIfOtherEmpty),
    headline: yup.string(),
    email: yup.string().email(),
    email2: yup.string().email(),
    email3: yup.string().email(),
    phoneNumber: yup.string(),
    phoneNumber2: yup.string(),
    phoneNumber3: yup.string(),
    linkedin: yup
      .string()
      .trim()
      .matches(REGEXP_LINKEDIN, { excludeEmptyString: true }),
    github: yup
      .string()
      .trim()
      .matches(REGEXP_GITHUB, { excludeEmptyString: true }),
    stackoverflow: yup
      .string()
      .trim()
      .matches(REGEXP_STACKOVERFLOW, { excludeEmptyString: true }),
  },
  [['firstname', 'lastname']],
);

type ProfileData = {
  firstname: string;
  lastname: string;
  headline: string;
  email: string;
  email2: string;
  email3: string;
  phoneNumber: string;
  phoneNumber2: string;
  phoneNumber3: string;
  linkedin: string;
  github: string;
  stackoverflow: string;
  customFields: Record<string, string | number>;
  gender: [Gender];
  availability: Record<string, string>;
  contactCategory: {
    type: 'human' | 'company';
    subtypes: { id: string }[];
  };
  currentTitle: string;
  currentCompany: string;
};

export type CandidateEditFormProps = ContextToProps & {
  setEditMode: (editMode: boolean) => void;
  initialValues: Partial<ProfileData>;
  customFields?: CustomFieldDefinition[];
  profileId: string;
  t: TFunction;
  isGenderManuallySet: boolean;
};

const CandidateEditForm = ({
  setEditMode,
  initialValues,
  customFields = [],
  profileId,
  t,
  onShowNotification,
  isGenderManuallySet,
}: CandidateEditFormProps) => {
  const clientId = useClientId();
  const { data: permissionData } = useClientPermissions(clientId);
  const { diversityAnalytics, nextAvailability: hasNextAvailability } =
    permissionData?.client.permissions || {};
  const [updateResumeDataInSearchPool] = useMutation(
    UPDATE_RESUME_DATA_IN_SEARCH_POOL,
  );
  const configurationParams = useMergedConfigurationParams();

  const type = initialValues?.contactCategory?.type || 'human';

  const { humanContactCategorySubtypes } = useHumanContactCategorySubtypes();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;

  const getDefaultValues = () => ({
    firstname: initialValues.firstname || '',
    lastname: initialValues.lastname || '',
    headline: initialValues.headline || '',
    email: initialValues.email || '',
    email2: initialValues.email2 || '',
    email3: initialValues.email3 || '',
    phoneNumber: initialValues.phoneNumber || '',
    phoneNumber2: initialValues.phoneNumber2 || '',
    phoneNumber3: initialValues.phoneNumber3 || '',
    linkedin: initialValues.linkedin || '',
    stackoverflow: initialValues.stackoverflow || '',
    github: initialValues.github || '',
    customFields: initialValues.customFields || {},
    gender: initialValues.gender || ['unknown'],
    nextAvailability:
      initialValues?.availability?.nextAvailabilityDate &&
      initialValues?.availability?.nextAvailabilityDate !== '-1'
        ? new Date(+initialValues.availability.nextAvailabilityDate)
        : new Date(),
    subtypes: _.map(
      initialValues?.contactCategory?.subtypes || [],
      ({ id }) => id,
    ),
    currentTitle: initialValues?.currentTitle || '',
    currentCompany: initialValues?.currentCompany || '',
  });
  const methods = useForm<ProfileData>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(ProfileSchema),
  });

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  const updatedFields = useRef<Record<string, boolean>>({});

  useEffect(() => {
    if (!updatedFields.current) {
      return;
    }
    _.each(_.keys(dirtyFields), (field) => {
      if (!updatedFields.current[field]) {
        updatedFields.current[field] = true;
      }
    });
  });

  useEffect(() => {
    methods.reset(getDefaultValues());
    // eslint-disable-next-line
  }, [profileId]);

  const publish = useDataUpdateSubscriptionPublish();

  const onSubmit = async (payload: ProfileData) => {
    const finalPayload: { [x: string]: any } = {};
    const anyPayload = payload as any;
    _.each(_.keys(payload), (key: string) => {
      if (updatedFields.current[key]) {
        finalPayload[key] = anyPayload[key];
      }
    });

    const {
      headline,
      github,
      linkedin,
      stackoverflow,
      gender,
      customFields: customFieldsData,
      nextAvailability,
      subtypes,
      currentTitle,
      currentCompany,
      ...rest
    } = finalPayload;

    try {
      let customFieldsPayload;
      if (customFieldsData) {
        customFieldsPayload = createCustomFieldsPayload(
          finalPayload.customFields,
          customFields,
        );
      }

      await updateResumeDataInSearchPool({
        variables: {
          searchPoolId: 'reveal',
          input: {
            id: profileId,
            data: {
              ...rest,
              ...(gender && {
                gender: gender?.[0] || 'unknown',
              }),
              ...(customFields && {
                customFields: customFieldsPayload,
              }),
              ...((github !== undefined ||
                linkedin !== undefined ||
                stackoverflow !== undefined) && {
                sources: {
                  ...(github !== undefined && { github }),
                  ...(linkedin !== undefined && { linkedin }),
                  ...(stackoverflow !== undefined && { stackoverflow }),
                },
              }),
              ...(headline !== undefined && {
                headline: { content: { text: headline } },
              }),
              ...(nextAvailability !== undefined && {
                availability: {
                  nextAvailabilityDate:
                    nextAvailability !== null
                      ? new Date(nextAvailability)
                      : '-1',
                },
              }),
              ...(permissions?.canEditHumanContactSubtypes &&
                type === 'human' &&
                subtypes && {
                  contactCategory: {
                    type,
                    subtypes: _.map(subtypes, (subtype) => ({ id: subtype })),
                  },
                }),
              ...(configurationParams?.canUseProfileColumnsTitleAndCompany ===
                'true' && {
                ...(currentTitle && {
                  currentTitle: { content: { text: currentTitle } },
                }),
                ...(currentCompany && {
                  currentCompany: { name: currentCompany },
                }),
              }),
            },
          },
        },
      });

      publish('onProfileResumeDataUpdate', { id: profileId });
      setEditMode(false);
      onShowNotification({
        level: 'success',
        message: t('reveal.profileEdition.success'),
      });
    } catch (e) {
      console.error({ e });
      onShowNotification({
        level: 'error',
        message: t('reveal.profileEdition.error'),
      });
      Sentry.captureException(e);
    }
  };

  const genderOptions = _.map(genderArray, (genderKey) => ({
    label: t(`reveal.reports.diversity.genders.${genderKey}`),
    value: genderKey,
  }));

  const contactCategoryOptions = _.map(
    humanContactCategorySubtypes,
    (subtype) => ({
      value: subtype.id,
      label: getTranslatedText(subtype.title),
    }),
  );

  return (
    <FormProvider {...methods}>
      <Form className='candidate-edit-form' onSubmit={handleSubmit(onSubmit)}>
        <div className='candidate-edit-header'>
          <h2 className='title'>{t('reveal.profileEdition.title')}</h2>
          <div className='actions'>
            <GenericButton
              primacy='secondary'
              onClick={() => setEditMode(false)}
            >
              {t('reveal.profileEdition.cancel')}
            </GenericButton>
            <GenericButton type='submit'>
              {t('reveal.profileEdition.save')}
            </GenericButton>
          </div>
        </div>
        <div className='candidate-edit-content'>
          <div className='candidate-edit-standard-fields'>
            {permissions?.canEditHumanContactSubtypes &&
              !_.isEmpty(humanContactCategorySubtypes) && (
                <SelectControl
                  multiple
                  label={t('newCandidateModal.subtype')}
                  name='subtypes'
                  horizontal
                  options={contactCategoryOptions}
                />
              )}

            <InputControl
              label={t('reveal.profileEdition.firstname')}
              errorMessage={t(
                'reveal.profileEdition.errors.firstnameOrLastname',
              )}
              name='firstname'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.lastname')}
              errorMessage={t(
                'reveal.profileEdition.errors.firstnameOrLastname',
              )}
              name='lastname'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.headline')}
              name='headline'
              horizontal
            />

            {configurationParams?.canUseProfileColumnsTitleAndCompany ===
              'true' && (
              <>
                <InputControl
                  label={t('reveal.profileEdition.position')}
                  name='currentTitle'
                  horizontal
                />
                <InputControl
                  label={t('reveal.profileEdition.company')}
                  name='currentCompany'
                  horizontal
                />
              </>
            )}

            <InputControl
              label={t('reveal.profileEdition.email')}
              errorMessage={t('reveal.profileEdition.errors.email')}
              name='email'
              horizontal
            />

            <InputControl
              label={t('reveal.profileEdition.email2')}
              errorMessage={t('reveal.profileEdition.errors.email2')}
              name='email2'
              horizontal
              legend={t('reveal.profileEdition.useAsMainEmail')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().email;
                methods.setValue('email', methods.getValues().email2);
                methods.setValue('email2', tmp);
              }}
            />

            <InputControl
              label={t('reveal.profileEdition.email3')}
              errorMessage={t('reveal.profileEdition.errors.email3')}
              name='email3'
              horizontal
              legend={t('reveal.profileEdition.useAsMainEmail')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().email;
                methods.setValue('email', methods.getValues().email3);
                methods.setValue('email3', tmp);
              }}
            />

            <InputControl
              label={t('reveal.profileEdition.phoneNumber')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber')}
              name='phoneNumber'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.phoneNumber2')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber2')}
              name='phoneNumber2'
              horizontal
              legend={t('reveal.profileEdition.useAsMainPhone')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().phoneNumber;
                methods.setValue(
                  'phoneNumber',
                  methods.getValues().phoneNumber2,
                );
                methods.setValue('phoneNumber2', tmp);
              }}
            />
            <InputControl
              label={t('reveal.profileEdition.phoneNumber3')}
              errorMessage={t('reveal.profileEdition.errors.phoneNumber3')}
              name='phoneNumber3'
              horizontal
              legend={t('reveal.profileEdition.useAsMainPhone')}
              clickableLegend
              onLegendClicked={() => {
                const tmp = methods.getValues().phoneNumber;
                methods.setValue(
                  'phoneNumber',
                  methods.getValues().phoneNumber3,
                );
                methods.setValue('phoneNumber3', tmp);
              }}
            />
            <InputControl
              label={t('reveal.profileEdition.linkedin')}
              errorMessage={t('reveal.profileEdition.errors.linkedin')}
              name='linkedin'
              horizontal
            />

            <InputControl
              label={t('reveal.profileEdition.github')}
              errorMessage={t('reveal.profileEdition.errors.github')}
              name='github'
              horizontal
            />
            <InputControl
              label={t('reveal.profileEdition.stackoverflow')}
              errorMessage={t('reveal.profileEdition.errors.stackoverflow')}
              name='stackoverflow'
              horizontal
            />
            {diversityAnalytics && (
              <SelectControl
                label={t('reveal.profileEdition.gender')}
                name='gender'
                horizontal
                options={genderOptions}
                helpText={
                  isGenderManuallySet
                    ? undefined
                    : t('reveal.profileEdition.automaticGenderExplanation', {
                        gender: t(
                          `reveal.reports.diversity.genders.${
                            initialValues.gender?.[0] || 'unknown'
                          }`,
                        ),
                      })
                }
              />
            )}
            {hasNextAvailability && (
              <DatePickerControl
                name='nextAvailability'
                label={t('reveal.profileEdition.availabilityDate')}
                horizontal
              />
            )}
          </div>
          <div className='candidate-edit-custom-fields'>
            {(customFields || []).map((customField) => (
              <CustomField
                key={customField.id}
                name={`customFields.${customField.id}`}
                definition={customField}
              />
            ))}
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default compose(
  contextToProps,
  withTranslation('translations'),
)(CandidateEditForm);
