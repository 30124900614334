import { QueryHookOptions, QueryResult, useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { PipelineState } from '@/components/PipelineSegmentation/pipelineSegmentation';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from './common';

const GET_PIPELINE_STATISTICS_DETAILS = gql`
  query getPipelineStatisticsDetails(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $archivedStatusesFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        volumeByPipeline(
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          archivedStatusesFilter: $archivedStatusesFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          missionId
          data {
            state
            archived
            profileItems {
              profileId
            }
          }
        }
      }
    }
  }
`;

interface VolumeByPipelineForMission {
  missionId: string;
  data: {
    state: PipelineState;
    archived: boolean;
    profileItems: {
      profileId: string;
    }[];
  }[];
}

interface GetPipelineStatisticsDetailsData {
  client: {
    id: string;
    statistics: {
      volumeByPipeline: VolumeByPipelineForMission[];
    };
  };
}

interface GetPipelineStatisticsDetailsVariables {
  clientId: string;
  missionsFilter?: GenericStatsFilterInput;
  stagesFilter?: GenericStatsFilterInput;
  withInterestedSubSteps?: boolean;
  archivedStatusesFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
}

interface UsePipelineStatisticsDetailsInput {
  queryOptions?: QueryHookOptions<
    GetPipelineStatisticsDetailsData,
    GetPipelineStatisticsDetailsVariables
  >;
}

type UsePipelineStatisticsDetailsResult = [
  (variables: GetPipelineStatisticsDetailsVariables) => void,
  QueryResult<
    GetPipelineStatisticsDetailsData,
    GetPipelineStatisticsDetailsVariables
  > & {
    volumeByPipeline?: VolumeByPipelineForMission[];
  },
];

const usePipelineStatisticsDetails = ({
  queryOptions = {},
}: UsePipelineStatisticsDetailsInput = {}): UsePipelineStatisticsDetailsResult => {
  const [fetchDetails, query] = useLazyQuery<
    GetPipelineStatisticsDetailsData,
    GetPipelineStatisticsDetailsVariables
  >(GET_PIPELINE_STATISTICS_DETAILS, {
    ...(queryOptions || {}),
  });

  const { volumeByPipeline } = query.data?.client.statistics || {};

  return [
    ({ endDate, ...otherVariables }) =>
      fetchDetails({
        variables: {
          ...otherVariables,
          endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
        },
      }),
    {
      ...query,
      volumeByPipeline,
    },
  ];
};

export default usePipelineStatisticsDetails;
