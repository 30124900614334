import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { ProjectDynamicImport } from '@/graphql/searchPoolJobs';
import useSharedMessagingAccounts, {
  SharedMessagingAccount,
} from '@/graphql/hooks/clients/useSharedServiceAccount';
import GenericTooltip from '@/components/Common/GenericTooltip';
import GenericButton from '@/components/Common/GenericButton';
import useClientId from '@/hooks/router/useClientId';
import { TRIGGER_DYNAMIC_IMPORT } from '@/graphql/DynamicImports';
import ButtonWithDropdown from '@/components/ButtonWithDropdown/ButtonWithDropdown';

type Props = {
  dynamicImport: ProjectDynamicImport;
  dynamicImportAccount?: SharedMessagingAccount;
  missionId: string;
};

function DynamicImportButton({
  dynamicImport,
  dynamicImportAccount,
  missionId,
}: Props) {
  const clientId = useClientId();
  const { t } = useTranslation();
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();

  const [triggerImport] = useMutation(TRIGGER_DYNAMIC_IMPORT, {
    refetchQueries: ['getSearchPoolJobProfileResults', 'getSearchPoolJob'],
  });

  const isUserImport = useMemo(() => {
    const importSenderId = dynamicImport.descriptor.unipileSharedAccountId;
    return !!_.findWhere(sharedMessagingAccounts, { id: importSenderId });
  }, [sharedMessagingAccounts, dynamicImport]);

  // No sender selected for this import
  if (!dynamicImportAccount) {
    return (
      <GenericTooltip
        rich
        hoverable
        trigger={
          <div>
            <GenericButton primacy='secondary' disabled>
              {t(`reveal.missions.mission.settingsTab.imports.resynchronize`)}
            </GenericButton>
          </div>
        }
      >
        {t(`reveal.missions.mission.settingsTab.imports.notSelected`)}
      </GenericTooltip>
    );
  }

  // User doesn't have access to sender
  if (!isUserImport) {
    return (
      <GenericTooltip
        rich
        hoverable
        trigger={
          <div>
            <GenericButton primacy='secondary' disabled>
              {t(`reveal.missions.mission.settingsTab.imports.resynchronize`)}
            </GenericButton>
          </div>
        }
      >
        {t(`reveal.missions.mission.settingsTab.imports.noAccess`)}
      </GenericTooltip>
    );
  }

  // Disconnected sender
  if (dynamicImportAccount?.status !== 'valid') {
    return (
      <GenericTooltip
        rich
        hoverable
        trigger={
          <div>
            <GenericButton primacy='secondary' disabled>
              {t(`reveal.missions.mission.settingsTab.imports.resynchronize`)}
            </GenericButton>
          </div>
        }
      >
        {t(`reveal.missions.mission.settingsTab.imports.invalidAccount`)}
        <Link to={`/client/${clientId}/automation-integrations`}>
          {t(`reveal.missions.mission.settingsTab.imports.here`)}
        </Link>
      </GenericTooltip>
    );
  }

  // Synchronizing
  if (dynamicImport.synchronizationStatus?.isSynchronizing) {
    return (
      <GenericTooltip
        rich
        hoverable
        trigger={
          <div>
            <GenericButton primacy='secondary' disabled>
              {t(`reveal.missions.mission.settingsTab.imports.synchronizing`)}
            </GenericButton>
          </div>
        }
      >
        {t(`reveal.missions.mission.settingsTab.imports.synchronizingDetails`)}
      </GenericTooltip>
    );
  }

  return (
    <ButtonWithDropdown
      primacy='secondary'
      onClick={() =>
        triggerImport({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId,
              dynamicImportId: dynamicImport.id,
              options: ['not-archived-only'],
            },
          },
        })
      }
      label={t(`reveal.missions.mission.settingsTab.imports.resynchronize`)}
      onSelect={(id) => {
        if (id !== 'synchronize-with-archived') {
          return;
        }
        triggerImport({
          variables: {
            searchPoolId: 'reveal',
            input: {
              missionId,
              dynamicImportId: dynamicImport.id,
            },
          },
        });
      }}
      options={[
        {
          id: 'synchronize-with-archived',
          label: t(
            `reveal.missions.mission.settingsTab.imports.resynchronize-with-archived`,
          ),
        },
      ]}
    />
  );
}

export default DynamicImportButton;
