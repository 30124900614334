import { PipelineDescriptor } from "./types";

export const defaultPipelineDescriptor: PipelineDescriptor = {
  steps: [
    { id: 'pending', title: { default: 'Non-contacted', fr: 'Non contactés' } },
    { id: 'in-progress', title: { default: 'Contacted', fr: 'Contactés' } },
    { id: 'replied', title: { default: 'Replied', fr: 'Répondus' } },
    { id: 'interested', title: { default: 'Interested', fr: 'Intéressés' } },
    { id: 'hired', title: { default: 'Hired', fr: 'Recrutés' } },
    { id: 'archived', title: { default: 'Archived', fr: 'Archivés' } },
  ]
};