import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import { PowerHour } from '@/types/powerHours';
import { getShortLanguage } from '@/common/utils/i18n';
import useClientId from '@/hooks/router/useClientId';

interface HardcodedPowerHour {
  id: string;
  startTimestamp: number;
  endTimestamp: number;
  message: { fr: string; en: string; de?: string };
  link: { fr: string; en: string; de?: string };
  title: { fr: string; en: string; de?: string };
  targetClientIds?: string[];
}

const hardcodedPowerHours: HardcodedPowerHour[] = [
  {
    id: 'js-230316',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-03-16T12:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-03-16T18:00:23.340Z',
    ).getTime(),
    message: {
      fr: 'Power Hour ce jeudi de 13h30 à 19h : -50% sur les recrutements JavaScript',
      en: 'Power Hour this Thursday from 1:30pm to 7pm: -50% on JavaScript hires',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-50 % sur les recrutements JavaScript',
      en: '-50% on JavaScript hires',
    },
  },
  {
    id: 'python-230323',
    startTimestamp: new Date('2023-03-23T12:30:23.340Z').getTime(),
    endTimestamp: new Date('2023-03-23T18:30:23.340Z').getTime(),
    message: {
      fr: 'Power Hours ce jeudi de 13h30 à 19h30 : -50% sur les recrutements Python',
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -50% on Python hires',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-50 % sur les recrutements Python',
      en: '-50% on Python hires',
    },
  },
  {
    id: 'php-java-ruby-230330',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-03-30T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-03-30T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: "Power Hours ce jeudi de 13h30 à 19h30 : -50% sur les recrutements PHP, Java, Ruby et d'autres à découvrir jeudi !",
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -50% on PHP, Java, Ruby hires and more to discover on Thursday!',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-50 % sur les recrutements PHP, Java, Ruby',
      en: '-50% on PHP, Java, Ruby hires',
    },
  },
  {
    id: 'net-product-designer-230406',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-04-06T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-04-06T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: "Power Hours ce jeudi de 13h30 à 19h30 : -50% sur les recrutements .Net, C#, Product Manager, Designer et d'autres à découvrir jeudi !",
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -50% on .Net, C#, Product Manager, Product Designer hires and more to discover on Thursday!',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-50 % sur les recrutements .NET, C#, Product Manager, Designer',
      en: '-50% on .NET, C#, Product Manager, Product, Designer hires',
    },
  },
  {
    id: 'junior02-230420',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-04-20T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-04-20T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: "Power Hours ce jeudi de 13h30 à 19h30 : -30% sur les recrutements de candidats ayant jusqu'à 2 ans d'expérience",
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -30% on junior candidates (0-2 years) hires',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: "-30% sur les recrutements de candidats ayant jusqu'à 2 ans d'expérience",
      en: '-30% on junior candidates (0-2 years) hires',
    },
  },
  {
    id: 'lead-tech-230404', // was in fact 2023-04-27
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-04-27T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-04-27T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: 'Power Hours ce jeudi de 13h30 à 19h30 : -30% sur les recrutements de candidats CTO, VP, Head of, Engineering Managers, et candidats JS la semaine prochaine',
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -30% on CTO, VP, Head of, Engineering managers hires, and Javascript hires next week',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-30% sur les recrutements de candidats CTO, VP, Head of et Engineering Manager',
      en: '-30% on CTO, VP, Head of and Engineering Managers hires',
    },
  },
  {
    id: 'js-230504',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-05-04T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-05-04T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: 'Power Hours ce jeudi de 13h30 à 19h30 : -30% sur les recrutements de développeurs JavaScript. Product Managers et développeurs Java la semaine prochaine',
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -30% on JavaScript hires. Next week: Product Managers and Java Developers',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-30% sur les recrutements de développeurs JavaScript',
      en: '-30% on JavaScript Developers hires',
    },
  },
  {
    id: 'java-pm-230511',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-05-11T11:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-05-11T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: 'Power Hours ce jeudi de 13h30 à 19h30 : -30% sur les recrutements de développeurs Java et Product (Manager ou Designer)',
      en: 'Power Hours this Thursday from 1:30pm to 7:30pm: -30% on Java Developers and Product (Manager and Designer) hires',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Les-Power-Hours-d-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-30% sur les recrutements de développeurs Java et Product Manager / Designer',
      en: '-30% on Java Developers and Product (Manager and Designer)  hires',
    },
  },
  {
    id: 'js-230522-from-230504',
    startTimestamp:
      /* new Date(Date.now() - 3*3600*1000).getTime(), */ new Date(
        '2023-05-22T07:30:23.340Z',
      ).getTime(),
    endTimestamp: /* new Date(Date.now() + 3*3600*1000).getTime(), */ new Date(
      '2023-05-22T17:30:23.340Z',
    ).getTime(),
    message: {
      fr: 'Power Hours ce lundi de 9h30 à 19h30 : -30% sur les recrutements de développeurs JavaScript.',
      en: 'Power Hours this Monday from 9:30am to 7:30pm: -30% on JavaScript hires.',
    },
    link: {
      fr: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-cc399c9b3d8d4621a323805073a6f4e1',
      en: 'https://hiresweet.notion.site/Power-Hours-by-HireSweet-Marketplace-02e12d4ce77e4003a135e5069ec65cd1',
    },
    title: {
      fr: '-30% sur les recrutements de développeurs JavaScript',
      en: '-30% on JavaScript Developers hires',
    },
    targetClientIds: [
      'appartoo',
      'gensdeconfiance',
      'flatsy',
      'extracadabra',
      'qare',
      'kls-platform',
      'matter',
      'stockly',
      'citron',
      'graneet',
      'wing',
      'matcha-wine',
      'lettria',
      'opensee',
    ],
  },
];

const getFinalPowerHour =
  (lng: 'fr' | 'en' | 'de') =>
  ({
    id,
    startTimestamp,
    endTimestamp,
    message,
    link,
    title,
  }: HardcodedPowerHour): PowerHour => ({
    id,
    startDate: new Date(startTimestamp),
    endDate: new Date(endTimestamp),
    message: message[lng] || message.en,
    link: link[lng] || link.en,
    title: title[lng] || title.en,
  });

interface UsePowerHoursResult {
  powerHours: PowerHour[];
}

const usePowerHours = (): UsePowerHoursResult => {
  const { i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lng = getShortLanguage(resolvedLanguage);

  const clientId = useClientId();

  const filteredPowerHours = _.filter(
    hardcodedPowerHours,
    (powerHour) =>
      !powerHour.targetClientIds ||
      powerHour.targetClientIds.includes(clientId),
  );

  return {
    powerHours: _.map(filteredPowerHours, getFinalPowerHour(lng)),
  };
};

export default usePowerHours;
