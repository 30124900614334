import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useEmailApi from '@/hooks/common/useEmailApi';
import useUserSettings from '@/graphql/hooks/users/useUserSettings';
import useClientTemplates from '@/graphql/hooks/templates/useClientTemplates';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import useDataUpdateSubscriptionPublish from '@/graphql/dataUpdateSubscription/useDataUpdateSubscriptionPublish';
import MergeTagsService from '@/common/mergeTags/MergeTagsService';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import useMergeTagsProfileContext from '@/common/mergeTags/useMergeTagsProfileContext';
import MailButton from '../../Email/MailButton';
import useRevealEmailAction from '../../Actions/EmailAction/useRevealEmailAction';

interface ActionMailButtonProps {
  message: any;
  profile: any;
  task: any;
  searchPoolId: string;
  sequenceId?: string;
  actionId: string;
  assignedSender: any;
  setClassicSender: any;
  clientId: string;
  bccAddresses: string[];
  ccAddresses: string[];
  instantiatedSnippets: SweetEvaluatorTypes.Variable[];
  userCanSend: boolean;
}

const ActionMailButton = ({
  message,
  profile,
  task,
  searchPoolId,
  sequenceId,
  actionId,
  assignedSender,
  setClassicSender,
  clientId,
  bccAddresses,
  ccAddresses,
  instantiatedSnippets,
  userCanSend,
}: ActionMailButtonProps): JSX.Element => {
  const emailApi = useEmailApi();
  const { data: templateData } = useClientTemplates(clientId);
  const publishSubscriptionEvent = useDataUpdateSubscriptionPublish();
  const clientTemplates = templateData?.client?.templates;
  const { data: userSettingsData } = useUserSettings();
  const userSignature = userSettingsData?.user?.signature;
  const { goToNextTask } = useCandidateViewContext();
  const { t } = useTranslation();
  const { lastSubject } = useRevealEmailAction(profile);
  const mergeTagsProfileContext = useMergeTagsProfileContext({
    clientId,
    profile,
  });

  const instantiatedMessage = useMemo(() => {
    const defaultSubject =
      profile?.contactData?.defaultSubject || lastSubject || '';
    if (!clientTemplates || !mergeTagsProfileContext) {
      return {};
    }
    return MergeTagsService.getInstantiatedMessage({
      message,
      context: mergeTagsProfileContext,
      templates: clientTemplates,
      defaultSubject,
      instantiatedSnippets,
      t,
      isEmailAction: true,
    });
  }, [
    message,
    profile,
    lastSubject,
    clientTemplates,
    instantiatedSnippets,
    t,
    mergeTagsProfileContext,
  ]);

  const handleEmailSend = () => {
    publishSubscriptionEvent('onTaskUpdated', {
      profileId: profile.id,
      taskId: task?.id,
      sequenceId: task?.sequenceId,
    });
    goToNextTask();
  };

  if (!instantiatedMessage) {
    return <></>;
  }

  const { body, subject } = instantiatedMessage;

  return (
    <MailButton
      clientId={clientId}
      sequenceId={sequenceId}
      actionId={actionId}
      profileId={profile?.id}
      profile={profile}
      searchPoolId={searchPoolId}
      mailData={{
        subject,
        body,
        footer: message?.footer,
        signature: userSignature || '',
        dest: profile?.email,
        bccAddresses,
        ccAddresses,
        firstname: profile?.resumeData?.firstname,
      }}
      emailApi={emailApi}
      task={task}
      assignedSender={assignedSender}
      setClassicSender={setClassicSender}
      onSend={handleEmailSend}
      targetThread={null}
      shouldUseThread
      userCanSend={userCanSend}
    />
  );
};

export default ActionMailButton;
