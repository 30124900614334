import _ from 'underscore';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import NextInteraction from '@/revealComponents/RevealListRow/NextInteraction';
import useSearchPoolMiniProfileById from '@/graphql/hooks/profiles/useSearchPoolMiniProfileById';
import {
  Action,
  SearchPoolMiniProfile,
} from '@/graphql/searchPoolMiniProfileById';
import AvatarColumn from '@/components/ProfileRow/columns/AvatarColumn/AvatarColumn';
import MissionsColumn from '@/components/ProfileRow/columns/MissionsColumn';
import LastInteractionColumn from '@/components/ProfileRow/columns/LastInteractionColumn';
import GenericCheckbox from '@/components/Common/GenericCheckbox';
import { getTranslatedText } from '@/common';
import useClientCustomCompanyDealStates from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { combineDataWithCustomFields } from '@/common/customFields';
import { CustomFieldDefinition } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import { InlineCustomField } from '@/components/CustomFields/InlineCustomField';
import { MONTH_IN_MS } from '@/common/dates';
import useInterestedSubPipeline from '@/graphql/hooks/searchPoolJob/useInterestedSubPipeline';
import { getSourceNameFromSource } from '@/revealComponents/ProfileDetails';

import MatchingDetails from '../revealComponents/RevealListRow/MatchingDetails';
import ProfileRowSequence from './ProfileRowSequence';
import NameAndHeadline from '../revealComponents/RevealListRow/NameAndHeadline';
import ProfileAtsApplicationsRow from './ProfileAtsApplicationsRow';
import { ColumnIds } from '../JobsView/JobView/ProjectProfilesTab/columns/useProfilesTableColumns';

import './ProfileRow.css';
import styles from './ProfileRow.module.less';
import { getATSDataItems } from '../../../common/reveal/SourceData';

const campaignStatePillColorByState: Record<string, string> = {
  pending: 'blue',
  hold: 'grey',
  'in-progress': 'orange',
  completed: 'green',
  archived: 'red',
};

export const CustomFieldsColumnsToExcludeFromCustomFieldsSection: Record<
  string,
  string[]
> = {
  // 'adequat-test1-demo': ['best-kaoutar'],
};

interface ProfileRowInterface {
  job?: {
    linkedATSJobId: string;
  };
  jobId?: string;
  tableId?: string;
  clientId: string;
  searchPoolId: string;
  profileId: string;
  campaignId?: string;
  isSelected?: boolean;
  onToggleCheckbox?: (profile: { id: string }) => void;
  onRangeSelect?: (profile: { id: string }) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  showProfileStep?: boolean;
  showMissions?: boolean;
  showEmail?: boolean;
  showPhoneNumber?: boolean;
  showActiveSequence?: boolean;
  showUnactiveSequence?: boolean;
  showLastInteraction?: boolean;
  showNextInteraction?: boolean;
  showAtsApplications?: boolean;
  showRelevance?: boolean;
  atsExplanation?: any;
  matchingDetails?: any;
  showCampaignState?: boolean;
  showSequence?: boolean;
  shouldHideCheckbox?: boolean;
  displayColumn?: (id: ColumnIds) => boolean;
  customFields?: CustomFieldDefinition[];
  showSubtype?: boolean;
  // showAdStatus?: boolean;
  subtype?: string;
  lateDateDayDiff?: number;
  isDisabled?: boolean;
  showCompanyDealState?: boolean;
  showComment?: boolean;
  showTitle?: boolean;
  showCompany?: boolean;
  showSource?: boolean;
  showHasBeenCandidate?: boolean;
}

export const getAnteriorityFromCustomField = (field, statusField) => {
  if (!field?.date) {
    // Special case where we cannot have an anteriority field
    // because we have no contracts. But we know the profile status to be "Intérimaire"
    // Also, mon-option-2 is the id for "Intérimaire"
    if (statusField?.selected?.[0] === 'mon-option-2') {
      return '6+';
    }
    return null;
  }

  if (statusField?.selected?.[0] !== 'mon-option-2') {
    return null;
  }

  const currentDate = new Date(Date.now());
  const fieldDate = new Date(field.date);

  const diff = currentDate.getTime() - fieldDate.getTime();
  const diffInMonth = diff / MONTH_IN_MS;
  if (diffInMonth < 0) {
    return null;
  }
  if (diffInMonth <= 1) {
    return '-1';
  }
  if (diffInMonth <= 3) {
    return '1-3';
  }
  if (diffInMonth > 3 && diffInMonth <= 6) {
    return '3-6';
  }
  return '6+';
};

// TODO: rename and move to revealComponents
const NewProfileRow: React.FC<ProfileRowInterface> = ({
  job,
  jobId,
  tableId,
  clientId,
  searchPoolId,
  profileId,
  campaignId,
  isSelected,
  onToggleCheckbox,
  onRangeSelect,
  onClick,
  shouldHideCheckbox,
  showProfileStep,
  showMissions,
  showEmail,
  showPhoneNumber,
  showActiveSequence,
  showUnactiveSequence,
  showLastInteraction,
  showNextInteraction,
  showAtsApplications = false,
  showRelevance,
  atsExplanation,
  matchingDetails,
  showCampaignState,
  showSequence = true,
  displayColumn = () => false,
  customFields,
  showSubtype = false,
  // showAdStatus = false,
  subtype,
  lateDateDayDiff,
  isDisabled,
  showCompanyDealState = false,
  showComment = false,
  showTitle = false,
  showCompany = false,
  showSource = false,
  showHasBeenCandidate = false,
}) => {
  const { t } = useTranslation();
  const { customCompanyDealStates } = useClientCustomCompanyDealStates();
  const { profile, loading } = useSearchPoolMiniProfileById(
    {
      searchPoolId,
      profileId,
    },
    {
      fetchPolicy: 'network-only',
    },
  );

  const { interestedSubPipeline } = useInterestedSubPipeline({
    missionId: jobId ?? '',
    queryOptions: {
      skip: !jobId,
    },
  });

  const { resumeData, currentSequenceInfo } = profile || {};
  const atsId = useClientCurrentAtsId();

  const { author } = currentSequenceInfo || {};
  const [nextInteraction, setNextInteraction] = useState<Action>({
    owner: '',
    plannedExecutionDate: '',
    type: '',
    actionId: '',
  });

  const topFields = (customFields || []).filter(
    (cf: any) => cf.shouldDisplayBelowFullname,
  );

  const fields = combineDataWithCustomFields(topFields, resumeData);
  const fieldsWithValue = _.filter(fields, (field: any) => field.readableValue);

  const [currentSequence, setCurrentSequence] = useState<any>({});
  const { state: campaignEnrollmentState } =
    _.findWhere(profile?.campaignEnrollments || [], {
      campaignId,
    }) || {};

  const onProfileClick =
    onRangeSelect && onToggleCheckbox && profile
      ? (event: MouseEvent) => {
        event.stopPropagation();
        if (event.nativeEvent?.shiftKey) {
          onRangeSelect(profile);
        } else {
          onToggleCheckbox(profile);
        }
      }
      : undefined;

  useEffect(() => {
    const profileCurrentSequence = _.findWhere(
      profile?.contactFlow?.sequences || [],
      {
        isCurrent: true,
      },
    );
    setCurrentSequence(profileCurrentSequence);
    if (
      !profileCurrentSequence ||
      profileCurrentSequence?.completion?.isCompleted ||
      profileCurrentSequence?.trigger?.type === 'manual-trigger'
    ) {
      setNextInteraction({
        owner: '',
        plannedExecutionDate: '',
        type: '',
        actionId: '',
      });
      return;
    }
    let lastInterIndex = -1;
    for (
      let iAction = 0;
      iAction < profileCurrentSequence?.actions?.length;
      iAction += 1
    ) {
      if (profileCurrentSequence?.actions?.[iAction]?.completion?.isCompleted) {
        lastInterIndex = iAction;
      }
    }
    const nextInteractionIndex = lastInterIndex + 1;
    if (nextInteractionIndex < profileCurrentSequence?.actions?.length) {
      const nextInter = profileCurrentSequence.actions[nextInteractionIndex];
      if (nextInter?.type && !nextInter?.completion?.completionDate) {
        setNextInteraction(nextInter);
      }
    }
  }, [profile]);

  if (loading) {
    return (
      <div
        className={classnames(
          'generic-profile-row',
          isSelected && 'selected',
          isDisabled && 'disabled',
        )}
        onClick={onClick}
      >
        {!shouldHideCheckbox && (
          <div className='row-checkbox-container'>
            <GenericCheckbox
              checked={isSelected}
              big
              onClick={onProfileClick}
            />
          </div>
        )}
        <div className='avatar-container' data-openreplay-masked>
          <Placeholder style={{ height: 32, width: 32, borderRadius: '16px' }}>
            <Placeholder.Image />
          </Placeholder>
        </div>
        <div className='name-and-headline-container'>
          <Placeholder>
            <Placeholder.Line length='short' style={{ height: '0px' }} />
            <Placeholder.Line length='long' style={{ height: '10px' }} />
          </Placeholder>
        </div>
        {showProfileStep && (
          <div className='profile-step-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showTitle && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showCompany && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showSource && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showHasBeenCandidate && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showEmail && (
          <div className='email-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showPhoneNumber && (
          <div className='phone-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showRelevance && (
          <div className='relevance-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showMissions && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showSubtype && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {/* {showAdStatus && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )} */}
        {showComment && (
          <div className='missions-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {(showSequence || showActiveSequence) && (
          <div className='sequence-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='short' style={{ height: '0px' }} />
              <Placeholder.Line length='long' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showCompanyDealState && (
          <div className='sequence-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='short' style={{ height: '0px' }} />
              <Placeholder.Line length='long' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showCampaignState && (
          <div className='campaign-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='medium' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showLastInteraction && (
          <div className='last-interaction-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='medium' style={{ height: '0px' }} />
              <Placeholder.Line length='short' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showNextInteraction && (
          <div className='next-interaction-container'>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='short' style={{ height: '0px' }} />
              <Placeholder.Line length='long' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
        {showAtsApplications && (
          <div>
            <Placeholder style={{ width: '100%' }}>
              <Placeholder.Line length='short' style={{ height: '0px' }} />
              <Placeholder.Line length='long' style={{ height: '10px' }} />
            </Placeholder>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={classnames(
        'generic-profile-row',
        isSelected && 'selected',
        isDisabled && 'disabled',
      )}
      onClick={onClick}
    >
      <div className='row-checkbox-container'>
        {!shouldHideCheckbox && (
          <GenericCheckbox checked={isSelected} big onClick={onProfileClick} />
        )}
      </div>

      <AvatarColumn profile={profile} className='avatar-container' />

      <div className='name-and-headline-container'>
        <div className='headline-and-custom-fields'>
          <NameAndHeadline
            clientId={clientId}
            searchPoolId={searchPoolId}
            profile={profile}
            displayCustomFields
            customFields={fieldsWithValue}
          />
        </div>
      </div>

      {showProfileStep && (
        <div className='email-container'>
          {getProfileStepName({ profile, jobId, interestedSubPipeline, t })}
        </div>
      )}

      {showTitle && (
        <div className='title-container'>
          <div className='content'>
            {profile?.resumeData?.currentTitle?.content?.text ?? '-'}
          </div>
        </div>
      )}

      {showCompany && (
        <div className='company-container'>
          <div className='content'>
            {profile?.resumeData?.currentCompany?.name ?? '-'}
          </div>
        </div>
      )}

      {showSource && (
        <div className='source-container'>
          <div className='content'>
            {getSourceNameFromSource(
              t,
              getATSDataItems(profile?.resumeData?.sourceData) || [],
              profile?.source,
            ) ?? '-'}
          </div>
        </div>
      )}

      {jobId && showHasBeenCandidate && (
        <div className='has-been-candidate-container'>
          <div className='content'>
            {(_.findWhere(profile?.applicationMissions || [], {
              missionId: jobId,
            })
              ? t('common.yes')
              : t('common.no')) ?? '-'}
          </div>
        </div>
      )}

      {showEmail && (
        <div className='email-container'>
          {profile?.resumeData?.email ? (
            <a
              className='email-link'
              href={`mailto:${profile.resumeData.email}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {profile.resumeData.email}
            </a>
          ) : (
            '-'
          )}
        </div>
      )}

      {showPhoneNumber && (
        <div className='phone-container'>
          {profile?.resumeData?.phoneNumber ? (
            <a
              className='phone-link'
              href={`tel:${profile.resumeData.phoneNumber}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {profile.resumeData.phoneNumber}
            </a>
          ) : (
            '-'
          )}
        </div>
      )}

      {!_.isEmpty(
        CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId],
      ) &&
        _.map(
          CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId] ?? [],
          (column) => {
            if (!displayColumn(column)) {
              return null;
            }
            return (
              <div key={column} className={styles.customFieldValue}>
                <InlineCustomField
                  customFields={customFields}
                  customField={
                    _.findWhere(profile?.resumeData?.customFields || [], {
                      clientCustomFieldId: column,
                    }) ||
                    (jobId
                      ? _.findWhere(
                        _.findWhere(profile?.missionsInfo || [], {
                          missionId: jobId,
                        })?.data?.customFields || [],
                        { clientCustomFieldId: column },
                      )
                      : undefined)
                  }
                />
              </div>
            );
          },
        )}

      {showRelevance && (
        <div className='relevance-container'>
          {!_.isEmpty(matchingDetails) ? (
            <MatchingDetails
              matchingDetails={matchingDetails}
              atsExplanation={atsExplanation}
            />
          ) : (
            '-'
          )}
        </div>
      )}

      {showMissions && (
        <div className='missions-container'>
          <MissionsColumn profile={profile} />
        </div>
      )}

      {showSubtype && (
        <div className='subtype-container'>
          <div className='content'>
            {atsId === 'adventure' && subtype
              ? t(`taskType.${subtype}`)
              : subtype || '-'}
            {lateDateDayDiff > 0 && (
              <span className='pill-message blue mini equal-padding no-margin'>
                {t('reveal.tasks.late', { count: lateDateDayDiff })}
              </span>
            )}
          </div>
        </div>
      )}

      {/* {showAdStatus ? (
        <div className={styles.customFieldValue}>
          <InlineCustomField
            customFields={customFields}
            customField={_.findWhere(profile?.resumeData?.customFields || [], {
              clientCustomFieldId: 'best-kaoutar',
            })}
          />
        </div>
      ) : (
        ''
      )} */}

      {showComment && (
        <div className='comment-container'>
          <div className='content'>
            {_.findWhere(profile?.missionsInfo || [], { missionId: jobId })
              ?.data?.comment || '-'}
          </div>
        </div>
      )}

      {showSequence &&
        (showActiveSequence &&
          (!currentSequence ||
            (currentSequence?.completion?.isCompleted &&
              !showUnactiveSequence)) ? (
          <div className='sequence-container' />
        ) : (
          <ProfileRowSequence
            currentSequenceInfo={currentSequenceInfo}
            sequences={profile?.contactFlow?.sequences}
            nextInteraction={nextInteraction}
            loading={loading}
            isStandalone={false}
            mini={false}
          />
        ))}
      {showCompanyDealState && (
        <div className='deal-state-container'>
          <div className='content'>
            {getTranslatedText(
              _.findWhere(customCompanyDealStates, {
                customCompanyDealStateId:
                  profile?.companyDealState?.customCompanyDealStateId,
              })?.title,
            ) || '-'}
          </div>
        </div>
      )}
      {showCampaignState && (
        <div className='campaign-container'>
          {campaignEnrollmentState?.hold && (
            <span className='pill-message equal-padding small grey'>
              {t('campaigns.table.actions.hold')}
            </span>
          )}
          {campaignEnrollmentState?.archived && (
            <span className='pillMessage equal-padding small red'>
              {t('campaigns.table.actions.archived')}
            </span>
          )}
          <span
            className={`pill-message equal-padding small ${campaignStatePillColorByState[
              campaignEnrollmentState?.status || ''
              ]
              }`}
          >
            {t(`campaigns.table.actions.${campaignEnrollmentState?.status}`)}
            {campaignEnrollmentState?.status === 'completed' &&
              ` ${t('common.the')} ${t('common.shortDate', {
                date: new Date(+campaignEnrollmentState?.completionTimestamp),
              })}`}
          </span>
        </div>
      )}

      {atsId !== 'adventure' && showLastInteraction && (
        <div className='last-interaction-container'>
          <LastInteractionColumn profile={profile} />
        </div>
      )}

      {_.map(customFields || [], (field) => {
        if (
          !displayColumn(field.id) ||
          _.includes(
            CustomFieldsColumnsToExcludeFromCustomFieldsSection[clientId] ?? [],
            field.id,
          )
        ) {
          return null;
        }
        const mission = _.findWhere(profile?.missionsInfo || [], {
          missionId: jobId,
        });
        const profileCustomField =
          _.findWhere(profile?.resumeData?.customFields || [], {
            clientCustomFieldId: field.id,
          }) ||
          (jobId
            ? _.findWhere(mission?.data?.customFields || [], {
              clientCustomFieldId: field.id,
            })
            : undefined);

        let anteriority = null;
        // Hack for adequat. best-kaoutar is the Status custom field
        if (field.id === 'best-kaoutar') {
          const anteriorityCustomField = _.findWhere(
            profile?.resumeData?.customFields || [],
            {
              clientCustomFieldId: 'anteriority',
            },
          );

          // in this case profileCustomField is the status field
          anteriority = getAnteriorityFromCustomField(
            anteriorityCustomField,
            profileCustomField,
          );
        }

        return (
          <div key={field.id} className={styles.customFieldValue}>
            <div className={styles.content}>
              <InlineCustomField
                customFields={customFields}
                customField={profileCustomField}
              />
              {field?.id === 'best-kaoutar' &&
                anteriority &&
                tableId !== 'tasks-view' && (
                  <span className='pill-message blue mini equal-padding no-margin'>
                    {t('reveal.tasks.anteriority', { anteriority })}
                  </span>
                )}
            </div>
          </div>
        );
      })}

      {showNextInteraction && (
        <div className='next-interaction-container'>
          {loading && (
            <Placeholder>
              <Placeholder.Line length='medium' />
              <Placeholder.Line length='long' />
            </Placeholder>
          )}

          {!loading && nextInteraction?.type && (
            <NextInteraction
              author={author}
              timestampStr={
                nextInteraction?.plannedExecutionDate
                  ? Date.parse(nextInteraction?.plannedExecutionDate).toString()
                  : `${Date.now()}`
              }
              action={nextInteraction}
              resumeData={resumeData}
            />
          )}

          {!loading && !nextInteraction?.type && <div>-</div>}
        </div>
      )}

      {showAtsApplications && (
        <ProfileAtsApplicationsRow job={job} profile={profile} />
      )}

      {atsId === 'adventure' && showLastInteraction && (
        <div className='last-interaction-container'>
          <LastInteractionColumn profile={profile} />
        </div>
      )}
    </div>
  );
};

// TODO : translate
const getProfileStepName = ({
  profile,
  jobId,
  interestedSubPipeline,
  t,
}: {
  profile?: SearchPoolMiniProfile;
  jobId?: string;
  interestedSubPipeline?: any;
  t?: any;
}) => {
  if (!profile || !jobId) {
    return '';
  }

  const missionInfo = _.findWhere(profile?.missionsInfo ?? [], {
    missionId: jobId,
  });
  if (!missionInfo) {
    return '';
  }

  if (missionInfo.data?.archived) {
    return t(`reveal.pipelineSegmentations.archived`);
  }

  const segmentationState = missionInfo.data?.segmentationStates?.[0];

  if (!segmentationState) {
    return t(`reveal.pipelineSegmentations.pending`);
  }

  if (segmentationState?.interestedStepId) {
    const interestedStepFromId = _.indexBy(
      interestedSubPipeline?.steps ?? [],
      'id',
    );
    return (
      interestedStepFromId[segmentationState?.interestedStepId]?.title ??
      t(`reveal.pipelineSegmentations.interested`)
    );
  }

  return t(
    `reveal.pipelineSegmentations.${segmentationState.state ?? 'pending'}`,
  );
  // return segmentationState.state;
};

export default NewProfileRow;
