import _ from 'underscore';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';

import GenericButton from '@/components/Common/GenericButton';
import { sanitizeTypename } from '@/common/utils/apollo';
import GenericTabMenu from '@/components/Common/GenericTabMenu/GenericTabMenu';
import { ClientCustomAction } from '@/types/customAction';

import { ClientCustomActivity } from '@/graphql/hooks/clients/useClientCustomActivities';
import CustomTaskPreviewContainer from './CustomTaskPreviewContainer';
import { CustomTaskFormBasicConfig } from './CustomTaskFormSections/CustomTaskFormBasicConfig';
import { CustomTaskFormFormConfig } from './CustomTaskFormSections/CustomTaskFormFormConfig';
import { CustomTaskFormExecutionConfig } from './CustomTaskFormSections/CustomTaskFormExecutionConfig';

import styles from '../CustomTasksSettings.module.less';
import { validateCustomAction } from './helpers';

type Props = {
  onSubmit: ({
    data,
  }: {
    data: ClientCustomAction | ClientCustomActivity;
  }) => void;
  currentTask?: ClientCustomAction | ClientCustomActivity;
  setOpen: (open: boolean) => void;
  type: 'tasks' | 'activities';
  index?: number;
};

export const DEFAULT_CUSTOM_TASK: ClientCustomAction = {
  id: '',
  title: {
    default: 'My custom {{type}} {{idx}}',
  },
  type: 'custom',
  icon: {
    type: 'url',
    url: '/images/logos/new-logo_without_text_blue.svg',
  },
  execution: {
    type: '',
  },
  completionButtonDisplay: {
    title: {
      default: 'Mark as completed',
      fr: 'Marquer comme effectuée',
      en: 'Mark as completed',
      de: 'Als erledigt markieren',
    },
  },
  format: {
    helpText: {
      default: '',
    },
    form: {
      id: '',
      fields: [],
    },
  },
};

const CustomTaskForm: React.FC<Props> = ({
  onSubmit,
  currentTask,
  type = 'tasks',
  index,
  setOpen,
}) => {
  const { t } = useTranslation();

  const translationKey =
    type === 'tasks' ? 'customTasksSettings' : 'customActivitySettings';

  const [taskForm, setTaskForm] = useState<
    ClientCustomAction | ClientCustomActivity
  >(currentTask || DEFAULT_CUSTOM_TASK);

  useEffect(() => {
    setTaskForm((current) => ({
      ...current,
      title: {
        default: current.title.default
          .replace('{{idx}}', index?.toString() || '')
          .replace('{{type}}', type === 'tasks' ? 'task' : 'activity'),
      },
    }));
  }, [index, type]);

  const [selectedMenu, setSelectedMenu] = useState('configuration');

  const handleSubmit = useCallback(() => {
    onSubmit({ data: sanitizeTypename(taskForm) });
  }, [onSubmit, taskForm]);

  const handleUpdate = useCallback((newValues) => {
    setTaskForm((currentTaskForm) => {
      return {
        ...currentTaskForm,
        ...newValues,
      };
    });
  }, []);

  return (
    <div className={styles.modalForm}>
      <div className={styles.modalContent}>
        <div className={styles.formContent}>
          <GenericTabMenu
            menus={[
              {
                id: 'configuration',
                label: t(
                  `reveal.parameters.${translationKey}.creationModal.headers.configuration`,
                ),
              },
              {
                id: 'form',
                label: t(
                  `reveal.parameters.${translationKey}.creationModal.headers.form`,
                ),
              },
              ...(type === 'tasks'
                ? [
                    {
                      id: 'execution',
                      label: t(
                        `reveal.parameters.${translationKey}.creationModal.headers.execution`,
                      ),
                    },
                  ]
                : []),
            ]}
            activeMenuId={selectedMenu}
            onMenuSelected={setSelectedMenu}
          />
          <div className={styles.formContentContainer}>
            {selectedMenu === 'configuration' && (
              <CustomTaskFormBasicConfig
                taskForm={{
                  ...taskForm,
                  title: {
                    default: taskForm.title.default.replace(
                      '{{idx}}',
                      index?.toString() || '',
                    ),
                  },
                }}
                type={type}
                onUpdate={handleUpdate}
              />
            )}
            {selectedMenu === 'form' && (
              <CustomTaskFormFormConfig
                taskForm={taskForm}
                onUpdate={handleUpdate}
              />
            )}
            {selectedMenu === 'execution' && (
              <CustomTaskFormExecutionConfig
                taskForm={taskForm as ClientCustomAction}
                onUpdate={handleUpdate}
              />
            )}
          </div>
        </div>
        <div className={classNames(styles.previewContainer)}>
          <CustomTaskPreviewContainer task={taskForm} type={type} />
        </div>
      </div>

      <div className={styles.modalActions}>
        <GenericButton
          size='big'
          primacy='secondary'
          onClick={() => setOpen(false)}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          onClick={handleSubmit}
          disabled={!validateCustomAction(taskForm, type)}
        >
          {t(
            currentTask
              ? 'common.save'
              : `reveal.parameters.${translationKey}.creationModal.create`,
          )}
        </GenericButton>
      </div>
    </div>
  );
};

export default CustomTaskForm;
