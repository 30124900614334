import htmlToText from 'html-to-text';

function convertHrefLinks(str) {
  return str.replace(/<a\s+href="([^"]+)">(.*?)<\/a>/gi, '$2 ($1)');
}



export const getHtmlStringAsText = (htmlString) => {
  if (typeof htmlString !== 'string') {
    return '';
  }

  if (htmlString.indexOf('<div>') < 0) {
    return htmlString;
  }
  return htmlToText
    .fromString(
      convertHrefLinks(htmlString || '').replace(/<\/div>/g, '</div><br/>'), 
      {
        ignoreHref: true,
        ignoreImage: true,
        unorderedListItemPrefix: '• ',
        wordwrap: null,
      
      }
    ).trim();
};
