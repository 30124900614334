import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './HiredPill.module.less';

interface HiredPillProps {
  showLabel?: boolean;
  text?: string;
}

const HiredPill: React.FC<HiredPillProps> = ({ showLabel = true, text }) => {
  const { t } = useTranslation();

  return (
    <div className={style.pill}>
      {showLabel && (text || t('reveal.profileModal.hiredPill.label'))}
    </div>
  );
};

export default HiredPill;
