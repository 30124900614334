import { useMutation, type MutationHookOptions } from '@apollo/client';
import gql from 'graphql-tag';
import { useCallback } from 'react';

const enableSharedSpaces = gql`
  mutation enableSharedSpaces($mode: String!) {
    enableClientMode(mode: $mode) {
      id
      permissions {
        canUseSharedViews
      }
    }
  }
`;

type Data = {
  enableClientMode: {
    id: string;
    permissions: {
      canUseSharedViews: boolean;
    };
  };
};

type Variables = {
  mode: 'sharedSpaces:enableFeedback' | 'sharedSpaces';
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

const useEnableSharedSpaces = ({ mutationOptions = {} }: Input) => {
  const [mutation, result] = useMutation<Data, Variables>(
    enableSharedSpaces,
    mutationOptions,
  );
  const enable = useCallback(
    ({ enableFeedback }: { enableFeedback: boolean }) =>
      mutation({
        variables: {
          mode: enableFeedback ? 'sharedSpaces:enableFeedback' : 'sharedSpaces',
        },
      }),
    [mutation],
  );
  return [enable, result] as const;
};

export default useEnableSharedSpaces;
