import React, { useCallback } from 'react';
import ENOverridesTranslations from '@/translationOverrides/en/overrides.json';
import FROverridesTranslations from '@/translationOverrides/fr/overrides.json';
import DEOverridesTranslations from '@/translationOverrides/de/overrides.json';
import { useClientDepartmentNomenclature } from '@/graphql/hooks/clients/useClientDepartmentNomenclature';

import {
  useClientCustomTranslations,
  useI18nOverrides,
} from './useClientCustomTranslations';

interface TranslationOverrideProps {
  children: React.ReactNode;
}

const TranslationOverride: React.FC<TranslationOverrideProps> = ({
  children,
}) => {
  const overrideI18n = useI18nOverrides();

  // Load client "raw" translations from DB
  useClientCustomTranslations();

  // Load client pre-defined translations (departments for example) from IDs
  const applyResourceBundles = useCallback(
    (
      FROverrides: Record<string, string | object>,
      ENOverrides: Record<string, string | object>,
      DEOverrides: Record<string, string | object>,
    ) => {
      overrideI18n({
        fr: {
          organization_custom: FROverrides,
        },
        en: {
          organization_custom: ENOverrides,
        },
        de: {
          organization_custom: DEOverrides,
        },
      });
    },
    [overrideI18n],
  );

  useClientDepartmentNomenclature({
    queryOptions: {
      onCompleted: (data) => {
        const nomenclature = data?.client?.departmentNomenclature;
        if (!nomenclature) {
          return;
        }

        const ENOverrides = {
          ...(ENOverridesTranslations.department as Record<string, object>)[
            nomenclature.department.id
          ],
          ...(ENOverridesTranslations.section as Record<string, object>)[
            nomenclature.section.id
          ],
          ...(ENOverridesTranslations.subsection as Record<string, object>)[
            nomenclature.subsection.id
          ],
        };
        const FROverrides = {
          ...(FROverridesTranslations.department as Record<string, object>)[
            nomenclature.department.id
          ],
          ...(FROverridesTranslations.section as Record<string, object>)[
            nomenclature.section.id
          ],
          ...(FROverridesTranslations.subsection as Record<string, object>)[
            nomenclature.subsection.id
          ],
        };
        const DEOverrides = {
          ...(DEOverridesTranslations.department as Record<string, object>)[
            nomenclature.department.id
          ],
          ...(DEOverridesTranslations.section as Record<string, object>)[
            nomenclature.section.id
          ],
          ...(DEOverridesTranslations.subsection as Record<string, object>)[
            nomenclature.subsection.id
          ],
        };
        applyResourceBundles(FROverrides, ENOverrides, DEOverrides);
      },
    },
  });

  return <>{children}</>;
};

export default TranslationOverride;
