import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import gql from 'graphql-tag';

import { PipelineCountDataPoint } from '@/types/statistics/pipeline';
import { isTodayYyyyMmDd } from '@/common/utils/time';
import { GenericStatsFilterInput } from './common';

const GET_PIPELINE_STATISTICS = gql`
  query getPipelineStatistics(
    $clientId: ID!
    $missionsFilter: GenericStatsFilterInput
    $stagesFilter: GenericStatsFilterInput
    $withInterestedSubSteps: Boolean
    $archivedStatusesFilter: GenericStatsFilterInput
    $startDate: Date
    $endDate: Date
  ) {
    client(id: $clientId) {
      id
      statistics {
        volumeByPipeline(
          missionsFilter: $missionsFilter
          stagesFilter: $stagesFilter
          withInterestedSubSteps: $withInterestedSubSteps
          archivedStatusesFilter: $archivedStatusesFilter
          startDate: $startDate
          endDate: $endDate
        ) {
          missionId
          data {
            state
            archived
            count
          }
        }
      }
    }
  }
`;

interface GetPipelineStatisticsVariables {
  clientId: string;
  missionsFilter?: GenericStatsFilterInput;
  stagesFilter?: GenericStatsFilterInput;
  withInterestedSubSteps?: boolean;
  archivedStatusesFilter?: GenericStatsFilterInput;
  startDate?: string;
  endDate?: string;
}

interface VolumeByPipelineForMission {
  missionId: string;
  data: PipelineCountDataPoint[];
}

interface GetPipelineStatisticsData {
  client: {
    id: string;
    statistics: {
      volumeByPipeline: VolumeByPipelineForMission[];
    };
  };
}

interface UsePipelineStatisticsInput extends GetPipelineStatisticsVariables {
  queryOptions?: QueryHookOptions<
    GetPipelineStatisticsData,
    GetPipelineStatisticsVariables
  >;
}

interface UsePipelineStatisticsResult
  extends QueryResult<
    GetPipelineStatisticsData,
    GetPipelineStatisticsVariables
  > {
  volumeByPipeline?: VolumeByPipelineForMission[];
}

const usePipelineStatistics = ({
  clientId,
  missionsFilter,
  stagesFilter,
  withInterestedSubSteps,
  archivedStatusesFilter,
  startDate,
  endDate,
  queryOptions = {},
}: UsePipelineStatisticsInput): UsePipelineStatisticsResult => {
  const query = useQuery<
    GetPipelineStatisticsData,
    GetPipelineStatisticsVariables
  >(GET_PIPELINE_STATISTICS, {
    ...queryOptions,
    variables: {
      clientId,
      missionsFilter,
      stagesFilter,
      withInterestedSubSteps,
      archivedStatusesFilter,
      startDate,
      endDate: isTodayYyyyMmDd(endDate) ? undefined : endDate,
    },
  });

  const { volumeByPipeline } = query.data?.client.statistics || {};

  return { ...query, volumeByPipeline };
};

export default usePipelineStatistics;
