import React, { useCallback, useContext, useMemo, useState } from 'react';
import _, { compose } from 'underscore';
import { Image } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import ActionButton from '@/components/ActionButton';
import {
  combineDataWithCustomFields,
  getReadableValue,
} from '@/common/customFields';
import TranslatableText from '@/components/TranslatableText';

import {
  getSecureLink,
  getTranslatedText,
  getRandomDefaultAvatarLink,
  removeEmptyKeyValues,
  getRandomString,
} from '@/common';
import logAction from '@/common/logAction';
import withProfileCurrentTask from '@/hocs/searchPoolProfile/withProfileCurrentTask';
import withActionLogger from '@/hocs/withActionLogger';
import withUserFromJWToken from '@/hocs/users/withUserFromJWToken';
import withMarkProfileAsShared from '@/hocs/profiles/withMarkProfileAsShared';
import LoadingComponent from '@/components/LoadingComponent';
import SaveProfileInfoToClipboard from '@/containers/hotkeys/SaveProfileInfoToClipboard';
import ProfileSources from '@/containers/Profile/ProfileSources';

import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import './RevealProfileHeader.css';
import NewProfileMissionsManagement from '@/revealComponents/ProfileProjectsTab/NewProfileMissionsManagement/NewProfileMissionsManagement';
import DoNotContactPill from '@/components/Reveal/Profile/privacy/DoNotContactPill/DoNotContactPill';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import ProfileViews from '@/components/Profile/ProfileViews';
import GenericTooltip from '@/components/Common/GenericTooltip';
import { getTaskType } from '@/common/constants/taskTypes';
import { sweetDateToMomentDate } from '@/common/dates';
import useIsPlugin from '@/hooks/common/useIsPlugin';
import classNames from 'classnames';
import CompanyStageEdition from '@/revealComponents/ProfileProjectsTab/CompanyStageEdition';
import withClientPermissions from '@/hocs/clients/withClientPermissions';
import Tag from '@/components/Common/Tag';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import HiredPill from '@/components/Reveal/Profile/HiredPill';
import ProfileActionsMenu from './ProfileActionsMenu';
import DownloadResumeButton from './DownloadResumeButton';
import RevealLinkedProfilesSwitch from './RevealLinkedProfilesSwitch';
import ContactCategoryContext from '../../../../../context/ContactTypeContext';
import { ProfileMissionInfo } from '../ProfileSharedFields/ProfileSharedFields';

export const replacePhotoLink = (link: string) =>
  (link || '').replace('shrinknp_400_400', 'shrinknp_75_75');

function isProfileDeletable(profile: any) {
  const ATS_KEYS = [
    'smartrecruiters',
    'lever',
    'greenhouse',
    'teamtailor',
    'workable',
    'recruitee',
  ];
  return _.isEmpty(
    removeEmptyKeyValues(_.pick(profile?.resumeData?.sourceData, ATS_KEYS)),
  );
}

const getElementWidth = (id: string) => {
  return document.getElementById(id)?.getBoundingClientRect()?.width;
};

class RevealProfileHeader extends React.PureComponent {
  static defaultProps = {
    editable: false,
  };

  constructor(props: any) {
    super(props);
    this.state = {
      linkCopiedToClipboard: false,
    };
  }

  shareProfile = async () => {
    const { markProfileAsShared, clientId, profileId, offerId, profile }: any =
      this.props;
    if (!clientId || !profileId || !(offerId || profile?.offer?.id)) {
      console.error('Missing props');
      return;
    }
    await markProfileAsShared({ profileId });
    await navigator.clipboard.writeText(
      `${window.origin}/client/${clientId}/jobs/${
        offerId || profile?.offer?.id
      }/profiles?pid=${encodeURIComponent(btoa(profileId))}`,
    );
    this.setState({ linkCopiedToClipboard: true });
  };

  copyEmail = async () => {
    const { profile }: any = this.props;
    if (profile?.resumeData?.email) {
      await navigator.clipboard.writeText(profile?.resumeData?.email);
    }
  };

  render() {
    const {
      t,
      location: routerLocation,
      profileId,
      stepId,
      resumeData,
      firstname,
      lastname,
      user,
      currentTask,
      clientId,
      profile,
      offerId,
      customFields,
      showEmailInHeader,
      // withBottomMargin,
      applicationReviewMode,
      editable,
      shouldHideProfileHeaderEditionButtons,
      shouldHideResumeDownloadButton,
      displayProjectAndSequenceOptions = true,
      toggleEditMode,
      deleteProfile,
      missionsInfo,
      searchPoolId,
      onChangeStage,
      permissions,
      shouldDisplayHiredPill,
    }: any = this.props;
    const { linkCopiedToClipboard }: any = this.state;

    const unbiasedModeEnabled =
      (user || {}).unbiasedModeEnabled &&
      (stepId === 'pending' || applicationReviewMode);
    const {
      mainEducation,
      photoLink,
      headline,
      location,
      phoneNumber,
      phoneNumber2,
      phoneNumber3,
    } = resumeData || {};
    const website = resumeData?.sources?.website;
    const headlineText = ((headline || {}).content || {}).text;
    const locationName = getTranslatedText((location || {}).name || '');
    const locationCanBeDisplayed = getLocationCanBeDisplayed(locationName);
    const { schoolName } = mainEducation || {};
    const isHiredInAMission = !!_.find(
      missionsInfo,
      (m: ProfileMissionInfo) =>
        !m.data?.archived && m.data?.segmentationStates?.[0]?.state === 'hired',
    );

    const endDate = sweetDateToMomentDate(
      mainEducation && mainEducation.endDate,
    );

    // const studiedAt =
    //   resumeData?.firstname && isFemaleName(resumeData?.firstname)
    //     ? t('profile.resume.femaleStudiedAt')
    //     : t('profile.resume.studiedAt');

    const mainEducationText = schoolName
      ? `${schoolName} ${endDate ? `(${endDate.fromNow()})` : ''}`
      : '';

    const isDeletable = isProfileDeletable(profile);

    const getFullHeadline = () => {
      let text = headlineText || '';
      if (headlineText && locationCanBeDisplayed && locationName) text += ' • ';
      if (locationCanBeDisplayed && locationName) text += locationName;
      return text;
    };

    const onClickSource = ({ e, sourceId }: { e: any; sourceId: string }) => {
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }

      logAction({
        searchPoolProfileId: profile?.id,
        type: 'click-profile-source',
        info: {
          sourceId,
          from: 'profile-header',
        },
      });

      if (sourceId === 'linkedin') {
        setPluginTaskState({ task: currentTask });
      }
    };

    const windowLocation = routerLocation.pathname;
    const displayLinkedProfiles =
      !/reveal\/tasks/.test(windowLocation) &&
      !/reveal\/tasks/.test(windowLocation);

    const hasBackgroundValues =
      !_.isEmpty(profile.resumeData?.experiences) ||
      !_.isEmpty(profile.resumeData?.education) ||
      !_.isEmpty(profile.resumeData?.summary?.content?.text);

    return (
      <SaveProfileInfoToClipboard
        profile={profile}
        offerId={offerId || profile?.offer?.id}
      >
        <div className='reveal-profile-top-header-container'>
          <div className='avatar-and-infos'>
            <AvatarAndEnrichmentDateIndicator
              firstname={firstname}
              lastname={lastname}
              photoLink={photoLink}
              website={website}
              unbiasedModeEnabled={unbiasedModeEnabled}
              lastEnrichmentDate={
                resumeData?.enrichmentInfo?.lastEnrichmentDate
              }
              creationTimestamp={+profile.creationTimestamp}
              sourceType={profile.source?.type}
              sourceLinkedin={resumeData?.sources?.linkedin}
              hasBackgroundValues={hasBackgroundValues}
              t={t}
            />
            <div className='infos'>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={30}
                margin={0.6}
              >
                <div className='inline-middle'>
                  <h2
                    className={`fullname${
                      unbiasedModeEnabled ? ' unbiased' : ''
                    }`}
                    data-openreplay-masked
                  >
                    {resumeData?.firstname} {resumeData?.lastname}
                    {user?.isAdmin &&
                      clientId &&
                      profileId &&
                      (offerId || profile?.offer?.id) && (
                        <i
                          className={`ri-external-link-line${
                            linkCopiedToClipboard ? ' copied' : ''
                          }`}
                          onClick={() => this.shareProfile()}
                        />
                      )}
                  </h2>
                </div>
                {clientId.includes('chantal-baudron') && (
                  <ProfileTypePills
                    contactCategory={profile?.contactCategory}
                  />
                )}
                <ProfileViews profile={profile} />
                {profile.privacyState?.markedAsDoNotContact && (
                  <DoNotContactPill
                    text={getTranslatedText(
                      profile.privacyState?.reason?.title || {},
                    )}
                  />
                )}
                {isHiredInAMission && shouldDisplayHiredPill && <HiredPill />}
                {!shouldHideProfileHeaderEditionButtons && (
                  <>
                    {editable && (
                      <div className='icons-container inline-middle'>
                        <ActionButton
                          icon='edit-2-fill ri-lg'
                          onClick={() => toggleEditMode()}
                        />
                        {isDeletable && (
                          <ActionButton
                            icon='delete-bin-fill ri-lg'
                            onClick={() => deleteProfile()}
                          />
                        )}
                      </div>
                    )}
                    <ProfileActionsMenu profile={profile} clientId={clientId} />
                  </>
                )}
                {displayLinkedProfiles && (
                  <RevealLinkedProfilesSwitch profile={profile} />
                )}
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              >
                <p className='headline'>{getFullHeadline()}</p>
                <p className='main-education'>{mainEducationText}</p>
              </LoadingComponent>
              <LoadingComponent
                as='div'
                loading={_.isEmpty(resumeData)}
                length={50}
                margin={0.6}
              />
              <div className='sources-container'>
                <PhoneNumber phoneNumber={phoneNumber} />
                {phoneNumber2 && <PhoneNumber phoneNumber={phoneNumber2} />}
                {phoneNumber3 && <PhoneNumber phoneNumber={phoneNumber3} />}
                <Email
                  email={resumeData?.email}
                  showEmailInHeader={showEmailInHeader}
                />
                {resumeData?.email2 && (
                  <Email
                    email={resumeData?.email2}
                    showEmailInHeader={showEmailInHeader}
                  />
                )}
                {resumeData?.email3 && (
                  <Email
                    email={resumeData?.email3}
                    showEmailInHeader={showEmailInHeader}
                  />
                )}
                <ProfileSources
                  sources={resumeData?.sources}
                  onClickSource={onClickSource}
                />
                {!shouldHideResumeDownloadButton && (
                  <DownloadResumeButton
                    profileId={profileId}
                    searchPoolId='reveal'
                  />
                )}
                {permissions?.canSearchNameOnLinkedIn &&
                  !resumeData?.sources?.linkedin &&
                  profile.contactCategory?.type !== 'company' && (
                    <a
                      className='search-linkedin'
                      href={`https://www.linkedin.com/search/results/all/?keywords=${encodeURIComponent(`${resumeData?.firstname || ''} ${resumeData?.lastname || ''}`.trim())}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('profile.resume.searchOnLinkedin')}
                    </a>
                  )}
              </div>
            </div>
          </div>
          {searchPoolId === 'reveal' && displayProjectAndSequenceOptions && (
            <div className='mission-sequence-container'>
              <NewProfileMissionsManagement
                clientId={clientId}
                missionsInfo={missionsInfo}
                missions={_.pluck(missionsInfo || [], 'mission')}
                onChangeStage={onChangeStage}
              />
            </div>
          )}
          {searchPoolId === 'reveal' &&
            !displayProjectAndSequenceOptions &&
            profile.contactCategory?.type === 'company' &&
            permissions?.companiesDealStates && (
              <CompanyStageEdition
                company={profile}
                onChangeStage={onChangeStage}
              />
            )}
        </div>
        <ProfileHeaderCustomFields
          customFields={customFields}
          resumeData={resumeData}
        />
      </SaveProfileInfoToClipboard>
    );
  }
}

export const ProfileTypePills = ({
  contactCategory,
}: {
  contactCategory: { subtypes: { id: string }[] };
}) => {
  const { t } = useTranslation();

  if (contactCategory?.type === 'company') {
    return null;
  }

  const subTypes = _.isEmpty(contactCategory?.subtypes)
    ? [{ id: 'hiring' }]
    : contactCategory?.subtypes;

  return _.map(subTypes, ({ id }) => (
    <span key={id} style={{ display: 'inline-block', marginRight: 3 }}>
      <Tag color={id === 'hiring' ? 'blue' : 'green'}>
        {id === 'hiring'
          ? t('profile.resume.candidate')
          : t('profile.resume.interlocutor')}
      </Tag>
    </span>
  ));
};

export function ProfileHeaderCustomFields({
  customFields,
  resumeData,
}: {
  customFields: any;
  resumeData: any;
}) {
  const isPlugin = useIsPlugin();
  const atsId = useClientCurrentAtsId();
  const topFields = (customFields || []).filter(
    (cf: any) => cf.display === 'profile-top',
  );

  const { containerRef, listRef, displayedItemsNumber, finalWidth } =
    useResizableItemList<HTMLDivElement>(24);

  const fields = combineDataWithCustomFields(topFields, resumeData);
  const fieldsWithValue = _.filter(fields, (field: any) => field.readableValue);

  if (_.isEmpty(fieldsWithValue)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'custom-fields-container',
        isPlugin ? 'plugin-custom-fields' : '',
        atsId === 'adventure' ? 'adventure' : '',
      )}
      ref={containerRef}
    >
      <div
        className='custom-fields-list'
        ref={listRef}
        style={{ width: finalWidth + displayedItemsNumber * 4 }}
      >
        {fieldsWithValue.map((f, index) =>
          !f.readableValue ? null : (
            <div
              key={f.clientCustomFieldId}
              className={`header-custom-field ${
                index > displayedItemsNumber ? 'hidden' : ''
              }`}
            >
              <div className='custom-field-title'>
                <TranslatableText text={f.title} />
              </div>
              <div className='custom-field-value'>{f.readableValue}</div>
            </div>
          ),
        )}
      </div>
      {fieldsWithValue.length - displayedItemsNumber > 0 && (
        <div className='header-custom-field'>
          <div className='custom-field-value'>
            +{fieldsWithValue.length - displayedItemsNumber}
          </div>
        </div>
      )}
    </div>
  );
}

export function ProfileMissionCustomFields({
  customFields,
  customFieldDefinitions,
  missionId,
  profileId,
}: {
  customFields: any;
  customFieldDefinitions: any;
  missionId: string;
  profileId: string;
}) {
  const isPlugin = useIsPlugin();
  const atsId = useClientCurrentAtsId();

  const [updateCustomFields] = useMutation(
    UPDATE_PROFILE_MISSION_CUSTOM_FIELD,
    {
      refetchQueries: ['getEnrichedSearchPoolProfile'],
    },
  );

  const saveCustomField = useCallback(
    async ({
      customFieldId,
      value,
    }: {
      customFieldId: string;
      value: string;
    }) => {
      const hasCustomField = (customFields || []).some(
        (cf: any) => cf.id === customFieldId,
      );
      const customFieldsInput = (customFields || []).map((cf: any) => {
        if (customFieldId === cf.id) {
          return {
            ..._.omit(cf, ['__typename']),
            clientCustomFieldId: cf.clientCustomFieldId,
            ...(cf.type === 'enum' && { selected: [value] }),
            ...(cf.type === 'inline-text' && { text: value }),
            ...(cf.type === 'day' && {
              date: !_.isEmpty(value) ? new Date(value).toISOString() : null,
            }),
            ...(cf.type === 'integer' && { integer: parseInt(value, 10) }),
            ...(cf.type === 'float' && { float: parseFloat(value) }),
          };
        }
        return {
          ..._.omit(cf, ['__typename']),
          ...(cf.type === 'day' && {
            date: new Date(+cf.date).toISOString(),
          }),
        };
      });
      let input = {};
      if (!hasCustomField) {
        const matchingField = (customFieldDefinitions || []).find(
          (cf: any) => cf.id === customFieldId,
        );
        input = {
          customFields: [
            ...customFieldsInput,
            {
              clientCustomFieldId: customFieldId,
              ...(matchingField.type === 'enum' && { selected: [value] }),
              ...(matchingField.type === 'inline-text' && { text: value }),
              ...(matchingField.type === 'day' && {
                date: new Date(+value).toISOString(),
              }),
              ...(matchingField.type === 'integer' && {
                integer: parseInt(value, 10),
              }),
              ...(matchingField.type === 'float' && {
                float: parseFloat(value),
              }),
              id: getRandomString(12),
              type: matchingField.type,
            },
          ],
          missionId,
          profileId,
        };
      } else {
        input = {
          customFields: customFieldsInput,
          missionId,
          profileId,
        };
      }

      try {
        await updateCustomFields({
          variables: {
            input,
            searchPoolId: 'reveal',
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    [
      customFields,
      missionId,
      profileId,
      updateCustomFields,
      customFieldDefinitions,
    ],
  );

  const fields = useMemo(
    () =>
      combineDataWithCustomFields(customFieldDefinitions, {
        customFields,
      }),
    [customFields, customFieldDefinitions],
  );

  const fieldsWithValue = _.filter(fields, (field: any) => field.readableValue);

  if (_.isEmpty(fieldsWithValue)) {
    return null;
  }

  return (
    <div
      className={classNames(
        'custom-fields-container',
        isPlugin ? 'plugin-custom-fields' : '',
        atsId === 'adventure' ? 'adventure' : '',
      )}
    >
      <div className='process-custom-fields-list'>
        {(fields || []).map((f) => (
          <ProfileMissionCustomField
            key={f.id}
            customField={f}
            customFieldDefinition={_.findWhere(customFieldDefinitions || [], {
              id: f.clientCustomFieldId,
            })}
            saveCustomField={saveCustomField}
          />
        ))}
      </div>
    </div>
  );
}

const UPDATE_PROFILE_MISSION_CUSTOM_FIELD = gql`
  mutation UpdateProfileMissionStateCustomFields(
    $input: UpdateProfileMissionStateCustomFieldsInput!
    $searchPoolId: ID!
  ) {
    searchPoolProfile(searchPoolId: $searchPoolId) {
      updateProfileMissionStateCustomFields(input: $input) {
        profile {
          missionsInfo {
            data {
              customFields {
                clientCustomFieldId
                id
                type
                ... on CustomFieldValueText {
                  text
                }
                ... on CustomFieldValueInteger {
                  integer
                }
                ... on CustomFieldValueFloat {
                  float
                }
                ... on CustomFieldValueEnum {
                  selected
                }
                ... on CustomFieldValueDay {
                  date
                }
              }
            }
          }
        }
      }
    }
  }
`;

const isoStringToDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${month}/${day}/${year}`;
};

const ProfileMissionCustomField = ({
  customField,
  customFieldDefinition,
  saveCustomField,
}: {
  customField: any;
  customFieldDefinition: any;
  saveCustomField: ({ customFieldId, value }: any) => void;
}) => {
  const [edition, setEdition] = useState(false);
  const [value, setValue] = useState(
    customField.type === 'enum'
      ? customField.rawValue[0]
      : customField.type === 'day'
        ? isoStringToDate(new Date(+customField.rawValue))
        : customField.rawValue,
  );

  const handleSaveCustomField = ({ value }: any) => {
    saveCustomField({ customFieldId: customField.id, value });
  };

  const { title } = customField;
  return (
    <div
      key={customField.clientCustomFieldId}
      className='process-custom-field'
      onBlur={() => setEdition(false)}
    >
      <div className='custom-field-title'>
        <TranslatableText text={title} />
      </div>
      {edition ? (
        <div className='custom-field-value'>
          {(customField.type === 'inline-text' ||
            customField.type === 'text') && (
            <input
              type='text'
              value={value}
              autoFocus
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                handleSaveCustomField({
                  value: e.target.value,
                });
                setEdition(false);
              }}
            />
          )}
          {customField.type === 'integer' && (
            <input
              type='number'
              value={value}
              autoFocus
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                handleSaveCustomField({
                  value: e.target.value,
                });
                setEdition(false);
              }}
            />
          )}
          {customField.type === 'float' && (
            <input
              type='number'
              step='0.01'
              value={value}
              autoFocus
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                handleSaveCustomField({
                  value: e.target.value,
                });
                setEdition(false);
              }}
            />
          )}
          {customField.type === 'day' && (
            <input
              autoFocus
              type='date'
              onChange={(e) => setValue(e.target.value)}
              value={value}
              onBlur={(e) => {
                handleSaveCustomField({
                  value: e.target.value,
                });
                setEdition(false);
              }}
            />
          )}
          {customField.type === 'enum' && (
            <select
              autoFocus
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onBlur={(e) => {
                handleSaveCustomField({
                  value: e.target.value,
                });
                setEdition(false);
              }}
            >
              {customFieldDefinition.options.map((value: any) => (
                <option key={value.id} value={value.id}>
                  {value.title.default}
                </option>
              ))}
            </select>
          )}
        </div>
      ) : (
        <>
          <div className='custom-field-value'>
            {getReadableValue(
              customField?.type === 'enum' ? [value] : value,
              customField,
            )}
          </div>
          <div className='custom-field-actions'>
            <div className='custom-field-edit'>
              <ActionButton
                icon='edit-2-fill ri-sm'
                onClick={() => setEdition(true)}
                className='edit-button small'
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const setPluginTaskState = ({ task }: any) => {
  let message;
  if (task) {
    message = {
      type: 'set-task-state',
      data: { task, activeTaskListId: getTaskType(task) },
    };
  } else {
    message = {
      type: 'clear-task-state',
    };
  }
  window.postMessage(message, '*');
};

const getLocationCanBeDisplayed = (locationName: any) => {
  if (/\d/.test(locationName)) {
    // No digits in locationName
    return false;
  }
  return true;
};

export const getBeautifiedPhoneNumber: any = ({ phoneNumber }: any) => {
  if (!phoneNumber) {
    return phoneNumber;
  }
  if (phoneNumber.indexOf('.') >= 0) {
    return phoneNumber;
  }
  const withoutSpace = phoneNumber.replace(/\s/g, '');

  if (withoutSpace.indexOf('+336') === 0) {
    return `+336 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }
  if (withoutSpace.indexOf('+337') === 0) {
    return `+337 ${getBeautifiedPhoneNumber({
      phoneNumber: withoutSpace.slice(4),
    })}`;
  }

  if (/^[0-9]*$/.test(withoutSpace)) {
    if (withoutSpace.length === 8 || withoutSpace.length === 10) {
      let result = '';
      _.each(withoutSpace.split(''), (c) => {
        if (
          result.length > 1 &&
          result[result.length - 1] !== ' ' &&
          result[result.length - 2] !== ' '
        ) {
          result += ' ';
        }
        result += c;
      });
      return result;
    }
  }
  return phoneNumber;
};

interface AvatarAndEnrichmentDateIndicatorProps {
  photoLink?: string;
  unbiasedModeEnabled?: boolean;
  firstname?: string;
  lastname?: string;
  lastEnrichmentDate?: string;
  creationTimestamp?: number;
  sourceType?: string;
  sourceLinkedin?: string;
  hasBackgroundValues?: boolean;
  website?: string;
  t: any;
}

export const AvatarAndEnrichmentDateIndicator: React.FC<
  AvatarAndEnrichmentDateIndicatorProps
> = ({
  photoLink,
  unbiasedModeEnabled,
  firstname,
  lastname,
  lastEnrichmentDate,
  creationTimestamp,
  sourceType,
  sourceLinkedin,
  hasBackgroundValues,
  website,
  t,
}) => {
  const contactCategory = useContext(ContactCategoryContext);
  const avatarImageUrl =
    photoLink && !unbiasedModeEnabled
      ? replacePhotoLink(photoLink)
      : getRandomDefaultAvatarLink(`${firstname}${lastname}`);

  const nbMsInAMonth = 30 * 86400 * 1000;
  const isNotRecentlyUpdated =
    lastEnrichmentDate &&
    new Date(lastEnrichmentDate) < new Date(Date.now() - 4 * nbMsInAMonth);

  return (
    <div className='avatar' data-openreplay-masked>
      <Image
        data-openreplay-masked
        className='card-company-image'
        src={getSecureLink(
          contactCategory === 'company' &&
            website &&
            (!avatarImageUrl || avatarImageUrl.includes('default-avatar'))
            ? `https://logo.clearbit.com/${website}`
            : avatarImageUrl,
        )}
        circular
        onError={(e: any) => {
          e.target.src = getRandomDefaultAvatarLink(`${firstname}${lastname}`);
        }}
      />
      {(lastEnrichmentDate ||
        (sourceType === 'reveal-plugin-import' &&
          hasBackgroundValues &&
          sourceLinkedin)) && (
        <GenericTooltip
          trigger={
            <i
              className={`ri-refresh-fill last-sync-icon ${
                isNotRecentlyUpdated ? ' not-updated' : ''
              }`}
            />
          }
          content={linkedinLastSyncDiff({
            date:
              lastEnrichmentDate ||
              new Date(creationTimestamp || 0).toISOString(),
            t,
          })}
          position='right center'
          popperModifiers={[
            {
              // Fix a positioning problem
              name: 'preventOverflow',
              options: {
                boundariesElement: 'offsetParent',
              },
            },
          ]}
        />
      )}
    </div>
  );
};

interface EmailProps {
  email?: string;
  showEmailInHeader?: boolean;
}

const Email: React.FC<EmailProps> = ({ email, showEmailInHeader }) => {
  const [emailCopiedToClipboard, setEmailCopiedToClipboard] = useState(false);
  const [popupWidth, setPopupWidth] = useState('100px');
  if (!email || !showEmailInHeader) {
    return null;
  }

  const copyMailToClipBoard = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setPopupWidth(`${getElementWidth('mail-popup') || 100}px`);
    await navigator.clipboard.writeText(email);
    setEmailCopiedToClipboard(true);
    setTimeout(() => {
      setEmailCopiedToClipboard(false);
    }, 1000);
  };

  return (
    <button type='button' className='hover-icon' onClick={copyMailToClipBoard}>
      <GenericTooltip
        trigger={<i className='ri-mail-fill' />}
        content={
          emailCopiedToClipboard ? (
            <div
              style={{
                width: popupWidth,
                textAlign: 'center',
              }}
            >
              Copied
            </div>
          ) : (
            <span id='mail-popup'>{email}</span>
          )
        }
        position='bottom center'
        positionFixed
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          },
        ]}
        on='hover'
      />
    </button>
  );
};

interface PhoneNumberProps {
  phoneNumber?: string;
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ phoneNumber }) => {
  const [numberCopiedToClipboard, setNumberCopiedToClipboard] = useState(false);
  const [popupWidth, setPopupWidth] = useState('100px');

  if (!phoneNumber) {
    return null;
  }

  const copyNumberToClipBoard = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setPopupWidth(`${getElementWidth('number-popup') || 100}px`);
    await navigator.clipboard.writeText(phoneNumber);
    setNumberCopiedToClipboard(true);
    setTimeout(() => {
      setNumberCopiedToClipboard(false);
    }, 1000);
  };

  return (
    <button
      type='button'
      className='hover-icon'
      onClick={copyNumberToClipBoard}
    >
      <GenericTooltip
        trigger={<i className='ri-phone-fill' />}
        content={
          numberCopiedToClipboard ? (
            <div
              style={{
                width: popupWidth,
                textAlign: 'center',
              }}
            >
              Copied
            </div>
          ) : (
            <span id='number-popup'>
              {getBeautifiedPhoneNumber({ phoneNumber })}
            </span>
          )
        }
        position='bottom center'
        positionFixed
        popperModifiers={[
          {
            // Fix a positioning problem
            name: 'preventOverflow',
            options: {
              boundariesElement: 'offsetParent',
            },
          },
        ]}
        on='hover'
      />
    </button>
  );
};

const linkedinLastSyncDiff = ({ date, t }: { date: string; t: any }) => {
  if (!date) {
    return t('profile.resume.notSync');
  }
  const lastSync = Date.parse(date);
  const now = Date.now();
  const diff = (now - lastSync) / 1000;
  const DAY = 86400;
  // less than one day
  if (diff < DAY) {
    return t('profile.resume.lastLinkedInSyncToday');
  }
  // less than 5 days
  if (diff < DAY * 6) {
    return t('profile.resume.lastLinkedInSyncSinceDays', {
      count: Math.floor(diff / 84600),
    });
  }
  // weeks
  if (diff < 28 * DAY) {
    return t('profile.resume.lastLinkedInSyncSinceWeeks', {
      count: Math.max(1, Math.floor(diff / (7 * 84600))),
    });
  }
  // months
  if (diff < 7 * 30 * DAY) {
    return t('profile.resume.lastLinkedInSyncSinceMonths', {
      count: Math.max(1, Math.floor(diff / (30 * DAY))),
    });
  }
  return t('profile.resume.notSync');
};

export default compose(
  withRouter,
  withActionLogger,
  withUserFromJWToken,
  withMarkProfileAsShared,
  withTranslation('translations'),
  withProfileCurrentTask,
  withClientPermissions,
)(RevealProfileHeader);
