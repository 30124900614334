import React, { useState } from 'react';
import _, { compose } from 'underscore';
import { Redirect, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, Form, Icon, Select } from 'semantic-ui-react';

import withAddTrialClientAndUser from '../../hocs/mutations/withAddTrialClientAndUser';
import withAddTrialClientAndOAuthUser from '../../hocs/mutations/withAddTrialClientAndOAuthUser';

import FloatingLabelInput from '../../components/FloatingLabelInput';
import ErrorModal from '../../components/modals/ErrorModal';
import OAuthGmailSignin from './OAuthGmailSignin';
import OAuthOutlookSignin from './OAuthOutlookSignin';
import PasswordChecks from './PasswordChecks';

import Separator from './Separator';
import Layout from './Layout';

import {
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from './validators';
import { passwordIsStrong } from '../../common';

import './GenericSigninForm.css';

const translations = {
  'free-trial': {
    startTrialWithGoogle: 'trial.signup.startTrialWithGoogle',
    startTrialWithMicrosoft: 'trial.signup.startTrialWithMicrosoft',
    email: 'trial.signup.email',
    password: 'trial.signup.password',
    startTrial: 'trial.signup.startTrial',
    networkError: 'trial.signup.networkError',
    title: 'trial.signup.title',
    firstname: 'trial.signup.firstname',
    lastname: 'trial.signup.lastname',
    company: 'trial.signup.company',
    submit: 'trial.signup.submit',
    previous: 'trial.signup.previous',
    errorModalHeader: 'trial.signup.errorModalHeader',
    backendError: 'trial.signup.backendError',
    rateLimitError: 'trial.signup.rateLimitError',
    alreadyHaveAnAccount: 'signup.alreadyHaveAnAccount',
    signin: 'signup.signin',
  },
  crm: {
    startTrialWithGoogle: 'trial.signup.startTrialWithGoogle',
    startTrialWithMicrosoft: 'trial.signup.startTrialWithMicrosoft',
    email: 'trial.signup.email',
    password: 'trial.signup.password',
    startTrial: 'trial.signup.startTrial',
    networkError: 'trial.signup.networkError',
    title: 'trial.signup.title',
    firstname: 'trial.signup.firstname',
    lastname: 'trial.signup.lastname',
    company: 'trial.signup.company',
    submit: 'trial.signup.submit',
    previous: 'trial.signup.previous',
    errorModalHeader: 'trial.signup.errorModalHeader',
    backendError: 'trial.signup.backendError',
    rateLimitError: 'trial.signup.rateLimitError',
    alreadyHaveAnAccount: 'signup.alreadyHaveAnAccount',
    signin: 'signup.signin',
  },
  marketplace: {
    startTrialWithGoogle: 'trial.signup.startTrialWithGoogle',
    startTrialWithMicrosoft: 'trial.signup.startTrialWithMicrosoft',
    email: 'trial.signup.email',
    password: 'trial.signup.password',
    startTrial: 'trial.signup.startMarketplaceTrial',
    networkError: 'trial.signup.networkError',
    title: 'trial.signup.marketplaceTitle',
    firstname: 'trial.signup.firstname',
    lastname: 'trial.signup.lastname',
    company: 'trial.signup.company',
    submit: 'trial.signup.submit',
    previous: 'trial.signup.previous',
    errorModalHeader: 'trial.signup.errorModalHeader',
    backendError: 'trial.signup.backendError',
    rateLimitError: 'trial.signup.rateLimitError',
    alreadyHaveAnAccount: 'signup.alreadyHaveAnAccount',
    signin: 'signup.signin',
  },
  'reveal-crm': {
    startTrialWithGoogle: 'trial.signup.startTrialWithGoogle',
    startTrialWithMicrosoft: 'trial.signup.startTrialWithMicrosoft',
    email: 'trial.signup.email',
    password: 'trial.signup.password',
    startTrial: 'trial.signup.startTrial',
    networkError: 'trial.signup.networkError',
    title: 'trial.signup.title',
    firstname: 'trial.signup.firstname',
    lastname: 'trial.signup.lastname',
    company: 'trial.signup.company',
    submit: 'trial.signup.submit',
    previous: 'trial.signup.previous',
    errorModalHeader: 'trial.signup.errorModalHeader',
    backendError: 'trial.signup.backendError',
    rateLimitError: 'trial.signup.rateLimitError',
    alreadyHaveAnAccount: 'signup.alreadyHaveAnAccount',
    signin: 'signup.signin',
    accountType: 'trial.signup.accountType',
    language: 'trial.signup.language',
    selectLanguage: 'trial.signup.selectLanguage',
    'recruitment-agency': 'trial.signup.recruitmentAgency',
    'temp-agency': 'trial.signup.tempAgency',
    'start-up': 'trial.signup.startUp',
    'large-company': 'trial.signup.largeCompany',
    smb: 'trial.signup.smb',
    'independant-recruiter': 'trial.signup.independantRecruiter',
  },
};

const InitialForm = ({
  email,
  preClientName,
  setEmail,
  password,
  setPassword,
  setIdToken,
  setFirstname,
  setLastname,
  setAlias,
  setSignature,
  setType,
  setCompany,
  setFormStep,
  mode,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [passwordInputHasFocus, setPasswordInputHasFocus] = useState(false);
  const onChangeEmail = (e) => setEmail(e.target.value);

  const onChangePassword = (e) => setPassword(e.target.value);

  const onOAuthSubmit = ({
    email: oAuthEmail,
    firstname,
    lastname,
    alias,
    signature,
    id_token: token,
    type,
  }) => {
    // TODO : error handling / missing fields / reset libs cache to avoid lock
    if (oAuthEmail) {
      setEmail(oAuthEmail);
      setCompany(preClientName || guessCompanyName(oAuthEmail));
    }
    if (firstname) {
      setFirstname(firstname);
    }
    if (lastname) {
      setLastname(lastname);
    }
    if (alias) {
      setAlias(alias);
    }
    if (signature) {
      setSignature(signature);
    }
    if (token) {
      setIdToken(token);
      setType(type);
      setPassword('');
    }
    setFormStep(1);
  };

  const onOAuthError = ({ provider }) => {
    setError(provider);
  };

  const onBeforeOAuth = () => {
    setError(null);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setCompany(preClientName || guessCompanyName(email));
    setIdToken('');
    setType('');
    setFormStep(1);
  };
  const disableSubmit = !email.includes('@') || !passwordIsStrong(password);

  return (
    <div className='generic-signin-form'>
      <div className='link-to-signin-container'>
        <AlreadyHaveAnAccountLink mode={mode} t={t} />
      </div>
      <div className='signin-buttons'>
        <OAuthGmailSignin
          label={t(translations[mode].startTrialWithGoogle)}
          onSignin={onOAuthSubmit}
          onBeforeSignin={onBeforeOAuth}
          onError={onOAuthError}
          mode='signup'
        />
        <OAuthOutlookSignin
          label={t(translations[mode].startTrialWithMicrosoft)}
          onSignin={onOAuthSubmit}
          onBeforeSignin={onBeforeOAuth}
          onError={onOAuthError}
          mode='signup'
        />
      </div>
      {error && (
        <div className='warning-message'>{t(`signin.error.${error}`)}</div>
      )}
      <Separator className='signin-separator-dark'>{t('signin.or')}</Separator>
      <Form className='trial-form'>
        <Form.Field>
          <FloatingLabelInput
            className='trial-form-field'
            type='email'
            value={email}
            name='email'
            label={t(translations[mode].email)}
            onChange={onChangeEmail}
          />
        </Form.Field>
        <Form.Field>
          <FloatingLabelInput
            className='trial-form-field'
            type='password'
            value={password}
            name='password'
            label={t(translations[mode].password)}
            onChange={onChangePassword}
            onFocus={() => setPasswordInputHasFocus(true)}
            onBlur={() => setPasswordInputHasFocus(false)}
          />
          <PasswordChecks
            password={password}
            inputHasFocus={passwordInputHasFocus}
          />
        </Form.Field>
        <Form.Field className='submit-field'>
          <button
            className='submit-button'
            type='submit'
            onClick={onFormSubmit}
            disabled={disableSubmit}
          >
            {t(translations[mode].startTrial)}
          </button>
        </Form.Field>
      </Form>
    </div>
  );
};

const TrialSignupForm = ({
  addTrialClientAndUser,
  addTrialClientAndOAuthUser,
  mode,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [redirectToClientId, setRedirectId] = useState(false);
  const [backendErrors, setBackendErrors] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [formStep, setFormStep] = useState(0);

  const [idToken, setIdToken] = useState('');
  const [type, setType] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [alias, setAlias] = useState('');
  const [signature, setSignature] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [accountType, setAccountType] = useState('recruitment-agency');
  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') ||
      navigator.language.split('-')?.[0] ||
      'fr',
  );
  // const [termsAccepted, setTermsAccepted] = useState(false);

  const [loading, setLoading] = useState(false);

  const search = new URLSearchParams(location?.search);

  const preClientId = search.get('clientId');
  const preClientName = search.get('clientName');
  const token = search.get('token');

  if (mode === 'marketplace') {
    if (!preClientId || !token) {
      return <div />;
    }
  }

  if (formStep === 0) {
    return (
      <Layout title={t(translations[mode].title)} t={t}>
        <InitialForm
          t={t}
          preClientName={preClientName}
          email={email}
          password={password}
          setEmail={setEmail}
          setPassword={setPassword}
          setIdToken={setIdToken}
          setType={setType}
          setFirstname={setFirstname}
          setLastname={setLastname}
          setAlias={setAlias}
          setSignature={setSignature}
          setCompany={setCompany}
          setFormStep={setFormStep}
          mode={mode}
        />
      </Layout>
    );
  }

  const userInput = () => {
    return {
      firstname,
      lastname,
      email,
      password,
      accountType,
      language,
    };
  };

  const userOAuthInput = () => {
    return {
      firstname,
      lastname,
      phoneNumber,
      alias, // optional (gmail only)
      signature,
      idToken,
      type,
      accountType,
      language,
    };
  };

  const disableSubmit =
    loading ||
    _.isEmpty(email) ||
    _.isEmpty(firstname) ||
    _.isEmpty(lastname) ||
    _.isEmpty(company) ||
    !accountType;

  const handlerMap = {
    firstname: setFirstname,
    lastname: setLastname,
    company: setCompany,
    email: setEmail,
    password: setPassword,
    phoneNumber: setPhoneNumber,
  };

  const trimInputs = () => {
    setFirstname((firstname || '').trim());
    setLastname((lastname || '').trim());
    setCompany((company || '').trim());
    setEmail((email || '').trim());
    setPhoneNumber((phoneNumber || '').trim());
  };

  const onChange = (e) => {
    const { name, value } = e?.target || {};
    const newValue = value || '';
    handlerMap[name](newValue);
    setFormErrors(_.reject(formErrors, (error) => error.type === name));
  };

  const handleFormSubmit = async () => {
    trimInputs();
    let emailError;
    let passwordError;
    if (!idToken) {
      emailError = validateEmail({ email, t });
      passwordError = validatePassword({ password, t });
    }
    const phoneError = validatePhoneNumber({ phoneNumber, t });

    const formErrorsTemp = _.compact([emailError, passwordError, phoneError]);
    if (formErrorsTemp.length > 0) {
      setFormErrors(formErrorsTemp);
      return;
    }

    let graphQLErrors;
    let clientId;
    try {
      setLoading(true);
      if (!idToken) {
        const { data, errors } = await addTrialClientAndUser({
          clientName: company,
          userInput: userInput(),
          mode,
          ...(preClientId && { clientId: preClientId }),
          ...(token && { token }),
        });
        graphQLErrors = errors;
        clientId = data?.addTrialClientAndUser?.id;
      } else {
        const { data, errors } = await addTrialClientAndOAuthUser({
          clientName: company,
          userInput: userOAuthInput(),
          mode,
          ...(preClientId && { clientId: preClientId }),
          ...(token && { token }),
        });
        graphQLErrors = errors;
        clientId = data?.addTrialClientAndOAuthUser?.id;
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
      setBackendErrors([t(translations[mode].networkError)]);
      return;
    }
    if (graphQLErrors) {
      setLoading(false);
      setBackendErrors(graphQLErrors);
      return;
    }
    if (_.isString(clientId)) {
      setRedirectId(clientId);
    }
  };

  if (redirectToClientId) {
    if (mode === 'reveal-crm') {
      return (
        <Redirect
          to={`/client/${redirectToClientId}/reveal/projects/recruiting?newJobDefaultName=${t(
            'reveal.onboardingModal.newJob',
          )}`}
        />
      );
    }
    return (
      <Redirect to={`/client/${redirectToClientId}/discover/candidates`} />
    );
  }

  const onClickPrevious = () => {
    setFormStep(0);
    setPassword('');
    setEmail('');
  };

  return (
    <Layout title={t(translations[mode].title)}>
      <div className='generic-signin-form'>
        <Form loading={loading}>
          <Form.Field>
            <FloatingLabelInput
              className='trial-form-field first'
              type='text'
              value={firstname}
              name='firstname'
              label={t(translations[mode].firstname)}
              onChange={onChange}
              autoFocus
            />
          </Form.Field>
          <Form.Field>
            <FloatingLabelInput
              className='trial-form-field'
              type='text'
              value={lastname}
              name='lastname'
              label={t(translations[mode].lastname)}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <FloatingLabelInput
              className='trial-form-field'
              type='text'
              value={company}
              name='company'
              label={t(translations[mode].company)}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <label>{t(translations[mode].accountType)}</label>
            <Select
              labeled
              value={accountType}
              options={[
                {
                  key: 'recruitment-agency',
                  text: t(translations[mode]['recruitment-agency']),
                  value: 'recruitment-agency',
                },
                {
                  key: 'temp-agency',
                  text: t(translations[mode]['temp-agency']),
                  value: 'temp-agency',
                },
                {
                  key: 'start-up',
                  text: t(translations[mode]['start-up']),
                  value: 'start-up',
                },
                {
                  key: 'large-company',
                  text: t(translations[mode]['large-company']),
                  value: 'large-company',
                },
                { key: 'smb', text: t(translations[mode].smb), value: 'smb' },
                {
                  key: 'independant-recruiter',
                  text: t(translations[mode]['independant-recruiter']),
                  value: 'independant-recruiter',
                },
              ]}
              onChange={(_e, { value }) => setAccountType(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>{t(translations[mode].language)}</label>
            <Dropdown
              placeholder={t(translations[mode].selectLanguage)}
              fluid
              selection
              value={language}
              onChange={(_e, { value }) => setLanguage(value)}
              options={[
                { key: 'fr', value: 'fr', text: t('langs.french') },
                { key: 'en', value: 'en', text: t('langs.english') },
                { key: 'de', value: 'de', text: t('langs.german') },
              ]}
            />
          </Form.Field>
          <Form.Field className='submit-field'>
            <button
              className='submit-button'
              type='submit'
              onClick={handleFormSubmit}
              disabled={disableSubmit}
            >
              {t(translations[mode].submit)}
            </button>
          </Form.Field>

          <div className='underlined-link'>
            <span onClick={onClickPrevious}>
              {t(translations[mode].previous)}
            </span>
          </div>
        </Form>
        <ErrorModal
          open={!_.isEmpty(backendErrors)}
          onClose={() => setBackendErrors([])}
          header={t(translations[mode].errorModalHeader)}
          content={formatErrors({ errors: backendErrors, mode, t })}
        />
      </div>
    </Layout>
  );
};

const formatErrors = ({ errors, mode, t }) => {
  const formattedErrors = _.map(errors, (e) => {
    const { message, extensions } = e || {};
    const errorMessage = message || '';
    const msBeforeNextReset = (extensions || {}).msBeforeNextReset || 0;
    const sBeforeNextReset = (msBeforeNextReset / 1000).toFixed(0);

    // possible errors are:
    //  trial.signup.companyAlreadyExistsError
    //  trial.signup.providerAlreadyExistsError
    //  trial.signup.rateLimitError - DEPRECATED
    //  graphql.rateLimitError
    if (!errorMessage.startsWith('trial.signup')) {
      return t(translations[mode].backendError);
    }
    if (
      errorMessage === 'trial.signup.rateLimitError' ||
      errorMessage === 'graphql.rateLimitError'
    ) {
      return t(translations[mode].rateLimitError, { sBeforeNextReset });
    }
    return t(e.message);
  });
  return formattedErrors.join('\n');
};

const NOT_GUESSED_DOMAINS = [
  'outlook',
  'passport',
  'live',
  'msn',
  'gmail',
  'yopmail',
  'protonmail',
];

const guessCompanyName = (email) => {
  if (!_.isString(email) || !email.includes('@')) {
    return '';
  }
  const domain = email.split('@')[1] || '';
  const subDomains = domain.split('.');
  let guess = subDomains[subDomains.length - 2] || '';
  if (guess === 'co') {
    guess = subDomains[subDomains.length - 3] || '';
  }
  if (guess.includes('mail') || NOT_GUESSED_DOMAINS.includes(guess)) {
    return '';
  }

  return !_.isEmpty(guess) ? guess[0].toUpperCase() + guess.slice(1) : '';
};

const AlreadyHaveAnAccountLink = ({ mode, t }) => {
  return (
    <div className='already-have-an-account-link'>
      <span>{t(translations[mode].alreadyHaveAnAccount)}</span>
      <span>&nbsp;</span>
      <Link className='link-to-signin' to='signin'>
        {t(translations[mode].signin)}
        <Icon name='chevron right' size='small' />
      </Link>
    </div>
  );
};

export default compose(
  withAddTrialClientAndUser,
  withAddTrialClientAndOAuthUser,
)(TrialSignupForm);
