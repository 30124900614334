import { literalTypeChecker } from './types';

const supportedLanguages = ['fr', 'en', 'de'] as const;
const isSupportedLanguage = literalTypeChecker(supportedLanguages);
export type SupportedLanguage = (typeof supportedLanguages)[number];

export function getShortLanguage(resolvedLanguage?: string): SupportedLanguage {
  const lng = resolvedLanguage?.slice(0, 2);
  return isSupportedLanguage(lng) ? lng : 'en';
}

export default {};
