/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useMemo, useState } from 'react';
import { Dropdown, Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';

import {
  KLastCompaniesVariable,
  VariableExamplePreviewLocale,
} from '@/common/mergeTags/utils';
import GenericModal from '@/components/Common/GenericModal';

import GenericButton from '@/components/Common/GenericButton';
import { SweetEvaluatorTypes } from '@/SweetEvaluator';
import styles from '../SmartVariableWithPreviewModal.module.less';
import KLastCompaniesVariableExamples from './KLastCompaniesVariablesExamples';

type Props = {
  open: boolean;
  exampleVariable: SweetEvaluatorTypes.BaseVariable & KLastCompaniesVariable;
  onClose: () => void;
  onSubmit: (
    exampleVariable: SweetEvaluatorTypes.BaseVariable & KLastCompaniesVariable,
  ) => void;
  isEditMode?: boolean;
};

const KLastCompaniesModal: React.FC<Props> = ({
  open,
  exampleVariable,
  onClose,
  onSubmit,
  isEditMode = false,
}) => {
  const { t } = useTranslation();

  const [locale, setLocale] = useState<VariableExamplePreviewLocale>(
    (i18n.language as VariableExamplePreviewLocale) || 'en',
  );

  const [numberOfPreviousCompanies, setNumberOfPreviousCompanies] = useState(
    exampleVariable.number || 2,
  );

  const localeOptions = useMemo(() => {
    return [
      {
        key: 'en',
        text: t('editor.variableExemplePreview.locales.en'),
        value: 'en',
      },
      {
        key: 'fr',
        text: t('editor.variableExemplePreview.locales.fr'),
        value: 'fr',
      },
      {
        key: 'de',
        text: t('editor.variableExemplePreview.locales.de'),
        value: 'de',
      },
    ];
  }, [t]);

  const handleSubmit = () => {
    onSubmit({
      ...exampleVariable,
      locale,
      number: numberOfPreviousCompanies,
    });
    onClose();
  };

  return (
    <GenericModal size='large' open={open} onClose={onClose}>
      <Modal.Header>{t('editor.variableExemplePreview.title')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Form className={styles.form}>
          <div className={styles.left}>
            <div className={styles.padding}>
              <Form.Field>
                <label>{t('editor.variableExemplePreview.language')}</label>
                <Dropdown
                  value={locale}
                  onChange={(e, { value }) =>
                    setLocale(value as VariableExamplePreviewLocale)
                  }
                  className={styles.input}
                  fluid
                  selection
                  options={localeOptions}
                />
              </Form.Field>
              <Form.Field>
                <label>{t('editor.kLastCompaniesVariables.dropdown')}</label>
                <Dropdown
                  value={numberOfPreviousCompanies}
                  onChange={(e, { value }) =>
                    setNumberOfPreviousCompanies(value as number)
                  }
                  className={styles.input}
                  fluid
                  selection
                  options={_.map([2, 3, 4, 5], (num) => ({
                    key: num,
                    text: num,
                    value: num,
                  }))}
                />
              </Form.Field>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.padding}>
              <div className={styles.examples}>
                <h3 className={styles.examplesTitle}>
                  {t('editor.variableExemplePreview.preview')}
                </h3>
                <KLastCompaniesVariableExamples
                  variable={exampleVariable}
                  locale={locale}
                  numberOfPreviousCompanies={numberOfPreviousCompanies}
                />
              </div>
            </div>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <GenericButton
          type='button'
          primacy='secondary'
          size='big'
          onClick={onClose}
        >
          {t('common.cancel')}
        </GenericButton>
        <GenericButton
          size='big'
          primacy='primary'
          type='submit'
          onClick={handleSubmit}
        >
          {isEditMode ? t('common.edit') : t('common.create')}
        </GenericButton>
      </Modal.Actions>
    </GenericModal>
  );
};

export default KLastCompaniesModal;
