import React, { FC, useMemo, useState, useCallback } from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Dropdown, Loader } from 'semantic-ui-react';

import usePipelineStatisticsByCategory from '@/graphql/hooks/clients/usePipelineStatisticsByCategory';
import { Category } from '@/types/statistics/category';
import { genderArray } from '@/types/gender';
import {
  PipelineCountDataPoint,
  PipelineDetailDataPoint,
} from '@/types/statistics/pipeline';
import usePipelineStatisticsByCategoryDetails from '@/graphql/hooks/clients/usePipelineStatisticsByCategoryDetails';
import InfoTooltip from '@/components/Common/InfoTooltip';
import EllipsisMenu from '@/components/EllipsisMenu';
import PipelineStatisticsByCategory from '../../../components/PipelineStatisticsByCategory';
import { segments } from '../../../components/PipelineStatisticsByCategory/helpers/segments';
import {
  DisplayType,
  PipelineCountDataPointForCategory,
  PipelineDetailDataPointForCategory,
} from '../../../components/PipelineStatisticsByCategory/types';
import { TimeSpan } from '../../../components/RevealAnalyticsHeader/RevealAnalyticsHeader';

import styles from './PipelineStatisticsByGender.module.less';
import { defaultPipelineDescriptor } from '../../../components/PipelineStatisticsByCategory/defaultPipelineDescriptor';

interface PipelineStatisticsByGenderProps {
  timeSpan: TimeSpan;
  clientId: string;
  missionIds: string[];
}

const PipelineStatisticsByGender: FC<PipelineStatisticsByGenderProps> = ({
  clientId,
  missionIds,
  timeSpan,
}) => {
  const { t } = useTranslation();
  const { loading, volumeByPipelineSplitByCategory } =
    usePipelineStatisticsByCategory({
      clientId,
      categorization: { type: 'gender' },
      startDate: timeSpan.startDate.format('YYYY-MM-DD'),
      endDate: timeSpan.endDate.format('YYYY-MM-DD'),
    });
  const [displayType, setDisplayType] = useState<DisplayType>('ever');

  const [
    fetchDetails,
    {
      volumeByPipelineSplitByCategory: detailsVolumeByPipelineSplitByCategory,
      loading: detailsLoading,
    },
  ] = usePipelineStatisticsByCategoryDetails();

  const formattedData: PipelineCountDataPointForCategory[] = useMemo(
    () =>
      _.chain(volumeByPipelineSplitByCategory || [])
        .filter(
          ({ missionId }) =>
            _.isEmpty(missionIds) || _.contains(missionIds, missionId),
        )
        .reduce(
          (accumulator, { categoryId, data }) => ({
            ...accumulator,
            [categoryId]: [...(accumulator[categoryId] || []), ...data],
          }),
          {} as Record<string, PipelineCountDataPoint[]>,
        )
        .map((data, categoryId) => ({ categoryId, data }))
        .value(),
    [volumeByPipelineSplitByCategory, missionIds],
  );

  const formattedDetailsData: PipelineDetailDataPointForCategory[] = useMemo(
    () =>
      _.chain(detailsVolumeByPipelineSplitByCategory || [])
        .filter(
          ({ missionId }) =>
            _.isEmpty(missionIds) || _.contains(missionIds, missionId),
        )
        .reduce(
          (accumulator, { categoryId, missionId, data }) => ({
            ...accumulator,
            [`${missionId}__${categoryId}`]: [
              ...(accumulator[`${missionId}__${categoryId}`] || []),
              ...data,
            ],
          }),
          {} as Record<string, PipelineDetailDataPoint[]>,
        )
        .map((data, key) => {
          const [missionId, categoryId] = key.split('__');
          return {
            missionId,
            categoryId,
            data,
          };
        })
        .value(),
    [detailsVolumeByPipelineSplitByCategory, missionIds],
  );

  const categories: Category[] = useMemo(
    () =>
      _.map(genderArray, (genderKey) => {
        return {
          id: genderKey,
          title: t(`reveal.reports.diversity.genders.${genderKey}`),
        };
      }),
    [t],
  );

  const displayTypeSwitchOptions = [
    {
      key: 'ever',
      text: t('reveal.reports.missions.pipelineDisplayType.ever'),
      value: 'ever',
    },
    {
      key: 'current',
      text: t('reveal.reports.missions.pipelineDisplayType.current'),
      value: 'current',
    },
  ];

  const onChangeDisplayType = (value: DisplayType) => {
    setDisplayType(value);
  };

  const handleExportData = useCallback(() => {
    const data = formattedData.map(({ categoryId, data: d }) => ({
      projectId: categoryId,
      data: d,
    }));
    const csvData = [
      [
        t('reveal.reports.diversity.gender'),
        ...segments.map((stage) => t(`reveal.pipelineSegmentations.${stage}`)),
      ],
      [
        'Total',
        ...segments.map((stage) => {
          return _.reduce(
            data,
            (sum, { data: item }) => {
              if (stage === 'archived') {
                return sum + item.filter(({ archived }) => archived)?.length;
              }
              const stageData = item.find(({ state: s }) => s === stage);
              return sum + (stageData?.count || 0);
            },
            0,
          );
        }),
      ],
      [],
      ...data.map(({ projectId, data: item }) => [
        `"${categories.find(({ id }) => id === projectId)?.title || projectId}"`,
        ...segments.map((stage) => {
          if (stage === 'archived') {
            return item.filter(({ archived }) => archived)?.length || 0;
          }
          const stageData = item.find(({ state: s }) => s === stage);
          return stageData?.count || 0;
        }),
      ]),
    ];
    const csvContent = `${encodeURI('data:text/csv;charset=utf-8,')}${encodeURIComponent(
      csvData.map((row) => row.join(',')).join('\r\n'),
    )}`;
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute(
      'download',
      `pipeline_statistics_by_gender_${timeSpan.startDate.format(
        'YYYY-MM-DD',
      )}_${timeSpan.endDate.format('YYYY-MM-DD')}.csv`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [categories, formattedData, t, timeSpan]);

  return (
    <div className={styles.volumeByStage}>
      <div className={styles.top}>
        <h3>
          {t('reveal.reports.diversity.pipelineByGender')}
          <InfoTooltip rich hoverable position='right center'>
            <h1>{t('reveal.reports.tooltips.diversity.pipeline.title')}</h1>
            <h2>
              {t('reveal.reports.tooltips.common.pipeline.passthroughRates')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.common.pipeline.passthroughRatesParagraph',
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.explanations')}</h2>
            <p>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.paragraph`,
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
            <p>
              {t('reveal.reports.tooltips.common.pipeline.manualMoveParagraph')}
            </p>
            <p>
              {t('reveal.reports.tooltips.common.pipeline.deletedParagraph')}
            </p>
            <h2>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.alternativeTitle`,
              )}
            </h2>
            <p>
              {t(
                `reveal.reports.tooltips.common.pipeline.${displayType}.alternativeParagraph`,
              )}
            </p>
            <h2>{t('reveal.reports.tooltips.common.details')}</h2>
            <p>{t('reveal.reports.tooltips.common.numberDetails')}</p>
            <h2>
              {t('reveal.reports.tooltips.diversity.common.genderLabeling')}
            </h2>
            <p>
              {t(
                'reveal.reports.tooltips.diversity.common.genderLabelingParagraph',
              )}
            </p>
          </InfoTooltip>
        </h3>
        <div>
          <Dropdown
            selection
            selectOnBlur={false}
            options={displayTypeSwitchOptions}
            defaultValue='ever'
            onChange={(_e, { value }) =>
              onChangeDisplayType(value as DisplayType)
            }
            className='hiresweet-rounded'
          />
        </div>
        <EllipsisMenu
          direction='left'
          items={[
            {
              key: 'export',
              title: t('reveal.missions.pipeline.exportAsCSV'),
              onClick: handleExportData,
            },
          ]}
        />
      </div>
      {loading ? (
        <div className={styles.loader}>
          <Loader active inline='centered' size='large' />
        </div>
      ) : (
        <PipelineStatisticsByCategory
          categorization={t('reveal.reports.diversity.gender')}
          categories={categories}
          data={formattedData}
          pipelineDescriptor={defaultPipelineDescriptor}
          displayType={displayType}
          detailsLoading={detailsLoading}
          detailsData={formattedDetailsData}
          fetchDetails={({ categoryIds, stages, archivedStates }) =>
            fetchDetails({
              clientId,
              categorization: { type: 'gender' },
              categoriesFilter: !_.isEmpty(categoryIds)
                ? { in: categoryIds }
                : undefined,
              stagesFilter: !_.isEmpty(stages) ? { in: stages } : undefined,
              archivedStatusesFilter: !_.isEmpty(archivedStates)
                ? { in: archivedStates }
                : undefined,
              missionsFilter: !_.isEmpty(missionIds)
                ? { in: missionIds }
                : undefined,
              startDate: timeSpan.startDate.format('YYYY-MM-DD'),
              endDate: timeSpan.endDate.format('YYYY-MM-DD'),
            })
          }
        />
      )}
    </div>
  );
};

export default PipelineStatisticsByGender;
