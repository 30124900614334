/* globals gapi */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-throw-literal */
/* eslint-disable react/jsx-no-bind */

import React from 'react';
import _, { compose } from 'underscore';
import { withApollo } from '@apollo/client/react/hoc';
import { SEND_SEARCH_POOL_EMAIL_ON_BEHALF_OF } from '@/graphql/searchPoolProfile';
import { ENRICHED_SEARCH_POOL_PROFILE_MISSIONS } from '@/graphql/enrichedSearchPoolProfile';
import getIsPlugin from '@/hooks/common/useIsPlugin';
import logAction from '../../common/logAction';
import detectBrowserAndOS from '../../common/detectBrowserAndOS';

import { query as ProfileTimelineQuery } from '../profiles/withProfileTimeLine';
import withEmailContextConsumer from './withEmailContextConsumer';
import {
  sentryCaptureException,
  sentryCaptureMessage,
  textToId,
  getRandomString,
} from '../../common';
import getTrackingImage from '../../common/getTrackingImage';
import replaceLinksForTracking, {
  replaceCalendlyLinksForTracking,
} from '../../common/replaceLinksForTracking';
import {
  lockSearchPoolProfileMutation,
  searchPoolProfileEmailSentMutation,
  unlockSearchPoolProfileMutation,
} from './sendRevealEmailMutations';
import { sendSearchPoolEmail } from './withSendEmailMutations';
import { getUserClientMailAccount } from './sendEmailQuery';

const TRACKING_DOMAIN = 'https://skj.shortylink.site';
const REVEAL_SEARCH_POOL_ID = 'reveal';

const getFullBodyAndTrackingData = ({
  body,
  signature,
  footer,
  hiresweetClient,
  profileId,
}) => {

  const getTrackingMessageId = () => {
    if (hiresweetClient?.id) {
      return btoa(
        `${hiresweetClient?.id}:::${getRandomString(12)}`
      );
    }
    return getRandomString(18);
  }

  const trackingMessageId = getTrackingMessageId();
  const trackOnEmailOpen =
    hiresweetClient?.settings?.clickAndOpenTracking?.trackOnEmailOpen;
  const trackOnLinkClick =
    hiresweetClient?.settings?.clickAndOpenTracking?.trackOnLinkClick;
  const trackingDomain = TRACKING_DOMAIN; // todo : get from graphql
  const emailContainsTrackedCalendlyLinks = doesEmailContainTrackedCalendlyLinks(
    { hiresweetClient, body },
  );
  let fullBody = body;

  if (emailContainsTrackedCalendlyLinks) {
    fullBody = replaceCalendlyLinksForTracking(fullBody, { profileId }).body;
  }

  if (trackOnLinkClick) {
    // replace all the links
    fullBody = replaceLinksForTracking(fullBody, {
      profileId,
      trackingDomain,
      messageId: trackingMessageId,
    }).body;
  }

  let targetFooterText = footer;
  if (targetFooterText) {
    targetFooterText = replaceLinksForTracking(footer, {
      profileId,
      trackingDomain,
      messageId: trackingMessageId,
    }).body;
  }

  // add signature and footer
  fullBody = `<div>${fullBody}</div><div>${signature}</div>${
    targetFooterText ? `<div>${targetFooterText}</div>` : ''
  }`;

  if (trackOnEmailOpen) {
    // add pixel
    fullBody += getTrackingImage(profileId, trackingMessageId, trackingDomain);
  }

  // wrap it up
  fullBody = `<div>${fullBody}</div>`;
  return { fullBody, trackingMessageId, trackOnEmailOpen, trackOnLinkClick };
};

const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

const delayRefetchedQuery = async (observableQuery) => {
  await sleep(500);
  observableQuery.refetch();
};

export function withSendEmail(WrappedComponent) {
  return class withSendEmailWrapper extends React.Component {
    async getEmailSenderAndTracking({
      profileId,
      hiresweetClient,
      originalBody,
      assignedSender,
      footer,
      signature,
    }) {
      const { user, client, emailApi } = this.props;
      const isPlugin = getIsPlugin();

      const getPluginUser = async () => {
        if (isPlugin && !assignedSender?.id) {
          return client.query({
            query: getUserClientMailAccount,
          });
        }
        return null;
      };

      const pluginUser = await getPluginUser();

      let senderEmailAddress = null;
      let alias = null;
      let targetSignature = null;
      if (assignedSender) {
        senderEmailAddress = assignedSender.senderAddress;
        // eslint-disable-next-line
        alias = assignedSender.alias;
        targetSignature = assignedSender.signature || '';
      } else if (pluginUser) {
        senderEmailAddress = pluginUser?.data?.user?.mailAccount?.address;
        alias = pluginUser?.data?.user?.alias || '';
        targetSignature = pluginUser?.data?.user?.signature || '';
      } else {
        senderEmailAddress = emailApi.currentAddress;
        alias =
          user?.mailAccount?.address === senderEmailAddress ? user.alias : '';
        targetSignature = signature;
      }

      const {
        fullBody,
        trackingMessageId,
        trackOnEmailOpen,
        trackOnLinkClick,
      } = getFullBodyAndTrackingData({
        body: originalBody,
        footer,
        signature: targetSignature,
        hiresweetClient,
        profileId,
      });

      return {
        senderInfo: {
          senderEmailAddress,
          alias,
          targetSignature,
        },
        trackingInfo: {
          fullBody,
          trackingMessageId,
          trackOnEmailOpen,
          trackOnLinkClick,
        },
      };
    }

    async handleSendEmail({
      subject,
      body: originalBody,
      footer,
      signature,
      dest,
      bccAddresses = [],
      ccAddresses = [],
      defaultThreadId,
      profileId,
      jobOfferId,
      sequenceId,
      actionId,
      client: hiresweetClient,
      task,
      assignedSender,
      searchPoolId,
    }) {
      const { user, client, emailApi } = this.props;
      const isPlugin = getIsPlugin();

      const {
        senderInfo: { senderEmailAddress, alias, targetSignature },
        trackingInfo: {
          fullBody,
          trackingMessageId,
          trackOnEmailOpen,
          trackOnLinkClick,
        },
      } = await this.getEmailSenderAndTracking({
        profileId,
        hiresweetClient,
        originalBody,
        footer,
        assignedSender,
        signature,
      });

      const emailContainsTrackedCalendlyLinks = doesEmailContainTrackedCalendlyLinks(
        {
          hiresweetClient,
          body: originalBody,
        },
      );

      try {
        // Plugin
        if (isPlugin && !assignedSender?.id) {
          const variables = {
            searchPoolId: 'reveal',
            input: {
              profileId,
              body: fullBody,
              subject,
              senderAddress: senderEmailAddress ?? '',
              bcc: bccAddresses,
              cc: ccAddresses,
            },
          };
          const rawResult = await client.mutate({
            mutation: sendSearchPoolEmail,
            variables,
          });

          if (!rawResult?.data?.searchPoolProfile?.backendSendSearchPoolEmail) {
            console.error('error in backend send');
            throw Error('invalid gmailResult');
          }
          const { type, threadId } =
            rawResult?.data?.searchPoolProfile?.backendSendSearchPoolEmail ||
            {};

          const sentResult = {
            senderAddress: senderEmailAddress,
            threadData: {
              body: fullBody,
              subject,
              from: senderEmailAddress,
              to: dest,
              type,
              threadId,
            },
          };
          const emailSentVariables = {
            searchPoolId: 'reveal',
            input: {
              id: profileId,
              signature: targetSignature,
              alias,
              sequenceId,
              actionId,
              ...sentResult.threadData,
              task: task && _.pick(task, 'id', 'queueId', 'projectId'),
              trackingMessageId,
              trackOnEmailOpen,
              trackOnLinkClick,
            },
          };
          await client.mutate({
            mutation: searchPoolProfileEmailSentMutation,
            variables: emailSentVariables,
            refetchQueries: [
              {
                query: ENRICHED_SEARCH_POOL_PROFILE_MISSIONS,
                variables: {
                  searchPoolId: 'reveal',
                  id: profileId,
                },
              },
            ],
            onQueryUpdated: delayRefetchedQuery,
          });
          return undefined;
        }

        await client.mutate({
          mutation: lockSearchPoolProfileMutation,
          variables: {
            searchPoolId: REVEAL_SEARCH_POOL_ID,
            input: {
              id: profileId,
              email: senderEmailAddress,
            },
          },
        });
        let sentResult;

        // SOBO
        if (assignedSender?.id) {
          const { id: senderId, senderAddress } = assignedSender;
          if (!senderAddress || !senderId) {
            throw new Error('Invalid offerAssignedSender');
          }

          const rawResult = await client.mutate({
            mutation: SEND_SEARCH_POOL_EMAIL_ON_BEHALF_OF,
            variables: {
              searchPoolId,
              input: {
                profileId,
                subject,
                body: fullBody,
                senderId,
                bcc: bccAddresses,
                cc: ccAddresses,
              },
            },
          });

          const emailResult =
            rawResult?.data?.searchPoolProfile?.sendSearchPoolEmailOnBehalfOf;
          if (!emailResult) {
            console.error('Error in SOBO send');
            throw new Error('Error in SOBO send');
          }

          const type = emailResult.type || 'gmail';
          sentResult = {
            senderAddress,
            threadData: {
              body: fullBody,
              from: senderAddress,
              to: dest,
              bcc: bccAddresses,
              cc: ccAddresses,
              subject,
              ...((emailResult || {}).threadId && {
                threadId: emailResult.threadId,
              }),
              alias, // sender alias
              type,
            },
            sender: assignedSender,
          };

          if (type === 'gmail') {
            const gmailResult = { ...emailResult, result: emailResult };
            sentResult = { ...sentResult, gmailResult };
          }
        } else {
          // regular send
          sentResult = await emailApi.onTriggerSendMail({
            subject,
            body: fullBody,
            dest,
            bccAddresses,
            ccAddresses,
            alias,
            defaultThreadId,
          });
        }
        const { error } = sentResult?.gmailResult || {};
        if (error || sentResult?.success === false) {
          await logErrorAndUnlockProfile({
            client,
            sentResult,
            profileId,
            dest,
            user,
            defaultThreadId,
            emailApi,
            senderEmailAddress,
          });
        } else if (sentResult.threadData) {
          if (emailContainsTrackedCalendlyLinks) {
            logAction({
              type: 'calendly-send-link',
              profileId,
            });
          }
          const sender = formatSenderInput({ sender: sentResult?.sender });
          await client.mutate({
            mutation: searchPoolProfileEmailSentMutation,
            variables: {
              searchPoolId: REVEAL_SEARCH_POOL_ID,
              input: {
                id: profileId,
                signature: targetSignature,
                alias,
                trackingMessageId,
                trackOnEmailOpen,
                trackOnLinkClick,
                sequenceId,
                actionId,
                ...sentResult.threadData,
                ...(sender && { sender }),
                task: task && _.pick(task, 'id', 'queueId', 'projectId'),
              },
            },
            refetchQueries: [
              {
                query: ENRICHED_SEARCH_POOL_PROFILE_MISSIONS,
                variables: {
                  searchPoolId: 'reveal',
                  id: profileId,
                },
              },
            ],
            onQueryUpdated: delayRefetchedQuery,
          });
        } else {
          console.error('ERROR (unhandled)');
          console.error('RES: ', sentResult);
          sentryCaptureMessage({ message: 'Unhandled sentResult' });
        }
        if (!_.isEmpty(error) || sentResult?.success === false) {
          handleAndThrowErrorMessage({ sentResult, profileId });
        }
        return sentResult;
      } catch (e) {
        sentryCaptureException({ error: e, tags: { feature: 'mail' } });
        await client.mutate({
          mutation: unlockSearchPoolProfileMutation,
          variables: {
            searchPoolId: REVEAL_SEARCH_POOL_ID,
            input: {
              id: profileId,
              error: e.message,
            },
          },
        });
        emailApi.onSendError({ profileId, offerId: jobOfferId });
        throw e;
      }
    }

    // Seems useless => in case of error maybe
    handleRefreshProfile = async ({ profileId }) => {
      const { client } = this.props;
      try {
        return await client.query({
          query: ProfileTimelineQuery, // TODO Change this query
          variables: { profileId },
        });
      } catch (e) {
        console.error(e);
        return null;
      }
    };

    render() {
      return (
        <WrappedComponent
          sendEmail={this.handleSendEmail.bind(this)}
          getEmailSenderAndTracking={this.getEmailSenderAndTracking.bind(this)}
          refreshProfile={this.handleRefreshProfile}
          {...this.props}
        />
      );
    }
  };
}

const formatSenderInput = ({ sender }) => {
  if (_.isEmpty(sender)) {
    return null;
  }
  const senderKeys = [
    'id',
    'clientId',
    'alias',
    'firstname',
    'lastname',
    'signature',
    'senderAddress',
  ];
  const senderInput = _.pick(sender, ...senderKeys);

  // convert resolved mailAccount back to DB format
  const { address, type } = sender?.mailAccount || {};
  if (address && type) {
    senderInput.mailAccount = { idToken: textToId(address), type };
  }
  return senderInput;
};

const doesEmailContainTrackedCalendlyLinks = ({ hiresweetClient, body }) => {
  const trackCalendly = !!hiresweetClient?.calendlyIntegration?.active;
  if (!trackCalendly) {
    return false;
  }
  return (body || '').indexOf('https://calendly.com/') !== -1;
};

const logErrorAndUnlockProfile = async ({
  client,
  sentResult,
  profileId,
  dest,
  user,
  defaultThreadId,
  emailApi,
  senderEmailAddress,
}) => {
  // log the send error with all the data available
  let errorMessage;
  if (sentResult?.gmailResult?.error) {
    errorMessage = `${sentResult.gmailResult.error.code} ${sentResult.gmailResult.error.message}`;
  } else {
    errorMessage = sentResult.error?.message;
  }

  logAction({
    type: 'sendError-client',
    searchPoolProfileId: profileId,
    info: {
      userSetup: detectBrowserAndOS(),
      dest,
      defaultThreadId,
      error: errorMessage,
      sentResult,
      recruiterEmail: user?.email,
      currentAddress: emailApi.currentAddress,
      senderAddress: senderEmailAddress,
      googleToken: gapi
        ? _.omit(
            gapi.auth2
              .getAuthInstance()
              .currentUser.get()
              .getAuthResponse(),
            'access_token',
            'id_token',
          )
        : null, //eslint-disable-line
    },
  });

  await client.mutate({
    mutation: unlockSearchPoolProfileMutation,
    variables: {
      searchPoolId: REVEAL_SEARCH_POOL_ID,
      input: {
        id: profileId,
        error: errorMessage,
      },
    },
  });
};

const handleAndThrowErrorMessage = ({ sentResult, profileId }) => {
  if (
    sentResult?.gmailResult?.error?.code === 404 ||
    sentResult?.gmailResult?.error?.code === 400
  ) {
    logAction({
      type: 'remove-sendError',
      searchPoolProfileId: profileId,
    });
    throw Error('400_404_gmail_error');
  }
  const errorMessage =
    sentResult?.error?.message ||
    sentResult?.gmailResult?.error?.message ||
    'send_error';
  throw Error(errorMessage);
};

export default compose(withApollo, withEmailContextConsumer, withSendEmail);
