import ClientPermissions, {
  IClientPermissions,
} from '@/graphql/fragments/ClientPermissions';
import { Department } from '@/graphql/fragments/Department';
import TranslatableText from '@/graphql/fragments/TranslatableText';
import { MutationHookOptions, useMutation } from '@apollo/client';
import gql from 'graphql-tag';

const mutation = gql`
  mutation applyClientTemplate($mode: String!) {
    enableClientMode(mode: $mode) {
      id
      permissions {
        ...ClientPermissions
      }
      departments {
        ...Department
      }
      pipelineSubSteps {
        missionCategory {
          type
        }
        stepId
        subSteps {
          id
          title {
            ...TranslatableText
          }
        }
      }
      profileCustomFields {
        type
        id
        display
        title {
          ...TranslatableText
        }
        contactCategory {
          type
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
          isMultiselect
        }
        shouldDisplayInCard
        shouldBeHidden
        shouldDisplayBelowFullname
        isDisplayedAtCreation
        isMandatoryAtCreation
      }
      missionCustomFields {
        type
        id
        isMandatoryAtCreation
        isDisplayedAtCreation
        title {
          ...TranslatableText
        }
        ... on CustomFieldDefinitionEnum {
          options {
            id
            title {
              ...TranslatableText
            }
          }
          isMultiselect
          isFilteringOption
        }
        shouldDisplayInCard
        shouldBeHidden
      }
      configurationParams {
        attributes {
          key
          value
        }
      }
      humanContactCategorySubtypes {
        id
        title {
          ...TranslatableText
        }
        isDefault
      }
    }
  }
  ${ClientPermissions}
  ${Department}
  ${TranslatableText}
`;

type Data = {
  enableClientMode: {
    id: string;
    permissions: IClientPermissions;
  };
};

type Variables = {
  mode: string;
};

type Input = {
  mutationOptions?: MutationHookOptions<Data, Variables>;
};

const useApplyClientTemplate = ({ mutationOptions = {} }: Input = {}) =>
  useMutation<Data, Variables>(mutation, mutationOptions);

export default useApplyClientTemplate;
