import React, {
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  Fragment,
} from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';

import SegmentationDropdown from '@/components/PipelineSegmentation/SegmentationDropdown';
import BoardIcon from '@/components/Reveal/Icons/BoardIcon';
import TableIcon from '@/components/Reveal/Icons/TableIcon';
import GenericDropdown from '@/components/Common/GenericDropdown';
import GenericButton from '@/components/Common/GenericButton';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';
import Dots from '@/components/Common/Icons/Dots';
import { DropdownMenuOption } from '@/components/Common/DropdownMenuPanel/DropdownMenuPanel';
import useClientId from '@/hooks/router/useClientId';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { Segmentation } from '@/components/PipelineSegmentation/pipelineSegmentation';

import ArrowRight from '@/components/Reveal/Icons/ArrowRight';
import ArrowLeft from '@/components/Reveal/Icons/ArrowLeft';
import { WEEK_IN_MS, getWeekNumber } from '@/common/dates';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import useGenerateWordDocumentFromProfiles from '@/graphql/hooks/searchPool/useGenerateWordDocumentFromProfiles';
import {
  Button,
  ProjectSharedViewManagementButton,
  ProjectContainer,
} from '@hiresweet/hiresweet-lib';
import downloadPDF from '@/common/downloadPDF';
import DropdownControlsContext from '@/context/DropdownControlsContext';

import ProfilesViewFilterSettings from './ProfilesViewFilterSettings';
import DynamicImportButton from './DynamicImportButton';

import styles from './ProjectProfilesViewSettingsHeader.module.less';

interface ProjectProfilesViewSettingsHeaderProps {
  segmentations: Segmentation[];
  segmentation: Segmentation;
  onSelectSegmentation: (segmentationId: string) => void;
  displayType: string;
  onSelectDisplayType: (displayType: string) => void;
  condensedInterested: boolean;
  setCondensedInterested: Dispatch<SetStateAction<boolean>>;
  weekDiffInMS?: number;
  onWeekDiffChange?: (diff: number) => void;
  missionId: string;
  dynamicImportPolling: number;
  onDynamicImportPollingChange: (value: number) => void;
}

const ProjectProfilesViewSettingsHeader: React.FC<
  ProjectProfilesViewSettingsHeaderProps
> = ({
  segmentations,
  segmentation,
  onSelectSegmentation,
  displayType,
  onSelectDisplayType,
  condensedInterested,
  setCondensedInterested,
  weekDiffInMS = 0,
  onWeekDiffChange,
  missionId,
  dynamicImportPolling,
  onDynamicImportPollingChange,
}) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const permissions = useClientPermissions(clientId).data?.client?.permissions;
  const showKanbanAvailability = permissions?.nextAvailability;
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();
  const [generateDocument, { loading: documentGenerationLoading }] =
    useGenerateWordDocumentFromProfiles();

  const { data: jobData } = useSearchPoolJob(
    'reveal',
    missionId,
    dynamicImportPolling,
  );

  const { job } = jobData?.searchPool || {};

  const jobDynamicImport = useMemo(() => {
    return job?.dynamicImports?.[0];
  }, [job]);

  const dynamicImportAccount = useMemo(() => {
    return sharedMessagingAccounts?.find(
      (account) =>
        account.id === jobDynamicImport?.descriptor?.unipileSharedAccountId,
    );
  }, [jobDynamicImport, sharedMessagingAccounts]);

  const hasDynamicImportSynchronizing = useMemo(
    () =>
      _.some(job?.dynamicImports, (dynamicImport) => {
        return dynamicImport.synchronizationStatus?.isSynchronizing;
      }),
    [job],
  );

  useEffect(() => {
    if (hasDynamicImportSynchronizing && dynamicImportPolling === 0) {
      onDynamicImportPollingChange(4000);
    }
    if (!hasDynamicImportSynchronizing) {
      onDynamicImportPollingChange(0);
    }
  }, [
    hasDynamicImportSynchronizing,
    dynamicImportPolling,
    onDynamicImportPollingChange,
  ]);

  const filteredSegmentations = useMemo(() => {
    if (showKanbanAvailability) {
      return segmentations;
    }
    return _.filter(
      segmentations,
      (segmentationItem) => !segmentationItem.featureFlag,
    );
  }, [showKanbanAvailability, segmentations]);

  const showCondensedInterested =
    segmentation.showExtraOptions?.condensedInterested || false;

  const missionSegmentationDropdownOptions = useMemo(() => {
    const result = [] as DropdownMenuOption[];
    if (showCondensedInterested) {
      result.push({
        id: 'condensedInterested',
        label: t(
          `reveal.toggles.${
            condensedInterested ? 'displaySubSteps' : 'hideSubSteps'
          }`,
        ),
      });
    }
    return result;
  }, [showCondensedInterested, condensedInterested, t]);
  const date = new Date(Date.now() + weekDiffInMS);
  const weekNumber = getWeekNumber(date);

  return (
    <div className={styles.projectProfileViewSettingsHeader}>
      <SegmentationDropdown
        segmentations={filteredSegmentations}
        segmentation={segmentation}
        onSelectSegmentation={(segmentationId: string) =>
          onSelectSegmentation(segmentationId)
        }
        showKanban
      />
      <div className={styles.right}>
        {permissions?.canUseSharedViews && (
          <ProjectContainer projectId={missionId}>
            <ProjectSharedViewManagementButton
              getUrlOrigin={({ standaloneReferrer }) =>
                `${window.location.origin}/sharing/${standaloneReferrer}/sspc`
              }
            />
          </ProjectContainer>
        )}
        {clientId === 'humanskills' && (
          <Button
            primacy='secondary'
            color='grey'
            disabled={documentGenerationLoading}
            onClick={() => {
              generateDocument({
                variables: {
                  profileIds: [],
                  searchPoolId: 'reveal',
                  jobId: missionId,
                  templateId: 'aravati-progress-report',
                },
              }).then(({ data }) => {
                const content =
                  data?.searchPool?.generateProfileListDocument?.file?.content;
                if (content) {
                  downloadPDF({
                    filename: 'progress-report.docx',
                    objectURL: content,
                  });
                }
              });
            }}
          >
            Progress report
          </Button>
        )}
        {_.map(job?.dynamicImports, (dynamicImport) => (
          <Fragment key={dynamicImport.id}>
            <DynamicImportButton
              dynamicImport={dynamicImport}
              dynamicImportAccount={dynamicImportAccount}
              missionId={missionId}
            />
          </Fragment>
        ))}
        <ProfilesViewFilterSettings />
        {segmentation.subtype === 'date' && onWeekDiffChange && (
          <div className={styles.weekSelectorContainer}>
            <div
              className={styles.prevSelector}
              onClick={() => onWeekDiffChange(weekDiffInMS - WEEK_IN_MS)}
            >
              <ArrowLeft />
            </div>
            <div
              className={styles.nextSelector}
              onClick={() => onWeekDiffChange(weekDiffInMS + WEEK_IN_MS)}
            >
              <ArrowRight />
            </div>
            <div className={styles.currentWeek}>
              {t('common.week')}&nbsp;
              {weekNumber}
            </div>
          </div>
        )}
        {segmentation.showDisplayTypeSelector && (
          <Dropdown
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            direction='left'
            trigger={
              displayType === 'kanban' ? (
                <div className={styles.displayTypeDropdownTrigger}>
                  <BoardIcon />
                  {t('reveal.toggles.viewAsKanban')}
                </div>
              ) : (
                <div className={styles.displayTypeDropdownTrigger}>
                  <TableIcon />
                  {t('reveal.toggles.viewAsTable')}
                </div>
              )
            }
            className={styles.displayTypeDropdown}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text={t('reveal.toggles.viewAsTable')}
                onClick={() => onSelectDisplayType('table')}
              />
              <Dropdown.Item
                text={t('reveal.toggles.viewAsKanban')}
                onClick={() => onSelectDisplayType('kanban')}
              />
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!_.isEmpty(missionSegmentationDropdownOptions) && (
          <GenericDropdown
            position='right'
            trigger={
              <DropdownControlsContext.Consumer>
                {({ toggleDropdown }) => (
                  <GenericButton
                    primacy='tertiary'
                    onClick={toggleDropdown}
                    isIcon
                  >
                    <Dots />
                  </GenericButton>
                )}
              </DropdownControlsContext.Consumer>
            }
          >
            <DropdownMenuPanel
              options={missionSegmentationDropdownOptions}
              onSelect={(id) => {
                if (id === 'condensedInterested') {
                  setCondensedInterested(!condensedInterested);
                }
              }}
            />
          </GenericDropdown>
        )}
      </div>
    </div>
  );
};

export default ProjectProfilesViewSettingsHeader;
