import _ from 'underscore';
import { ApolloClient, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createHttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';

import possibleTypes from './possibleTypes.json';

const fetchWithOperationName = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  return fetch(`${uri}?${operationName}`, options);
};

const createLink = () => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    ...(process.env.NODE_ENV !== 'production' && {
      fetch: fetchWithOperationName,
    }),
  });
  const middlewareLink = setContext(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      return {};
    }

    return {
      headers: {
        authorization: token || undefined,
      },
    };
  });

  const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((res) => {
      const context = operation.getContext();
      const { response } = context;
      const token = response?.headers?.get('authorization');
      // this is only useful for free-trial signup
      if (token) {
        localStorage.setItem('token', token);
      }
      return res;
    });
  });

  const errorLink = onError(({ networkError = {}, graphQLErrors }) => {
    const networkErrorIs401 = networkError.statusCode === 401;
    const graphQLErrorsHas401 = _.some(
      graphQLErrors,
      (error) => error?.data?.statusCode === 401,
    );
    if (networkErrorIs401 || graphQLErrorsHas401) {
      localStorage.removeItem('token');
    }
  });

  return middlewareLink
    .concat(errorLink)
    .concat(afterwareLink)
    .concat(httpLink);
};

const dataIdFromObject = (result) => {
  if (result.id && result.__typename) {
    // eslint-disable-line no-underscore-dangle
    return `${result.__typename}:${result.id}`; // eslint-disable-line no-underscore-dangle
  }
  // Make sure to return null if this object doesn't have an ID
  return null;
};

// Merge apollo cache array objects depending on internal id (apollo only recognizes id, _id, or if keyArgs was passed)
const mergeArraysByObjectIdForIncoming = (
  idArg,
  existing,
  incoming,
  { mergeObjects },
) => {
  const existingById = _.indexBy(existing, idArg);
  const incomingById = _.indexBy(incoming, idArg);
  const incomingIds = Object.keys(incomingById);

  // Only use incoming ids, usually this is done because we deleted/added some objects and local data is stale
  return _.map(incomingIds, (actionId) => {
    return mergeObjects(existingById[actionId], incomingById[actionId] || {});
  });
};

export const createCache = () => {
  const options = {
    dataIdFromObject,
    possibleTypes,
    typePolicies: {
      // This corresponds to what used to be `cacheRedirects`
      Query: {
        fields: {
          offer: {
            read: (_arg, { args, toReference }) => {
              return toReference({
                __typename: 'Offer',
                id: args.id,
              });
            },
          },
          enrichedProfile: {
            read: (_arg, { args, toReference }) => {
              return toReference({
                __typename: 'Profile',
                id: args.id,
              });
            },
          },
          profile: {
            read: (_arg, { args, toReference }) => {
              return toReference({
                __typename: 'Profile',
                id: args.id,
              });
            },
          },
        },
      },
      Client: {
        fields: {
          statistics: {
            merge: true,
          },
          permissions: {
            merge: true,
          },
          customActions: {
            merge: (_existing, incoming) => incoming,
          },
          contactFlowTasks: {
            keyArgs: [],
            merge: (_existing, incoming) => incoming,
          },
          explicitTasks: {
            keyArgs: [],
            merge: (_existing, incoming) => incoming,
          },
        },
      },
      ContactFlowActionFormatFormField: {
        // needed to prevent old form field from being applyed to the reapplied same sequence action
        keyFields: false,
      },
      ContactFlowActionFormatFormFieldOption: {
        // needed to prevent old form field option from being applyed to the reapplied same sequence action
        keyFields: false,
      },
      DisplayColumn: {
        // needed because we have multiple tables with the same column names
        keyFields: false,
      },
      MiniProfileWithSegmentation: {
        keyFields: ['id', 'categoryId'],
      },
      SearchPool: {
        fields: {
          segmentProfileResults: {
            keyArgs: ['input', ['missionsFilter', 'extraFilters']],
            merge: (existing = [], incoming = []) => {
              const allSegmentIds = new Set([
                ..._.pluck(existing, 'segmentId'),
                ..._.pluck(incoming, 'segmentId'),
              ]);

              const res = _.map(Array.from(allSegmentIds), (segmentId) => {
                const existingMatch = _.findWhere(existing, {
                  segmentId,
                });

                const incomingMatch = _.findWhere(incoming, {
                  segmentId,
                });

                if (!existingMatch) {
                  return incomingMatch;
                }

                if (!incomingMatch) {
                  return existingMatch;
                }

                const allProfileRefs = new Set([
                  ..._.pluck(existingMatch.profiles || [], '__ref'),
                  ..._.pluck(incomingMatch.profiles || [], '__ref'),
                ]);

                const newProfiles = _.map(
                  Array.from(allProfileRefs),
                  (profileRef) =>
                    _.findWhere(incomingMatch.profiles || [], {
                      __ref: profileRef,
                    }) ||
                    _.findWhere(existingMatch.profiles || [], {
                      __ref: profileRef,
                    }),
                );

                return {
                  ...existingMatch,
                  count: incomingMatch.count || existingMatch.count,
                  profiles: newProfiles,
                };
              });

              return res;
            },
          },
        },
      },
      RevealProfile: {
        fields: {
          currentSequenceInfo: { merge: true },
          resumeData: { merge: true },
          contactData: { merge: true },
          missionsInfo: {
            keyArgs: ['missionId'],
            merge: (existing, incoming, { mergeObjects }) => {
              const existingByMissionId = _.indexBy(existing, 'missionId');
              const incomingByMissionId = _.indexBy(incoming, 'missionId');
              const incomingIds = Object.keys(incomingByMissionId);
              return incomingIds.map((id) => {
                const incomingMission = incomingByMissionId[id] ?? {
                  missionId: id,
                };
                const existingMission = existingByMissionId[id];
                if (!existingMission) {
                  return incomingMission;
                }
                const incomingData = incomingMission.data;
                const existingData = existingMission.data;
                return {
                  ...mergeObjects(existingMission, incomingMission),
                  data:
                    existingData && incomingData
                      ? mergeObjects(existingData, incomingData)
                      : incomingData ?? existingData,
                };
              });
            },
          },
          applicationMissions: {
            keyArgs: ['missionId'],
            merge: (existing, incoming, { mergeObjects }) => {
              return mergeArraysByObjectIdForIncoming(
                'missionId',
                existing,
                incoming,
                {
                  mergeObjects,
                },
              );
            },
          },
        },
      },
      Attachment: {
        fields: {
          file: { merge: true },
        },
      },
      User: {
        fields: {
          displayPreferences: { merge: true },
          permissions: { merge: true },
        },
      },
      Profile: {
        fields: {
          annotation: { merge: true },
          resumeData: { merge: true },
          contactData: { merge: true },
          source: { merge: true },
          talentStrategist: { merge: true },
        },
      },
      MailAccount: {
        keyFields: ['address'],
      },
      Annotation: {
        fields: {
          globalFavorite: { merge: true },
          globalComment: { merge: true },
        },
      },
      RevealJob: {
        fields: {
          data: { merge: true },
          stats: { merge: true },
        },
      },
      // I'm not sure why these are necessary since we already
      // Added them as possible types for RevealJob
      RevealGreenhouseJob: {
        fields: {
          data: { merge: true },
          stats: { merge: true },
        },
      },
      RevealLeverJob: {
        fields: {
          data: { merge: true },
          stats: { merge: true },
        },
      },
      RevealSmartrecruitersJob: {
        fields: {
          data: { merge: true },
          stats: { merge: true },
        },
      },
      RevealConnector: {
        fields: {
          filterOptions: { merge: true },
        },
      },
      ContactFlowSequence: {
        fields: {
          actions: {
            keyArgs: ['actionId'],
            merge: (existing, incoming, { mergeObjects }) => {
              // For actions we
              return mergeArraysByObjectIdForIncoming(
                'actionId',
                existing,
                incoming,
                {
                  mergeObjects,
                },
              );
            },
          },
        },
      },
      Sequence: {
        fields: {
          author: {
            merge: true,
          },
        },
      },
      CustomFieldDefinitionEnumOption: {
        keyFields: false,
      },
    },
  };

  return new InMemoryCache(options);
};

export const createClient = (name) => {
  const link = createLink();
  const cache = createCache();

  return new ApolloClient({
    link,
    cache,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    name,
  });
};
