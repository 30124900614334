import _ from 'underscore';
import React, { FC, useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { getTranslatedText } from '@/common';
import useProjectNomenclatures from '@/graphql/hooks/clients/useProjectNomenclatures';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import { PipelineDescriptor, SegmentListeners } from '../types';
import GenericFunnelSummary from '../../GenericFunnelSummary';

import styles from './PipelineFunnelSummary.module.less';


interface PipelineFunnelSummaryProps {
  data: Record<string, number>;
  pipelineDescriptor: PipelineDescriptor;
  conversionRates?: Record<string, string>;
  clickListeners?: SegmentListeners;
}

const paragraphListBySegment: Record<string, readonly string[]> = {
  pending: ['multiMission'],
  'in-progress': ['multiMove', 'revertMove', 'multiMission'],
  replied: ['multiMove', 'revertMove', 'skip', 'multiMission'],
  interested: ['multiMove', 'revertMove', 'skip', 'multiMission'],
  hired: ['multiMove', 'multiMission'],
  archived: ['definition', 'multiMove', 'multiMission'],
};

const getParagraphFetcher = (t: TFunction) => (
  segment: string,
): { key: string; paragraph: string }[] =>
  _.map(paragraphListBySegment[segment] ?? [], (paragraphKey) => ({
    key: `${segment}_${paragraphKey}`,
    paragraph: t(
      `reveal.reports.tooltips.common.pipeline.stages.${segment}.${paragraphKey}Paragraph`,
    ),
  }));

const PipelineFunnelSummary: FC<PipelineFunnelSummaryProps> = ({
  data,
  pipelineDescriptor,
  conversionRates,
  clickListeners,
}) => {
  const { t } = useTranslation();
  const getParagraphsForSegment = getParagraphFetcher(t);
  const { projectNomenclatures } = useProjectNomenclatures();
  const missionCategoryType = useContext(MissionCategoryContext);

  const projectNomenclature = useMemo(
    () =>
      _.find(
        projectNomenclatures,
        ({ missionCategory }) => missionCategory.type === missionCategoryType,
      ),
    [projectNomenclatures, missionCategoryType],
  );

  const defaultSuccessStepId = useMemo(
    () => (missionCategoryType === 'sales' ? 'signed' : 'hired'),
    [missionCategoryType],
  );

  const successStepId = useMemo(
    () => projectNomenclature?.successStep?.id || defaultSuccessStepId,
    [projectNomenclature?.successStep?.id, defaultSuccessStepId],
  );

  const segmentDescriptions = _.map(pipelineDescriptor.steps, ({ id: key, title }) => ({
    key,
    name: key === 'hired' ? (
      t(`superPipelineSettings.nomenclature.options.${successStepId}`, { count: 10 })
    ) : getTranslatedText(title),
    count: data[key] ?? 0,
    conversionRate: conversionRates?.[key],
    clickListener: clickListeners?.[key],
    classNames: { global: styles[key], count: styles.count },
    tooltip: !key.includes('interested_') ? (
      <>
        <h1>
          {t(`reveal.reports.tooltips.common.pipeline.stages.${key}.title`)}
        </h1>
        <h2>{t('reveal.reports.tooltips.common.surprised')}</h2>
        {_.map(
          getParagraphsForSegment(key),
          ({ key: paragraphKey, paragraph }) => (
            <p key={paragraphKey}>{paragraph}</p>
          ),
        )}
      </>
    ) : undefined,
  }));

  return (
    <GenericFunnelSummary segments={segmentDescriptions} />
  )
};

export default PipelineFunnelSummary;
