import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import classnames from 'classnames';

import SettingsLayout from '@/containers/SettingsLayout';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import GenericToggle from '@/components/Common/GenericToggle';
import useUpdateClientPermissions from '@/graphql/hooks/clients/useUpdatePermissions';
import useCurrentUser from '@/graphql/hooks/users/useCurrentUser';
import { sanitizeTypename } from '@/common/utils/apollo';
import { typesafeMap } from '@/common/utils/types';
import GenericButton from '@/components/Common/GenericButton';
import useEnableTripartiteMode from '@/graphql/hooks/clients/useEnableTripartiteMode';
import useEnableAutomationMode from '@/graphql/hooks/clients/useEnableAutomationMode';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import useEnableCompanyCustomDealStates from '@/graphql/hooks/clients/useEnableCompanyCustomDealStates';
import useEnableSharedSpaces from '@/graphql/hooks/clients/useEnableSharedSpaces';

import FreeTrialForm from './FreeTrialForm';
import AccountStateForm from './AccountStateForm';
import ApplyClientTemplateForm from './ApplyClientTemplateForm';

import styles from './PermissionsSettings.module.less';

interface PermissionsSettingsProps {
  clientId: string;
}

const PermissionsSettings: React.FC<PermissionsSettingsProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const [updateClientPermissions] = useUpdateClientPermissions();
  const [applyTemplateModalOpen, setApplyTemplateModalOpen] =
    React.useState(false);
  const { success } = useNotificationSystem();

  const permissions = sanitizeTypename(
    useClientPermissions(clientId).data?.client?.permissions,
  );

  const sortedPermissions = permissions
    ? _.sortBy(
        typesafeMap(permissions, (value, name) => ({ value, name })),
        'name',
      )
    : [];

  const [enableAutomationMode, { loading: automationLoading }] =
    useEnableAutomationMode({
      mutationOptions: {
        onCompleted: () => success(t('settings.clientModes.automationEnabled')),
      },
    });
  const [enableTripartiteMode, { loading: tripartiteLoading }] =
    useEnableTripartiteMode({
      mutationOptions: {
        onCompleted: () => success(t('settings.clientModes.tripartiteEnabled')),
      },
    });
  const [enableSharedSpaces, { loading: sharedSpacesLoading }] =
    useEnableSharedSpaces({
      mutationOptions: {
        onCompleted: () =>
          success(t('settings.clientModes.sharedSpacesEnabled')),
      },
    });

  const [
    enableCompanyCustomDealStates,
    { loading: companyCustomDealStatesLoading },
  ] = useEnableCompanyCustomDealStates({
    mutationOptions: {
      onCompleted: () =>
        success(t('settings.clientModes.companyCustomDealStatesEnabled')),
    },
  });

  const updatePermissions = ({
    permissionName,
  }: {
    permissionName: string;
  }) => {
    updateClientPermissions({
      variables: {
        input: {
          permission: permissionName,
          value: !((permissions || {}) as Record<string, boolean>)?.[
            permissionName
          ],
        },
      },
    });
  };

  if (!user?.isAdmin) {
    return null;
  }

  return (
    <div className='settings'>
      <SettingsLayout
        currentPage='permissions'
        clientId={clientId}
        className='settings-container'
      >
        <div className='settings settings-header'>
          <h1>{t('settings.clientModes.title')}</h1>
        </div>
        <div className={styles.clientModes}>
          <div className={styles.clientModesRow}>
            <GenericButton
              className={styles.clientModeButton}
              disabled={automationLoading}
              onClick={() => enableAutomationMode()}
            >
              {t('settings.clientModes.enableAutomation')}
            </GenericButton>
            <GenericButton
              className={styles.clientModeButton}
              disabled={tripartiteLoading}
              onClick={() => enableTripartiteMode()}
            >
              {t('settings.clientModes.enableTripartite')}
            </GenericButton>
            <GenericButton
              className={styles.clientModeButton}
              disabled={companyCustomDealStatesLoading}
              onClick={() => enableCompanyCustomDealStates()}
            >
              {t('settings.clientModes.enableCompanyCustomDealStates')}
            </GenericButton>
          </div>
          <div className={styles.clientModesRow}>
            <GenericButton
              className={styles.clientModeButton}
              onClick={() => setApplyTemplateModalOpen(true)}
            >
              {t('settings.clientModes.applyTemplate.title')}
            </GenericButton>
          </div>
          <div className={styles.clientModesRow}>
            <GenericButton
              className={styles.clientModeButton}
              disabled={sharedSpacesLoading}
              onClick={() => enableSharedSpaces({ enableFeedback: false })}
            >
              {t('settings.clientModes.enableSharedSpaces')} (
              {t('settings.clientModes.withoutFeedback')})
            </GenericButton>
            <GenericButton
              className={styles.clientModeButton}
              disabled={sharedSpacesLoading}
              onClick={() => enableSharedSpaces({ enableFeedback: true })}
            >
              {t('settings.clientModes.enableSharedSpaces')} (
              {t('settings.clientModes.withFeedback')})
            </GenericButton>
          </div>
        </div>
        <FreeTrialForm />
        <AccountStateForm />
        <ApplyClientTemplateForm
          open={applyTemplateModalOpen}
          onOpen={setApplyTemplateModalOpen}
        />
        <div className='settings settings-header'>
          <h1>{t('settings.permissions.title')}</h1>
        </div>
        <div className={styles.permissionsContainer}>
          {_.map(sortedPermissions, (permission) => (
            <div className={styles.permissionContainer}>
              <div
                className={classnames(
                  styles.permissionTitle,
                  permission.value ? styles.active : null,
                )}
              >
                {permission.name}
              </div>
              <GenericToggle
                className={styles.toggle}
                isChecked={!!permission.value}
                label=''
                name={permission.name}
                onChange={() =>
                  updatePermissions({ permissionName: permission.name })
                }
              />
            </div>
          ))}
        </div>
      </SettingsLayout>
    </div>
  );
};

export default PermissionsSettings;
