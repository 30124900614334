import gql from 'graphql-tag';
import ContactFlowMessageSender from './ContactFlowMessageSender';

export default gql`
  fragment ContactFlowMessage on ContactFlowMessage {
    templateId
    subject
    body
    footer
    cc
    bcc
    senderId
    sender {
      ...ContactFlowMessageSender
    }
  }
  ${ContactFlowMessageSender}
`;
