import classnames from 'classnames';
import _ from 'underscore';
import moment from 'moment';
import React, {
  Dispatch,
  FC,
  SyntheticEvent,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Link, useRouteMatch } from 'react-router-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';

import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import { toString } from '@/common/utils/string';
import { useClientCurrentAtsId } from '@/graphql/hooks/clients/useClientRevealProjects';
import { getShortLanguage } from '@/common/utils/i18n';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import ProjectTreeDropdownProvider from '@/routes/RevealView/Overview/ProjectTreeDropdownContext';
import ProjectTreeDropdown from '@/routes/RevealView/Overview/ProjectTreeDropdown';
import { dateRangeDates } from '../../utils';
import SequenceSelector from '../SequenceSelector';

import MissionsDropdown from './MissionsDropdown';
import UsersDropdown from './UsersDropdown';
import MissionFolderingSelector from './MissionFolderingSelector';
import StatisticsParametersContext from '../../StatisticsParametersContext';
import AggregatePeriodSelector from './AggregatePeriodSelector';

import styles from './RevealAnalyticsHeader.module.less';

const TIMES_VALUES = [
  '7days',
  '30days',
  '60days',
  '180days',
  '365days',
  'week',
  'month',
  'quarter',
  'year',
  'allTime',
  'custom',
];
type TIMES_ENUM =
  | '7days'
  | '30days'
  | '60days'
  | '180days'
  | '365days'
  | 'week'
  | 'month'
  | 'quarter'
  | 'year'
  | 'allTime'
  | 'custom';

export interface TimeSpan {
  id: TIMES_ENUM;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

interface RevealAnalyticsHeaderProps {
  clientId: string;
  selectedSequence: string[];
  setSelectedSequence: Dispatch<string[]>;
  userEmails: string[] | undefined;
  onChangeUsers: (users: string[]) => void;
}

const RevealAnalyticsHeader: FC<RevealAnalyticsHeaderProps> = ({
  clientId,
  selectedSequence,
  setSelectedSequence,
  userEmails,
  onChangeUsers,
}) => {
  const { t, i18n } = useTranslation();
  const configurationParams = useMergedConfigurationParams();
  const lang = getShortLanguage(i18n.resolvedLanguage);
  const { params } = useRouteMatch<{ tab: string }>();
  const { data: clientData } = useClientPermissions(clientId);
  const clientCreationDate = clientData?.client?.creationDate;
  const { diversityAnalytics, crmMarketing } =
    clientData?.client.permissions || {};
  // states for the custom dates
  const [startDate, setStartDate] = useState<Date>(defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [{ timeSpan, missionIds }, dispatch] = useContext(
    StatisticsParametersContext,
  );

  const setTimeSpan = useCallback(
    (value: TimeSpan) => dispatch({ type: 'setTimeSpan', value }),
    [dispatch],
  );

  const setMissionIds = useCallback(
    (value: string[]) => dispatch({ type: 'setMissionIds', value }),
    [dispatch],
  );

  const atsId = useClientCurrentAtsId();

  const formatValue = (value: string) => ({
    key: value,
    value,
    text: t(`analytics.dateRanges.${value}`),
  });

  const timeSpanOptions = () => _.map(TIMES_VALUES, formatValue);

  const handleTimespan = (
    _ev: SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps,
  ) => {
    const value = toString(data.value);
    if (value !== 'custom') {
      setTimeSpan(
        dateRangeDates({
          dateRange: value,
          firstDate: clientCreationDate,
        }) as TimeSpan,
      );
    } else {
      // don't update the actual dates yet, just change to custom
      setTimeSpan({ ...timeSpan, id: 'custom' });
      // reset the datepickers to default
      setStartDate(new Date());
      setEndDate(new Date());
    }
  };

  const onChangeStartDate = (date: Date) => {
    setStartDate(date);
    setTimeSpan({
      id: 'custom',
      startDate: moment(date),
      endDate: moment(endDate),
    });
  };

  const onChangeEndDate = (date: Date) => {
    setEndDate(date);
    setTimeSpan({
      id: 'custom',
      startDate: moment(startDate),
      endDate: moment(date),
    });
  };

  const dateFormat = useMemo(
    () => (lang === 'en' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'),
    [lang],
  );

  return (
    <div className={styles.revealAnalyticsHeader}>
      <div className={styles.navigationButtons}>
        {atsId !== 'adventure' && (
          <Link
            to={`/client/${clientId}/reveal/analytics/missions`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'missions' && styles.selected,
            )}
          >
            {t('header.parameters.projects')}
          </Link>
        )}
        {['bloomays', 'bloomays-d06', 'hirestone', 'taylor-river'].includes(clientId ?? '') && (
          <Link
            to={`/client/${clientId}/reveal/analytics/missions-process`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'missions-process' && styles.selected,
            )}
          >
            Process
          </Link>
        )}
        {atsId !== 'adventure' && (
          <Link
            to={`/client/${clientId}/reveal/analytics/sequences`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'sequences' && styles.selected,
            )}
          >
            {t('header.parameters.sequences', { count: 2 })}
          </Link>
        )}
        {atsId !== 'adventure' && (
          <Link
            to={`/client/${clientId}/reveal/analytics/tasks`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'tasks' && styles.selected,
            )}
          >
            {t('header.parameters.todos')}
          </Link>
        )}
        {atsId !== 'adventure' && diversityAnalytics && (
          <Link
            to={`/client/${clientId}/reveal/analytics/diversity`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'diversity' && styles.selected,
            )}
          >
            {t('header.parameters.diversity')}
          </Link>
        )}
        {atsId !== 'adventure' && crmMarketing && (
          <Link
            to={`/client/${clientId}/reveal/analytics/marketing`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'marketing' && styles.selected,
            )}
          >
            {t('header.parameters.marketing')}
          </Link>
        )}
        {atsId === 'adventure' && (
          <Link
            to={`/client/${clientId}/reveal/analytics/adventure1`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'adventure1' && styles.selected,
            )}
          >
            Viviers
          </Link>
        )}
        {atsId === 'adventure' && (
          <Link
            to={`/client/${clientId}/reveal/analytics/adventure2`}
            className={classnames(
              styles.navigationButton,
              params.tab === 'adventure2' && styles.selected,
            )}
          >
            Actions
          </Link>
        )}
      </div>

      <div className={styles.selectionDropdown}>
        {params.tab === 'tasks' && (
          <div className={styles.leftDropdown}>
            <UsersDropdown
              selectedUsers={userEmails}
              onChangeUsers={onChangeUsers}
              clientId={clientId}
            />
          </div>
        )}
        {(params.tab === 'missions' || params.tab === 'missions-process' || params.tab === 'diversity') && (
          <div className={styles.leftDropdown}>
            {configurationParams?.canUseStatsProjectTreeSelector !== 'true' ? (
              <MissionsDropdown
                selectedMissions={missionIds}
                onChangeMissions={setMissionIds}
              />
            ) : (
              <ProjectTreeDropdownProvider
                onSelectedItemsChange={setMissionIds}
              >
                <ProjectTreeDropdown
                  style={{
                    minWidth: '350px',
                  }}
                />
              </ProjectTreeDropdownProvider>
            )}
          </div>
        )}
        {(params.tab === 'adventure1' || params.tab === 'adventure2') && (
          <MissionFolderingSelector
            onMissionIds={setMissionIds}
            displayMissionDropdown={params.tab === 'adventure1'}
          />
        )}
        {(params.tab === 'adventure1' || params.tab === 'adventure2') && (
          <div className={styles.leftDropdown}>
            <AggregatePeriodSelector />
          </div>
        )}
        {(params.tab === 'sequences' || params.tab === 'tasks') && (
          <div className={styles.leftDropdown}>
            <SequenceSelector
              clientId={clientId}
              selectedSequence={selectedSequence ?? []}
              setSelectedSequence={setSelectedSequence}
              t={t}
            />
          </div>
        )}
        {timeSpan.id === 'custom' && (
          <>
            <div className={styles.dateRangePicker}>
              <DatePicker
                selected={startDate}
                autoFocus
                onChange={onChangeStartDate}
                locale={lang}
                dateFormat={dateFormat}
              />
            </div>
            &nbsp;-&nbsp;
            <div className={classnames(styles.dateRangePicker, styles.last)}>
              <DatePicker
                selected={endDate}
                onChange={onChangeEndDate}
                locale={lang}
                dateFormat={dateFormat}
              />
            </div>
          </>
        )}
        <Dropdown
          selection
          onChange={handleTimespan}
          value={timeSpan.id}
          options={timeSpanOptions()}
          className='hiresweet-rounded'
        />
      </div>
    </div>
  );
};

const defaultStartDate = dateRangeDates({
  dateRange: 'quarter',
  firstDate: new Date(0),
}).startDate.toDate();

export default RevealAnalyticsHeader;
