/* eslint-disable class-methods-use-this */
import _ from 'underscore';
import { cleanupProfileInfoTags } from '@hiresweet/hiresweet-lib';

import { SweetEvaluatorTypes } from '@/SweetEvaluator';

export default class ExpressionCurrentJobTitleEvaluator
  implements SweetEvaluatorTypes.EvaluatorInterface
{
  evaluate({ context }: { context: SweetEvaluatorTypes.Context }): {
    value: string | undefined;
  } {
    const anyContext = context as any;
    const profileExperiences =
      anyContext.experiences || anyContext.data?.experiences || null;

    if (_.isEmpty(profileExperiences)) {
      return { value: undefined };
    }

    if (profileExperiences.length === 1) {
      return { value: profileExperiences[0]?.title?.text || undefined };
    }

    const currentExperience = _.find(
      profileExperiences,
      ({ endDate }) => !endDate,
    );

    const result = cleanupProfileInfoTags(currentExperience?.title?.text ?? '');

    return { value: result || undefined };
  }
}
