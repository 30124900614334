import React from 'react';
import { HiresweetLibProvider, SharedSpace } from '@hiresweet/hiresweet-lib';
import { useRouteMatch } from 'react-router-dom';
import i18n from '@/i18n';
import { getShortLanguage } from '@/common/utils/i18n';

const SLASH_APP_IDS = ['slhp'];

const SharedSpaceView = () => {
  const { resolvedLanguage } = i18n;
  const lang = resolvedLanguage ? getShortLanguage(resolvedLanguage) : null;

  const { params } = useRouteMatch();
  const { standaloneReferrer, appId } = params as {
    standaloneReferrer: string;
    appId: string;
  };
  const decodedReferrer = atob(standaloneReferrer);
  const parts = decodedReferrer.split(':');
  const [clientId] = parts;

  if (!clientId) {
    return null;
  }

  return (
    <HiresweetLibProvider
      clientId={clientId}
      theme={SLASH_APP_IDS.includes(appId) ? 'slash' : 'hiresweet'}
      lang={lang || 'fr'}
    >
      <SharedSpace
        standaloneReferrerToken={standaloneReferrer}
        sharedSpaceData={{
          type: 'built-in',
          subtype: 'multi-project-shared-views',
        }}
        initialState=''
        onState={() => {}}
      />
    </HiresweetLibProvider>
  );
};

export default SharedSpaceView;
