import React from 'react';
import _ from 'underscore';
import { Segment } from 'semantic-ui-react';
import { getOneATSDataItemAttachments } from '@/common/reveal/SourceData';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import { getSortedSkillsWishes } from '../../../../components/Profile/SkillsLabels';
import ATSData from '../../../../containers/Profile/Resume/Card/ATSData';
import ProfileHeader from '../../../../containers/Profile/Resume/Card/ProfileHeader';
import SmartSummary from '../../../../containers/Profile/Resume/Card/SmartSummary';
import Background from '../../../../containers/Profile/Resume/Background';
import Attachments from '../../../../containers/Profile/Resume/Attachments/Attachments';

import useEnrichedSearchPoolProfile from '../../../../graphql/hooks/profiles/useEnrichedSearchPoolProfile';
import HoverPopup from './HoverPopup';

import './HoverSearchpoolProfileCard.css';

interface OnSelectAttachmentArgs {
  id: string;
}

const onSelectAttachment = ({ id }: OnSelectAttachmentArgs) => {
  const attachmentElement = document.getElementById(id);
  if (attachmentElement) {
    attachmentElement.scrollIntoView();
  }
};

interface HoverSearchpoolProfileCardProps {
  profileId: string;
  searchPoolId: string;
  clientId: string;
}

const HoverSearchPoolProfileCard: React.FC<HoverSearchpoolProfileCardProps> = ({
  children,
  profileId,
  searchPoolId,
  clientId,
}) => {
  const PopupContent = (
    <HoverSearchPoolProfileContent
      clientId={clientId}
      profileId={profileId}
      searchPoolId={searchPoolId}
    />
  );

  return <HoverPopup content={PopupContent}>{children}</HoverPopup>;
};

interface HoverSearchPoolProfileContentProps {
  searchPoolId: string;
  profileId: string;
  clientId: string;
}

const HoverSearchPoolProfileContent: React.FC<
  HoverSearchPoolProfileContentProps
> = ({ searchPoolId, profileId, clientId }) => {
  const { data } = useEnrichedSearchPoolProfile({
    id: profileId,
    searchPoolId,
  });
  const configurationParams = useMergedConfigurationParams();

  const profile = data?.searchPool?.enrichedProfile;
  const resumeData = profile?.resumeData;
  const attachments = profile?.attachments;
  const ATSAttachments = getOneATSDataItemAttachments(
    profile?.resumeData?.sourceData,
  );
  const allAttachments = (ATSAttachments || []).concat(
    _.map(attachments || [], (attachment) => ({
      ...attachment,
      from: 'hiresweet',
    })),
  );

  const hidePanesAndTopCorner =
    configurationParams?.hideProfilePanesAndTopRightCornerIfDoNotContact ===
      'true' && profile?.privacyState?.markedAsDoNotContact;

  return (
    <Segment
      className='hover-searchpool-profile-content resume-data-card'
      basic
    >
      <ProfileHeader
        profile={profile}
        profileId={profileId}
        firstname={resumeData?.firstname}
        lastname={resumeData?.lastname}
        resumeData={resumeData}
        clientId={clientId}
        watchCollectViewMode
        searchPoolId={searchPoolId}
      />
      {!hidePanesAndTopCorner && (
        <>
          <ATSData profile={profile} clientId={clientId} />
          <SmartSummary
            profileId={profileId}
            argumentation={resumeData?.smartSummary?.arguments}
            skills={getSortedSkillsWishes({
              hiresweetData: resumeData?.sourceData?.hiresweet,
            })}
            neutralMode
          />
          <Attachments
            attachments={allAttachments}
            attachmentsAreImmutable
            onSelectAttachment={onSelectAttachment}
            profileId={profileId}
          />
          <Background
            clientId={clientId}
            resumeData={resumeData}
            attachments={allAttachments}
            hideAttachmentActions
            profileId={profileId}
            hoverPopupsDisabled
            isRevealProfile
          />
        </>
      )}
    </Segment>
  );
};

export default HoverSearchPoolProfileCard;
