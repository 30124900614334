import React from 'react';
import { useBackgroundTranslator } from '@hiresweet/hiresweet-lib';

import { getEnrichedText } from '@/common';
import CollapsibleEnrichedText from '@/components/CollapsibleEnrichedText';

import './BackgroundItemSubCard.css';

const BackgroundItemSubCard = ({ title, subtitle, content, logExpand }) => {
  const backgroundTranslator = useBackgroundTranslator();
  const translatedText = content.text
    ? backgroundTranslator(content.text)
    : undefined;

  return (
    <div className='background-item-sub-card'>
      <div className='sub-card-bullet-container'>
        <div className='sub-card-bullet' />
      </div>

      <div className='sub-card-content'>
        <div className='card-title'>{title}</div>
        <div className='card-subtitle'>{subtitle}</div>
        {content && (
          <div className='card-content'>
            <CollapsibleEnrichedText
              enrichedText={getEnrichedText(translatedText)}
              charCost={1}
              lineCost={90}
              targetMaxCost={400}
              toleranceFactor={0.3}
              onToggleCollapse={logExpand}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BackgroundItemSubCard;
