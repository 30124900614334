import React from 'react';
import _ from 'underscore';

import { Category } from '@/types/statistics/category';

import { PipelineDescriptor, SegmentListeners } from '../types';
import GenericFunnelRow from '../../GenericFunnelRow';

import styles from './CategoryFunnelStatistics.module.less';

interface CategoryFunnelStatisticsProps {
  data: Record<string, number>;
  pipelineDescriptor: PipelineDescriptor;
  category?: Category;
  conversionRates: Record<string, string>;
  clickListeners?: SegmentListeners;
}

const CategoryFunnelStatistics: React.FC<CategoryFunnelStatisticsProps> = ({
  data,
  pipelineDescriptor,
  category,
  conversionRates,
  clickListeners,
}) => {
  const segmentDescriptions = _.map(pipelineDescriptor.steps, ({ id }) => ({
    key: id,
    count: data[id] ?? 0,
    conversionRate: conversionRates?.[id],
    clickListener: clickListeners?.[id],
    classNames: { global: styles[id], count: styles.count },
  }));

  return <GenericFunnelRow row={category} segments={segmentDescriptions} />;
};

export default CategoryFunnelStatistics;
