import TranslatableText from '@/graphql/fragments/TranslatableText';
import { gql, useMutation } from '@apollo/client';

const MOVE_CUSTOM_CLIENT_COMPANY_STATE_DOWNWARD = gql`
  mutation MoveClientCompanyStateDownward($customCompanyDealStateId: ID!) {
    moveClientCompanyStateDownward(
      customCompanyDealStateId: $customCompanyDealStateId
    ) {
      id
      customCompanyDealStates {
        customCompanyDealStateId
        title {
          ...TranslatableText
        }
        type
      }
    }
  }
  ${TranslatableText}
`;

interface MoveClientCustomCompanyDealStateDownwardVariables {
  customCompanyDealStateId: string;
}

interface ClientCustomCompanyDealStateResults {
  client: {
    customCompanyDealStates: {
      customCompanyDealStateId: string;
      title: {
        default: string;
        fr: string;
        en: string;
        de?: string;
      };
      type: string;
    }[];
  };
}

const useMoveCustomClientCompanyStateDownward = () => {
  return useMutation<
    ClientCustomCompanyDealStateResults,
    MoveClientCustomCompanyDealStateDownwardVariables
  >(MOVE_CUSTOM_CLIENT_COMPANY_STATE_DOWNWARD);
};

export default useMoveCustomClientCompanyStateDownward;
