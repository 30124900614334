import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';

import './ProfileTabsMenu.css';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import useClientId from '@/hooks/router/useClientId';
import useResizableItemList from '@/hooks/common/useResizableItemList';
import _ from 'underscore';
import classNames from 'classnames';
import GenericDropdown from '@/components/Common/GenericDropdown';
import DropdownControlsContext from '@/context/DropdownControlsContext';
import DropdownMenuPanel from '@/components/Common/DropdownMenuPanel';

interface ProfileTabsMenuProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  isCompany?: boolean;
  withSimilarProfiles?: boolean;
  nbMissions?: number;
  atsName?: string;
  hasHiresweetDetails: boolean;
}

const ProfileTabsMenu = ({
  activeTab,
  setActiveTab,
  isCompany = false,
  withSimilarProfiles = false,
  nbMissions = 0,
  atsName,
  hasHiresweetDetails = false,
}: ProfileTabsMenuProps) => {
  const { t } = useTranslation();
  const clientId = useClientId();
  const { permissions } = useClientPermissions(clientId);
  const configurationParams = useMergedConfigurationParams();

  if (isCompany) {
    const menuItems = [
      ...(configurationParams?.shouldHideProfileTab !== 'true'
        ? [
            {
              id: 'company-overview',
              title: t('reveal.profileModal.company'),
              isActive: activeTab === 'company-overview',
            },
          ]
        : []),
      ...(permissions?.profileDocumentsTab
        ? [
            {
              id: 'documents',
              title: t('reveal.profileModal.documents'),
              isActive: activeTab === 'documents',
            },
          ]
        : []),
    ];
    return (
      <ProfileTabsMenuInner items={menuItems} onActiveTab={setActiveTab} />
    );
  }

  const menuItems = [
    ...(configurationParams?.shouldHideProfileTab !== 'true'
      ? [
          {
            id: 'profile',
            title: t('reveal.profileModal.profile'),
            isActive: activeTab === 'profile',
          },
        ]
      : []),
    ...(configurationParams?.shouldHideProfileATSPane !== 'true' && atsName
      ? [
          {
            id: 'ATS',
            title: t(`integrations.types.${atsName}`),
            isActive: activeTab === 'ATS',
          },
        ]
      : []),
    ...(configurationParams?.shouldHideProfileInformationPane !== 'true'
      ? [
          {
            id: 'details',
            title: t('reveal.profileModal.details'),
            isActive: activeTab === 'details',
          },
        ]
      : []),
    ...(permissions?.profileDocumentsTab
      ? [
          {
            id: 'documents',
            title: t('reveal.profileModal.documents'),
            isActive: activeTab === 'documents',
          },
        ]
      : []),
    ...(permissions?.canUseActivePlacementsTab
      ? [
          {
            id: 'active-placement',
            title: t('reveal.profileModal.active-placement'),
            isActive: activeTab === 'active-placement',
          },
        ]
      : []),
    ...(hasHiresweetDetails
      ? [
          {
            id: 'hs-data',
            title: t('reveal.profileModal.hsData'),
            isActive: activeTab === 'hs-data',
          },
        ]
      : []),
    ...(configurationParams?.shouldHideProfileProjectsPane !== 'true'
      ? [
          {
            id: 'missions',
            title: (
              <>
                {t('header.parameters.projects')}{' '}
                {nbMissions > 0 && (
                  <span className='ui label'>{nbMissions}</span>
                )}
              </>
            ),
            isActive: activeTab === 'missions',
          },
        ]
      : []),
    ...(configurationParams?.shouldDisplayProfileTimelineOnLeftPane === 'true'
      ? [
          {
            id: 'timeline',
            title: t('profile.contact.timeline.timeline'),
            isActive: activeTab === 'timeline',
          },
        ]
      : []),
    ...(configurationParams?.shouldHideProfileSimilarProfilesPane !== 'true' &&
    withSimilarProfiles
      ? [
          {
            id: 'similar',
            title: t('reveal.profileModal.similarProfiles'),
            isActive: activeTab === 'similar',
          },
        ]
      : []),
  ];

  return <ProfileTabsMenuInner items={menuItems} onActiveTab={setActiveTab} />;
};

export default ProfileTabsMenu;

type ProfileTabsMenuInnerProps = {
  items: {
    id: string;
    title: ReactNode;
    isActive: boolean;
  }[];
  onActiveTab: (tab: string) => void;
};

const ProfileTabsMenuInner = ({
  items,
  onActiveTab,
}: ProfileTabsMenuInnerProps) => {
  const { containerRef, listRef, displayedItemsNumber, finalWidth } =
    useResizableItemList<HTMLDivElement>(0);

  return (
    <div className='profile-tabs-menu' ref={containerRef}>
      <div className='tab-list' ref={listRef} style={{ width: finalWidth }}>
        {_.map(items, (item, index) => (
          <div
            key={item.id}
            onClick={() => onActiveTab(item.id)}
            onKeyPress={() => onActiveTab(item.id)}
            role='menuitem'
            tabIndex={0}
            className={classNames(
              'tab',
              { active: item.isActive },
              index >= displayedItemsNumber && 'hidden',
            )}
          >
            <h4>{item.title}</h4>
          </div>
        ))}
      </div>
      {items.length > displayedItemsNumber && (
        <GenericDropdown
          trigger={
            <DropdownControlsContext.Consumer>
              {({ toggleDropdown }) => {
                return (
                  <div
                    className={classNames('tab', {
                      active: _.some(
                        items.slice(displayedItemsNumber),
                        (item) => item.isActive,
                      ),
                    })}
                    onClick={toggleDropdown}
                    onKeyPress={toggleDropdown}
                    role='menuitem'
                    tabIndex={0}
                  >
                    <h4>...</h4>
                  </div>
                );
              }}
            </DropdownControlsContext.Consumer>
          }
        >
          <DropdownMenuPanel
            options={items.slice(displayedItemsNumber).map((item) => ({
              id: item.id,
              label: item.title,
            }))}
            onSelect={onActiveTab}
          />
        </GenericDropdown>
      )}
    </div>
  );
};
