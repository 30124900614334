import GenericButton from '@/components/Common/GenericButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import _ from 'underscore';
import GenericDropdown from '@/components/Common/GenericDropdown';
import Dots from '@/components/Common/Icons/Dots';
import DropdownPanel from '@/components/Common/DropdownPanel';
import DropdownMenuItem from '@/components/Common/DropdownMenuItem';
import Edit from '@/components/Reveal/Icons/Edit';
import ArrowDown from '@/components/Reveal/Icons/ArrowDown';
import Delete from '@/components/Reveal/Icons/Delete';
import ArrowUp from '@/components/Reveal/Icons/ArrowUp';
import { ClientCompanyDealState } from '@/graphql/hooks/clients/useClientCustomCompanyDealStates';
import styles from './CompaniesStatesTable.module.less';
import { getTranslatedText } from '../../../common/index';

interface CompaniesStatesTableProps {
  companyStates: ClientCompanyDealState[];
  onRemove: ({ companyStateId }: { companyStateId: string }) => void;
  onEdit: ({ companyStateId }: { companyStateId: string }) => void;
  onMoveUpward: ({ companyStateId }: { companyStateId: string }) => void;
  onMoveDownward: ({ companyStateId }: { companyStateId: string }) => void;
}

const CompaniesStatesTable: React.FC<CompaniesStatesTableProps> = ({
  companyStates,
  onRemove,
  onEdit,
  onMoveUpward,
  onMoveDownward,
}) => {
  const { t } = useTranslation();

  if (_.isEmpty(companyStates)) {
    return null;
  }

  const options = [
    {
      value: 'prospect',
      label: getTranslatedText({
        default: 'Prospect',
        fr: 'Prospect',
        en: 'Prospect',
        de: 'Aussicht',
      }),
    },
    {
      value: 'client',
      label: getTranslatedText({
        default: 'Client',
        fr: 'Client',
        en: 'Client',
        de: 'Kunde',
      }),
    },
    {
      value: 'archived',
      label: getTranslatedText({
        default: 'Archived',
        fr: 'Archivé',
        en: 'Archived',
        de: 'Archiviert',
      }),
    },
  ] as const;

  return (
    <Table basic className={styles.table}>
      <Table.Header className={styles.header}>
        <Table.Row className={styles.headerRow}>
          <Table.HeaderCell className={styles.th}>
            <span>{t('settings.companiesStates.table.name')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell className={styles.th}>
            <span>{t('settings.companiesStates.table.type')}</span>
          </Table.HeaderCell>
          <Table.HeaderCell collapsing className={styles.th} />
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {_.map(companyStates || [], (companyState, index) => (
          <Table.Row
            key={companyState.customCompanyDealStateId}
            className={styles.row}
          >
            <Table.Cell textAlign='left' className={styles.name}>
              <span>{getTranslatedText(companyState.title)}</span>
            </Table.Cell>
            <Table.Cell textAlign='left' className={styles.type}>
              <span>
                {
                  _.findWhere(options, {
                    value: companyState?.type as
                      | 'prospect'
                      | 'client'
                      | 'archived',
                  })?.label
                }
              </span>
            </Table.Cell>
            <Table.Cell className={styles.actions}>
              <GenericDropdown
                position='right'
                Trigger={({ onClick }) => (
                  <GenericButton primacy='tertiary' onClick={onClick}>
                    <Dots />
                  </GenericButton>
                )}
              >
                <DropdownPanel className={styles.actionPanel}>
                  <DropdownMenuItem
                    className={styles.actionPanelItem}
                    onClick={() => {
                      onEdit({
                        companyStateId: companyState.customCompanyDealStateId,
                      });
                    }}
                  >
                    <span>
                      <Edit />
                    </span>
                    {t('common.edit')}
                  </DropdownMenuItem>
                  {index > 0 ? (
                    <DropdownMenuItem
                      className={styles.actionPanelItem}
                      onClick={() => {
                        onMoveUpward({
                          companyStateId: companyState.customCompanyDealStateId,
                        });
                      }}
                    >
                      <span>
                        <ArrowUp />
                      </span>
                      {t('settings.companiesStates.moveUpward')}
                    </DropdownMenuItem>
                  ) : (
                    ''
                  )}
                  {index < companyStates.length - 1 ? (
                    <DropdownMenuItem
                      className={styles.actionPanelItem}
                      onClick={() => {
                        onMoveDownward({
                          companyStateId: companyState.customCompanyDealStateId,
                        });
                      }}
                    >
                      <span>
                        <ArrowDown />
                      </span>
                      {t('settings.companiesStates.moveDownward')}
                    </DropdownMenuItem>
                  ) : (
                    ''
                  )}
                  <DropdownMenuItem
                    className={styles.actionPanelItem}
                    onClick={() => {
                      onRemove({
                        companyStateId: companyState.customCompanyDealStateId,
                      });
                    }}
                  >
                    <span>
                      <Delete />
                    </span>
                    {t('settings.companiesStates.delete')}
                  </DropdownMenuItem>
                </DropdownPanel>
              </GenericDropdown>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default CompaniesStatesTable;
