import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavApplicationMode } from '@/containers/TopBar/Header/NavSwitch.hook';
import SettingsLayout from '../../SettingsLayout';

import './LegalDocumentation.css';

interface LegalDocumentationProps {
  clientId: string;
}

const ClassicLegalDocumentation = () => {
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lng = resolvedLanguage?.slice(0, 2);
  const termsLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-cgv'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-t-and-c';
  const privacyLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-fr/hiresweet-marketplace-politique-de-confidentialite'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-marketplace-en/hiresweet-marketplace-privacy';

  return (
    <>
      <div className='description'>
        {t('settings.legalDocumentation.marketplaceDescription')}
      </div>
      <div className='links'>
        <a href={termsLink} target='_blank' rel='noopener noreferrer'>
          {t('settings.legalDocumentation.marketplaceLinks.termsAndConditions')}
          <i className='ri-external-link-line ri-lg' />
        </a>
        <a href={privacyLink} target='_blank' rel='noopener noreferrer'>
          {t('settings.legalDocumentation.marketplaceLinks.privacy')}
          <i className='ri-external-link-line ri-lg' />
        </a>
      </div>
    </>
  );
};

const RevealLegalDocumentation = () => {
  const { t, i18n } = useTranslation();
  const { resolvedLanguage } = i18n;
  const lng = resolvedLanguage?.slice(0, 2);
  const termsLink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-crm-fr/hiresweet-crm-cgs'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-crm-en/hiresweet-crm-terms';
  const DPALink =
    lng === 'fr'
      ? 'https://content.hiresweet.com/clients-hiresweet/hiresweet-crm-fr/hiresweet-crm-conditions-generales-de-services'
      : 'https://content.hiresweet.com/clients-hiresweet/hiresweet-crm-en/hiresweet-crm-dpa';

  return (
    <>
      <div className='description'>
        {t('settings.legalDocumentation.revealDescription')}
      </div>
      <div className='links'>
        <a href={termsLink} target='_blank' rel='noopener noreferrer'>
          {t('settings.legalDocumentation.revealLinks.termsAndConditions')}
          <i className='ri-external-link-line ri-lg' />
        </a>
        <a href={DPALink} target='_blank' rel='noopener noreferrer'>
          {t('settings.legalDocumentation.revealLinks.DPA')}
          <i className='ri-external-link-line ri-lg' />
        </a>
      </div>
    </>
  );
};

const LegalDocumentation: React.FC<LegalDocumentationProps> = ({
  clientId,
}) => {
  const { t } = useTranslation();
  const { applicationMode } = useNavApplicationMode();

  return (
    <SettingsLayout
      currentPage='legal'
      clientId={clientId}
      className='settings-container'
    >
      <div className='legal-settings'>
        <div className='settings-header'>
          <h1>{t('header.accountDropdownMenu.legalDocumentation')}</h1>
        </div>
        <div className='content'>
          {applicationMode === 'classic' ? (
            <ClassicLegalDocumentation />
          ) : (
            <RevealLegalDocumentation />
          )}
        </div>
      </div>
    </SettingsLayout>
  );
};

export default LegalDocumentation;
