import GenericAccordion from '@/components/Common/GenericAccordion';
import { FormInputText, Modal } from '@hiresweet/hiresweet-lib';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useApplyClientTemplate from '@/graphql/hooks/clients/useApplyClientTemplate';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import styles from './ApplyClientTemplateForm.module.less';

type ApplyClientTemplateFormProps = {
  open: boolean;
  onOpen: (open: boolean) => void;
};

const ApplyClientTemplateForm = ({
  open,
  onOpen,
}: ApplyClientTemplateFormProps) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = React.useState('');
  const notifications = useNotificationSystem();

  const [applyClientTemplate] = useApplyClientTemplate();

  const handleSubmit = () => {
    applyClientTemplate({
      variables: {
        mode: `configFrom:${templateId}`,
      },
    })
      .then(() => {
        notifications.success(t('settings.clientModes.applyTemplate.success'));
        onOpen(false);
      })
      .catch((e) => {
        console.error(e);
        notifications.error(t('settings.clientModes.applyTemplate.error'));
      });
  };

  return (
    <Modal
      title={t('settings.clientModes.applyTemplate.title')}
      open={open}
      onClose={() => onOpen(false)}
      onSubmit={handleSubmit}
      size='small'
      closeText={t('common.cancel')}
    >
      <FormInputText
        label={t('settings.clientModes.applyTemplate.label')}
        value={templateId}
        onChange={setTemplateId}
        placeholder={t('settings.clientModes.applyTemplate.placeholder')}
      />
      <GenericAccordion
        title={t('settings.clientModes.applyTemplate.howToFindClientId')}
      >
        <div className={styles.summaryContent}>
          {t('settings.clientModes.applyTemplate.howToFindClientIdDescription')}
          <div className={styles.clientIdExample}>
            <div className={styles.fakeInput}>
              <span className={styles.urlPart1}>
                https://app.hiresweet.com/client/
              </span>
              <span className={styles.urlPart2}>xxxxx(-)xxxxx</span>
            </div>
          </div>
        </div>
      </GenericAccordion>
    </Modal>
  );
};

export default ApplyClientTemplateForm;
