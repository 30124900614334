import React, {
  useEffect,
  useState,
  FC,
  useContext,
  useMemo,
  ReactNode,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from 'semantic-ui-react';
import { useMutation, useQuery } from '@apollo/client';
import RevealCandidateViewTimeLineItems from '@/containers/Profile/Contact/TimeLineItems/RevealCandidateViewTimeLineItems';

import './CandidateView.css';
import { useCandidateViewContext } from '@/context/CandidateView/useCandidateViewContext';
import TaskLink from '@/routes/RevealView/SearchView/CandidatesListPane/CandidateView/TaskLink';
import ProfileRow from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/ProfileRow';
import { Sequence } from '@/types/sequence';
import useIsPlugin from '@/hooks/common/useIsPlugin';

import PluginProfileView from '@/containers/PluginProfile/PluginProfileView';
import { useClientProfileCustomFields } from '@/graphql/hooks/clients/useClientProfileCustomFields';
import RevealProfileHeader from '@/containers/Profile/Resume/Card/RevealProfileHeader';
import PluginMinimizedViewProvider from '@/containers/PluginProfile/PluginMinimizedViewProvider';
import MinimizedViewContext from '@/context/MinimizedView/MinimizedViewContext';
import { REGISTER_REVEAL_PROFILE_VIEW } from '@/graphql/searchPoolProfiles';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import useSharedMessagingAccounts from '@/graphql/hooks/clients/useSharedServiceAccount';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import ContactCategoryContext from '@/context/ContactTypeContext';
import { SkipProfileMessage } from '@/routes/RevealView/JobsView/JobView/ProjectProfilesTab/SkipWithEmailContext/SkipWithEmailContext';
import RevealCandidateViewNewActivity from './RevealCandidateViewNewActivity';
import RevealCandidateViewNewMessage from './RevealCandidateViewNewMessage';
import RevealCandidateViewTasks from './RevealCandidateViewTasks';
import ResumeDataCard from './ResumeDataCard';
import RevealCandidateViewPendingActions from '../../../revealComponents/ProfileContactFlow/Sequences/RevealPendingActions/RevealCandidateViewPendingActions';
import CandidateViewControlsContext from './CandidateViewControlsContext';
import SEARCH_POOL_PROFILE_LAST_EVENT from '../../../../../graphql/useSearchPoolProfileLastEvent';
import SharedNoteModal from './SharedNotes/SharedNoteModal';
import DynamicCandidateRecommendationBlurb from './CandidateRecommendationBlurb';

const filterExplicitTask = (searchPoolProfile: any) => {
  return searchPoolProfile?.explicitTasks.filter(
    (explicitT: any) => explicitT.state !== 'completed',
  );
};

interface CandidateViewProps {
  searchPoolId: string;
  clientId: string;
  withSimilarProfiles: boolean;
  onClose: () => void;
  isRecommendedProfileModal?: boolean;
  isApplicantProfileModal?: boolean;
  skipProfile?: (
    id: string,
    reason: string,
    message?: SkipProfileMessage,
  ) => void;
  confirmProfile?: (
    profileId: string,
    selectedSequenceId: string | null,
  ) => void;
  jobId?: string;
  clientSequences?: Sequence[];
  onChangeStage?: (data: any) => void;
  skipLoading?: boolean;
}

const CandidateViewControlsContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [editMode, setEditMode] = useState(false);
  const value = useMemo(() => ({ editMode, setEditMode }), [editMode]);
  return (
    <CandidateViewControlsContext.Provider value={value}>
      {children}
    </CandidateViewControlsContext.Provider>
  );
};

const CandidateView: React.FC<CandidateViewProps> = ({
  searchPoolId,
  clientId,
  withSimilarProfiles,
  onClose,
  isRecommendedProfileModal = false,
  isApplicantProfileModal = false,
  skipProfile,
  confirmProfile,
  jobId,
  clientSequences,
  onChangeStage,
  skipLoading,
}) => {
  const { t } = useTranslation();
  const { permissions } = useClientPermissions(clientId);
  const configurationParams = useMergedConfigurationParams();
  const {
    profileId,
    currentTask,
    currentSequenceTask,
    profile: searchPoolProfile,
    loadingProfile,
    profileError,
    refetch: refetchFullProfile,
  } = useCandidateViewContext();
  const { editMode, setEditMode } = useContext(CandidateViewControlsContext);
  const { sharedMessagingAccounts } = useSharedMessagingAccounts();

  const hasWhatsappAutomation =
    permissions?.unipile &&
    _.findIndex(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-whatsapp',
    ) >= 0;
  const hasLinkedinAutomation =
    permissions?.unipile &&
    _.findIndex(
      sharedMessagingAccounts,
      (account) => account.type === 'unipile-linkedin',
    ) >= 0;

  const { data: lastEventTimestampData } = useQuery(
    SEARCH_POOL_PROFILE_LAST_EVENT,
    {
      variables: {
        searchPoolId: 'reveal',
        profileId,
      },
      skip: !hasWhatsappAutomation && !hasLinkedinAutomation,
      pollInterval: 4000,
    },
  );

  const [displayAddSequenceModal, setDisplayAddSequenceModal] = useState(false);
  const [explicitTasks, setExplicitTasks] = useState(
    filterExplicitTask(searchPoolProfile),
  );
  const [modalDeletionOpen, setModalDeletionOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState('');
  const [messageMetadata, setMessageMetadata] = useState<
    { key: string; value: string | boolean }[]
  >([]);
  const [updateProfileViews] = useMutation(REGISTER_REVEAL_PROFILE_VIEW);
  const [lastSeenEventTimestamp, setLastSeenEventTimestamp] = useState(null);

  const isPlugin = useIsPlugin();

  const sortedExplicitTasks = useMemo(
    () => _.sortBy(explicitTasks, ({ dueDate }) => dueDate),
    [explicitTasks],
  );

  useEffect(() => {
    if (!lastEventTimestampData) {
      return;
    }
    const timestampStr =
      lastEventTimestampData.searchPool?.profile?.lastEventTimestamp;
    if (lastSeenEventTimestamp && lastSeenEventTimestamp !== timestampStr) {
      // refetch timeline
      refetchFullProfile();
    }
    setLastSeenEventTimestamp(timestampStr);
  }, [lastEventTimestampData, lastSeenEventTimestamp, refetchFullProfile]);

  useEffect(() => {
    updateProfileViews({
      variables: {
        searchPoolId,
        profileId,
        origin: {
          type: isPlugin ? 'plugin' : 'app',
          URL: window.location.href,
        },
      },
    });
    // eslint-disable-next-line
  }, [searchPoolId, profileId]);

  useEffect(() => {
    if (searchPoolProfile) {
      setExplicitTasks(filterExplicitTask(searchPoolProfile));
    }
    // eslint-disable-next-line
  }, [searchPoolProfile]);

  const handleConfirmProfileSubmit = (selectedSequenceId: string | null) => {
    if (!confirmProfile) {
      console.error('No confirmProfile function');
      return;
    }
    confirmProfile(profileId, selectedSequenceId);
    setDisplayAddSequenceModal(false);
  };
  const { resumeData } = searchPoolProfile || {};

  const { profileCustomFields: customFields } =
    useClientProfileCustomFields(clientId);

  if (loadingProfile) {
    return (
      <div className='profile-preview'>
        <Loader active />
      </div>
    );
  }
  // Also see useEffect for another way to handle this case with onClose
  if (!searchPoolProfile) {
    return (
      <div className='profile-preview'>
        <div className='resume-section-container' />
      </div>
    );
  }
  if (loadingProfile || profileError) {
    return (
      <div className='profile-preview'>
        {loadingProfile && <Loader active />}
      </div>
    );
  }
  const currentSequence = _.findWhere(
    searchPoolProfile?.contactFlow?.sequences || [],
    {
      isCurrent: true,
    },
  );
  const sequenceIsTriggerable =
    currentSequence?.trigger?.type === 'manual-trigger';
  const currentSequenceIsCompleted = currentSequence?.completion?.isCompleted;
  const notHaveRevealPendingActions =
    _.isEmpty(searchPoolProfile?.contactFlow) ||
    _.isEmpty(currentSequence) ||
    sequenceIsTriggerable ||
    currentSequenceIsCompleted;

  const profileWasAccepted = !!_.findWhere(
    searchPoolProfile?.missionsInfo || [],
    {
      missionId: jobId,
    },
  );
  const recommendedMission = _.findWhere(
    searchPoolProfile?.recommendedMissions || [],
    {
      missionId: jobId,
    },
  );
  const applicationMission = _.findWhere(
    searchPoolProfile?.applicationMissions || [],
    { missionId: jobId },
  );
  const applicationMissionSkipReason = _.find(
    applicationMission?.history || [],
    (h) => h.archiveReason?.type === 'skipped',
  )?.archiveReason?.skipReason;

  const profileWasSkipped =
    recommendedMission?.skipped ||
    (applicationMission?.status === 'inactive' &&
      !!applicationMissionSkipReason);
  const recommendationIsActive =
    recommendedMission && !profileWasAccepted && !profileWasSkipped;

  const reviewArgument = _.findWhere(recommendedMission?.arguments ?? [], {
    type: 'review',
  });
  const recommendationBlurb = reviewArgument?.content?.default ?? '';

  if (isPlugin) {
    /*
      searchPoolProfile
    */
    return (
      <PluginMinimizedViewProvider clientId={clientId}>
        <div
          className='plugin-candidate-view-container'
          style={{
            position: 'relative',
            width: '100%',
            height: '95vh',
            background: 'whitesmoke',
          }}
        >
          <MinimizedViewContext.Consumer>
            {({ isMinimized }) => (
              <div
                className='plugin-candidate-view'
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: isMinimized ? 200 : 360,
                  overflowY: 'auto',
                  height: '100%',
                  background: '#f8faff',
                  flexDirection: 'column',
                  border: '1px solid left',
                }}
              >
                <PluginProfileView
                  clientId={clientId}
                  profile={searchPoolProfile}
                />
              </div>
            )}
          </MinimizedViewContext.Consumer>
        </div>
      </PluginMinimizedViewProvider>
    );
  }

  // const customDetails = combineProfileWithCustomFields(
  //  customFields,
  //  resumeData || {},
  // );
  // const customFieldsInitialValues = _.object(
  //  customDetails.map((o) => [o.clientCustomFieldId, o.rawValue]),
  // );

  const hidePanesAndTopCorner =
    configurationParams?.hideProfilePanesAndTopRightCornerIfDoNotContact ===
      'true' && searchPoolProfile.privacyState?.markedAsDoNotContact;

  const allActionButtonsHidden =
    configurationParams?.shouldHideNewActivity === 'true' &&
    configurationParams?.shouldHideNewTask === 'true' &&
    configurationParams?.shouldHideNewEmail === 'true';

  return (
    <div
      style={{
        width: 'calc(100vw - 174px)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <ContactCategoryContext.Provider
        value={searchPoolProfile.contactCategory?.type || 'human'}
      >
        <RevealProfileHeader
          profile={searchPoolProfile}
          profileId={profileId}
          firstname={resumeData?.firstname}
          lastname={resumeData?.lastname}
          resumeData={resumeData}
          clientId={clientId}
          task={currentTask}
          withBottomMargin
          showEmailInHeader
          editable
          customFields={customFields}
          toggleEditMode={() => setEditMode(!editMode)}
          deleteProfile={() => {
            setEditMode(false);
            setModalDeletionOpen(!modalDeletionOpen);
          }}
          missionsInfo={searchPoolProfile?.missionsInfo}
          searchPoolId={searchPoolId}
          onChangeStage={onChangeStage}
          shouldHideProfileHeaderEditionButtons={
            configurationParams?.shouldHideProfileHeaderEditionButtons ===
            'true'
          }
          shouldHideResumeDownloadButton={
            configurationParams?.shouldHideResumeDownloadButton === 'true'
          }
          displayProjectAndSequenceOptions={
            searchPoolProfile.contactCategory?.type !== 'company' &&
            configurationParams?.shouldHideProjectAndSequenceOptions !==
              'true' &&
            !hidePanesAndTopCorner
          }
          shouldDisplayHiredPill={
            configurationParams?.shouldDisplayHiredPill === 'true'
          }
        />
      </ContactCategoryContext.Provider>
      <div className='candidate-view profile-preview' id='reveal-profile-view'>
        {!hidePanesAndTopCorner && (
          <>
            <div className='resume-section-container regular-profile-view'>
              <ResumeDataCard
                searchPoolId={searchPoolId}
                searchPoolProfile={searchPoolProfile}
                profileId={profileId}
                clientId={clientId}
                withSimilarProfiles={withSimilarProfiles}
                task={currentTask}
                showEmailInHeader
                onCloseProfileModal={onClose}
                toggleEditMode={() => setEditMode(!editMode)}
                isEditing={editMode}
                openDeleteModal={modalDeletionOpen}
                setOpenDeleteModal={setModalDeletionOpen}
              />
            </div>
            <div
              className={`action-section-container ${modalOpen ? 'no-scroll' : ''}`}
            >
              <div className='actions-buttons-container'>
                <div className='actions-button-row'>
                  {configurationParams?.shouldHideNewTask !== 'true' && (
                    <div className='action-button'>
                      <Button
                        onClick={() =>
                          setModalOpen(
                            modalOpen === 'new-message' ? '' : 'new-message',
                          )
                        }
                        className={`${modalOpen === 'new-message' ? 'active' : ''}`}
                      >
                        <i className='ri-at-line' />
                        {t('profile.actionButtons.message')}
                      </Button>
                    </div>
                  )}
                  {permissions?.sharedNotes && (
                    <div className='action-button'>
                      <Button
                        onClick={() => {
                          setModalOpen(
                            modalOpen === 'shared-note' ? '' : 'shared-note',
                          );
                        }}
                        className={`${modalOpen === 'shared-note' ? 'active' : ''}`}
                      >
                        <i className='ri-sticky-note-add-line' />
                        {t('profile.actionButtons.shared-note')}
                      </Button>
                    </div>
                  )}
                  {configurationParams?.shouldHideNewActivity !== 'true' && (
                    <div className='action-button'>
                      <Button
                        onClick={() =>
                          setModalOpen(
                            modalOpen === 'new-activity' ? '' : 'new-activity',
                          )
                        }
                        className={`${modalOpen === 'new-activity' ? 'active' : ''}`}
                      >
                        <i className='ri-add-box-line' />
                        {t('profile.actionButtons.logActivity')}
                      </Button>
                    </div>
                  )}

                  {configurationParams?.shouldHideNewEmail !== 'true' && (
                    <div className='action-button'>
                      <Button
                        onClick={() =>
                          setModalOpen(modalOpen === 'tasks' ? '' : 'tasks')
                        }
                        className={`${modalOpen === 'tasks' ? 'active' : ''}`}
                      >
                        <i className='ri-todo-line' />
                        {t('profile.actionButtons.task')}
                      </Button>
                    </div>
                  )}
                </div>
                <div className='actions-button-row'>
                  {hasWhatsappAutomation && (
                    <div className='action-button'>
                      <Button
                        onClick={() => {
                          setMessageMetadata([{ key: 'canSend', value: true }]);
                          setModalOpen(
                            modalOpen === 'unipile-whatsapp'
                              ? ''
                              : 'unipile-whatsapp',
                          );
                        }}
                        className={`${
                          modalOpen === 'unipile-whatsapp' ? 'active' : ''
                        }`}
                      >
                        <i className='ri-whatsapp-line' />
                        {t(
                          'profile.contact.timeline.newMessage.unipile-whatsapp.btn',
                        )}
                      </Button>
                    </div>
                  )}
                  {hasLinkedinAutomation && (
                    <div className='action-button'>
                      <Button
                        onClick={() => {
                          setMessageMetadata([{ key: 'canSend', value: true }]);
                          setModalOpen(
                            modalOpen === 'unipile-linkedin-message'
                              ? ''
                              : 'unipile-linkedin-message',
                          );
                        }}
                        className={`${
                          modalOpen === 'unipile-linkedin-message'
                            ? 'active'
                            : ''
                        }`}
                      >
                        <i className='ri-linkedin-box-line' />
                        {t(
                          'profile.contact.timeline.newMessage.unipile-linkedin.btn',
                        )}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              {!_.isEmpty(modalOpen) && (
                <div className='exit-action-modal'>
                  <Button onClick={() => setModalOpen('')}>
                    <i className='ri-close-fill ri-xl' />
                  </Button>
                </div>
              )}

              {!_.isEmpty(modalOpen) && <div className='dark-overlay' />}
              <div
                style={{ marginTop: allActionButtonsHidden ? '0em' : '5em' }}
              >
                {(isRecommendedProfileModal || isApplicantProfileModal) &&
                  !profileWasAccepted && (
                    <div className='buttons-container'>
                      <div className='label'>
                        {t(
                          `reveal.recommendedProfiles.${
                            isApplicantProfileModal
                              ? 'applicationReview'
                              : 'recommendationReview'
                          }`,
                        )}
                      </div>
                      {recommendationBlurb ? (
                        <DynamicCandidateRecommendationBlurb
                          profileId={profileId}
                          recommendationBlurb={recommendationBlurb}
                        />
                      ) : (
                        ''
                      )}
                      <div className='buttons'>
                        {(recommendationIsActive || isApplicantProfileModal) &&
                          !profileWasSkipped && (
                            <ProfileRow.SkipAction
                              isApplicationAction={isApplicantProfileModal}
                              onSkip={(
                                reason: any,
                                message?: SkipProfileMessage,
                              ) => {
                                if (!skipProfile) {
                                  console.error(
                                    'missing skipProfile function props',
                                  );
                                  return;
                                }
                                skipProfile(
                                  searchPoolProfile.id,
                                  reason,
                                  message,
                                );
                              }}
                              skipLoading={skipLoading}
                            />
                          )}
                        <ProfileRow.Action
                          onClick={(e) => {
                            e.stopPropagation();
                            setDisplayAddSequenceModal(true);
                          }}
                        >
                          <i className='ri-thumb-up-line' />
                          {t('reveal.recommendedProfiles.confirm.trigger')}
                        </ProfileRow.Action>

                        {displayAddSequenceModal && clientSequences && (
                          <ProfileRow.AddToSequenceModal
                            jobId={jobId}
                            onSubmit={(selectedSequenceId): void =>
                              handleConfirmProfileSubmit(selectedSequenceId)
                            }
                            onClose={(): void =>
                              setDisplayAddSequenceModal(false)
                            }
                            absolutePositionStyles={{
                              right: '2px',
                              top: '41px',
                            }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                {modalOpen === 'shared-note' && (
                  <SharedNoteModal
                    profileId={profileId}
                    onClose={() => setModalOpen('')}
                  />
                )}
                {_.includes(
                  ['unipile-whatsapp', 'unipile-linkedin-message'],
                  modalOpen,
                ) && (
                  <RevealCandidateViewNewMessage
                    clientId={clientId}
                    profile={searchPoolProfile}
                    messageType={modalOpen}
                    metadata={messageMetadata || []}
                    onClose={() => setModalOpen('')}
                    profileName={`${resumeData?.firstname} ${resumeData?.lastname}`}
                    profilePhoneNumber={resumeData?.phoneNumber}
                  />
                )}
                {modalOpen === 'new-activity' && (
                  <RevealCandidateViewNewActivity
                    clientId={clientId}
                    setIsOpen={setModalOpen}
                  />
                )}
                {modalOpen === 'new-message' && (
                  <ContactCategoryContext.Provider
                    value={searchPoolProfile.contactCategory?.type || 'human'}
                  >
                    <RevealCandidateViewNewMessage
                      clientId={clientId}
                      profile={searchPoolProfile}
                    />
                  </ContactCategoryContext.Provider>
                )}
                {modalOpen === 'tasks' && (
                  <RevealCandidateViewTasks
                    clientId={clientId}
                    setIsOpen={setModalOpen}
                  />
                )}
                {!notHaveRevealPendingActions && (
                  <RevealCandidateViewPendingActions
                    searchPoolId={searchPoolId}
                    clientId={clientId}
                    profileId={searchPoolProfile?.id}
                    task={currentSequenceTask}
                    currentSequenceInfo={searchPoolProfile?.currentSequenceInfo}
                    skipFirst={false}
                    shouldHideFinalSeparator={
                      configurationParams?.shouldDisplayProfileTimelineOnLeftPane ===
                      'true'
                    }
                  />
                )}
                {!_.isEmpty(sortedExplicitTasks) && (
                  <div className='reveal-pending-actions candidate-explicit-tasks'>
                    {_.map(sortedExplicitTasks, (explicitTask) => {
                      return (
                        <TaskLink
                          key={explicitTask?.id}
                          clientId={clientId}
                          profileId={profileId}
                          task={explicitTask}
                          sequence={null}
                        />
                      );
                    })}
                  </div>
                )}
                {configurationParams?.shouldDisplayProfileTimelineOnLeftPane !==
                  'true' && (
                  <RevealCandidateViewTimeLineItems
                    clientId={clientId}
                    searchPoolId={searchPoolId}
                    hideSourced
                    loading={loadingProfile}
                    sequenceOwner={
                      searchPoolProfile?.currentSequenceInfo?.author
                    }
                    openMessageModal={(
                      type?: string,
                      metadata?: { key: string; value: string | boolean }[],
                    ) => {
                      if (
                        !type ||
                        typeof type !== 'string' ||
                        type === 'email'
                      ) {
                        setModalOpen('new-message');
                        setMessageMetadata([]);
                      } else if (
                        _.includes(
                          ['unipile-whatsapp', 'unipile-linkedin-message'],
                          type,
                        )
                      ) {
                        setModalOpen(type);
                        setMessageMetadata(metadata || []);
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CandidateViewWithContext: FC<CandidateViewProps> = (props) => (
  <CandidateViewControlsContextProvider>
    <CandidateView {...props} />
  </CandidateViewControlsContextProvider>
);

export default CandidateViewWithContext;
