import { getRandomString } from "..";
import replaceLinksForTracking from "../replaceLinksForTracking";

const TRACKING_DOMAIN = 'https://skj.shortylink.site';

export const getInstantiatedFooter = ({
  footer,
  clientId,
  profileId,
}: {
  footer?: string;
  clientId: string;
  profileId: string;
}) => {
  if (!footer) {
    return footer;
  }
  const trackingMessageId = btoa(`${clientId}:::${getRandomString(12)}`);

  return replaceLinksForTracking(footer, {
    profileId,
    trackingDomain: TRACKING_DOMAIN,
    messageId: trackingMessageId,
  }).body;
}