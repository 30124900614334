import { TranslatableText } from '@/types/text';
import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from './ProfileSharedFields.module.less';

export type ProfileMissionInfo = {
  missionId: string;
  insertionDate?: string;
  data?: ProfileMissionInfoData;
};

type ProfileMissionInfoData = {
  sharedFields?: SharedField[];
  archived: boolean;
  segmentationStates: SegmentationState[];
  comment?: string;
};

type SegmentationState = {
  state: string;
};

export type SharedFieldText = SharedFieldBase & {
  type: 'text';
  text: string;
};

export type SharedFieldEnum = SharedFieldBase & {
  type: 'enum';
  selected: string[];
};

export type SharedField = SharedFieldText | SharedFieldEnum;

type SharedFieldBase = {
  clientSharedFieldId: string;
  __on?: {
    TextSharedField: {
      text: string;
    };
    EnumSharedField: {
      selected: string[];
    };
  };
};

export type SharedFieldDefinition = SharedFieldDefinitionBase &
  (TextSharedFieldDefinition | EnumSharedFieldDefinition);

type SharedFieldDefinitionBase = {
  id: string;
  name: TranslatableText;
};

type TextSharedFieldDefinition = {
  type: 'text';
};

type EnumSharedFieldDefinition = {
  type: 'enum';
  options: EnumSharedFieldOption[];
};

export type EnumSharedFieldOption = {
  id: string;
  label: TranslatableText;
};

type Props = {
  missionsInfo: ProfileMissionInfo[];
};

const ProfileSharedFields = ({ missionsInfo }: Props) => {
  const { t } = useTranslation();

  const sharedFields = missionsInfo.flatMap(
    (missionInfo) => missionInfo.data?.sharedFields || [],
  );

  if ((sharedFields || []).length === 0) {
    return null;
  }

  return (
    <div className={styles.profileSharedFieldsContainer}>
      <h4>{t('reveal.profileModal.sharedFields.title')}</h4>
      {sharedFields.map((sharedField) => (
        <div
          key={sharedField.clientSharedFieldId}
          className={styles.profileSharedField}
        >
          <div className={styles.profileSharedFieldName}>
            {sharedFieldNameById?.[sharedField?.clientSharedFieldId]?.default}
          </div>
          <div className={styles.profileSharedFieldValue}>
            {sharedField.type === 'text' && <div>{sharedField.text}</div>}
            {sharedField.type === 'enum' && (
              <div>
                {sharedField.selected
                  .map(
                    (value) =>
                      enumSharedFieldOptionsById?.[
                        sharedField.clientSharedFieldId
                      ]?.find((option) => option.id === value)?.label?.default,
                  )
                  .join(', ')}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const enumSharedFieldOptionsById: Record<
  string,
  EnumSharedFieldOption[]
> = {
  '3-tier-rating': [
    { id: 'i', label: { default: 'Tier 1' } },
    { id: 'ii', label: { default: 'Tier 2' } },
    { id: 'iii', label: { default: 'Tier 3' } },
  ],
  'acme-shared-field-KSAO10': [
    { id: 'ok', label: { default: '🟢' } },
    { id: 'neutral', label: { default: '🟠' } },
    { id: 'ko', label: { default: '🔴' } },
  ],
};

const sharedFieldNameById: Record<string, TranslatableText> = {
  '3-tier-rating': {
    default: 'Rating',
    fr: 'Note',
    en: 'Rating',
    de: 'Bewertung',
  },
  'acme-shared-field-KSAO10': {
    default: 'Status',
    fr: 'Statut',
    en: 'Status',
    de: 'Status',
  },
  'feedback-comment': {
    default: 'Comment',
    fr: 'Commentaire',
    en: 'Comment',
    de: 'Kommentar',
  },
};

export default ProfileSharedFields;
