import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';
import '../../../../../containers/Parameters/RevealIntegrations/RevealIntegration/Instructions.css';

const ImportFromLinkedInTutorial = () => {
  const { i18n } = useTranslation();

  return (
    <div className='instructions'>
      {i18n.resolvedLanguage?.slice(0, 2) === 'fr' ? (
        <>
          <h3>
            <span>1</span> Sur LinkedIn, aller dans le menu{' '}
            <b>Préférences et confidentialité</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step1_fr.png'
              fluid
            />
          </div>
          <p>
            Vous pouvez vous y rendre en cliquant directement{' '}
            <a
              href='https://www.linkedin.com/psettings/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BBKW78s8NRfOWxzpygvoB6w%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_feed-nav_settings_account_manage_account'
              target='_blank'
              rel='noopener noreferrer'
            >
              ici
            </a>
            .
          </p>
          <br />
          <h3>
            <span>2</span> Cliquez sur <b>Obtenir une copie de vos données</b>{' '}
            et demandez l’export
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step2_fr.png'
              fluid
            />
          </div>
          <p>
            Ouvrez le sous-menu <b>Obtenir une copie de vos données</b>, puis
            téléchargez vos relations en sélectionnant l’option{' '}
            <b>Mes relations</b> et cliquez sur <b>Demander les archives</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Téléchargez le fichier CSV de vos relations depuis
            votre boîte e-mail
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step3_fr.png'
              fluid
            />
          </div>
          <p>
            Le fichier CSV contenant toutes vos relations arrivera sur la boîte
            e-mail liée à votre compte LinkedIn.
          </p>
          <br />
          <p>
            Vous pouvez maintenant sélectionner dans ce fichier vos relations
            pertinentes et importer le fichier CSV sur HireSweet en cliquant sur{' '}
            <b>Importer des contacts</b> puis <b>Choisir un document</b>.
          </p>
        </>
      ) : (
        <>
          <h3>
            <span>1</span> On LinkedIn, go to <b>Settings & Privacy</b>
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step1_en.png'
              fluid
            />
          </div>
          <p>
            You can access this page directly by clicking on this{' '}
            <a
              href='https://www.linkedin.com/psettings/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BBKW78s8NRfOWxzpygvoB6w%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_flagship3_feed-nav_settings_account_manage_account'
              target='_blank'
              rel='noopener noreferrer'
            >
              link
            </a>
            .
          </p>
          <br />
          <h3>
            <span>2</span> Click on <b>Get a copy of your data</b> and request
            the export
          </h3>
          <div className='image-container'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step2_en.png'
              fluid
            />
          </div>
          <p>
            Open the menu <b>Get a copy of your data</b>, then download your
            contacts by selecting <b>My connections</b> and click on{' '}
            <b>Request archive</b>.
          </p>
          <br />
          <h3>
            <span>3</span> Download the requested connections CSV from your
            email inbox
          </h3>
          <div className='image-container full-screen'>
            <Image
              src='/images/screenshots/linkedin-import-instructions/step3_en.png'
              fluid
            />
          </div>
          <p>
            The CSV file containing all your connections will be sent to the
            email address associated to your LinkedIn account.
          </p>
          <br />
          <p>
            You can now select the relevant connections in this file and upload
            your CSV file to HireSweet by clicking on <b>Import contacts</b> and{' '}
            <b>Select a file</b>.
          </p>
        </>
      )}
    </div>
  );
};

export default ImportFromLinkedInTutorial;
