import _ from 'underscore';
import { TFunction } from 'i18next';

import {
  ArchivedState,
} from '@/components/PipelineSegmentation/pipelineSegmentation';
import { sumNumberRecords } from '@/common/helpers/stats';
import {
  PipelineCountDataPointForCategory,
  PipelineDescriptor,
  SeparatedCountsByPipelineState,
  SeparatedCountsByPipelineStateByCategory,
} from '../types';


const getInitialSeparatedCountsByPipelineState = (): SeparatedCountsByPipelineState => ({
  nonArchived: {},
  archived: {},
});

const sumSeparatedCountsByPipelineState = (
  a: SeparatedCountsByPipelineState,
  b: SeparatedCountsByPipelineState,
): SeparatedCountsByPipelineState => ({
  nonArchived: sumNumberRecords(a.nonArchived, b.nonArchived),
  archived: sumNumberRecords(a.archived, b.archived),
});

export const formatPipelineCountDataPoints = (
  datapoints: PipelineCountDataPointForCategory[],
  pipelineDescriptor: PipelineDescriptor,
): SeparatedCountsByPipelineStateByCategory => {
  const firstInterestedStepId = _.find(_.pluck(pipelineDescriptor.steps, 'id'), (id) => id.includes('interested'));
  return _.reduce(
    datapoints,
    (result, { categoryId, data: datapoint }) => {
      const counts = getInitialSeparatedCountsByPipelineState();
      _.each(datapoint, ({ state, archived, count }) => {
        const realState = state === 'interested_' ? (firstInterestedStepId ?? state) : state;
        if (archived) {
          counts.archived[realState] = (counts.archived[realState] || 0) + count;
        } else {
          counts.nonArchived[realState] = (counts.nonArchived[realState] || 0) + count;
        }
      });
      return {
        ...result,
        [categoryId]: counts,
      };
    },
    {} as SeparatedCountsByPipelineStateByCategory,
  )
};

export const getConversionRates = (t: TFunction) => (
  stats: Record<string, number>,
  pipelineDescriptor: PipelineDescriptor
): Record<string, string> => {
  return _.chain(
    _.map(pipelineDescriptor.steps.slice(0, -2), ({ id }, index) => (
      (stats[pipelineDescriptor.steps[index + 1]!.id] || 0) / (stats[id] || 1)
    ))
  )
    .map((rate) => Math.round(100 * rate))
    .map((rate) => `${rate}${t('reveal.reports.percentageSuffix')}`)
    .map((rate, index) => [pipelineDescriptor.steps[index]!.id, rate])
    .object()
    .value()
};

export const flattenSeparatedCountsByPipelineState = (
  data: SeparatedCountsByPipelineStateByCategory,
): SeparatedCountsByPipelineState =>
  _.reduce(
    data,
    (result, counts) => sumSeparatedCountsByPipelineState(result, counts),
    getInitialSeparatedCountsByPipelineState(),
  );

export const getEverCountsBySegment = (
  stats: SeparatedCountsByPipelineState,
  pipelineDescriptor: PipelineDescriptor
): Record<string, number> => {
  const result: Record<string, number> = {
  };

  const { archived, nonArchived } = stats;
  const { steps } = pipelineDescriptor;

  _.each(steps, ({ id }, index) => {
    for (let i = index; i < steps.length; i += 1) {
      result[id] = (result[id] ?? 0) + (nonArchived[steps[i]!.id] ?? 0);
      result[id] = (result[id] ?? 0) + (archived[steps[i]!.id] ?? 0);
    }
  });

  _.each(_.values(archived), (count) => {
    result[ArchivedState.ARCHIVED] = (result[ArchivedState.ARCHIVED] ?? 0) + count;
  });

  return result;
};

export const getCurrentCountsBySegment = (
  stats: SeparatedCountsByPipelineState,
): Record<string, number> => {
  return {
    ...stats.nonArchived,
    [ArchivedState.ARCHIVED]: _.reduce(
      stats.archived,
      (total, stat) => total + stat,
      0,
    ),
  };
};
