import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import _ from 'underscore';
import { useTranslation } from 'react-i18next';
import { Segment, Dropdown, Form } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';

import './InformationsTab.css';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomInput } from '@/containers/Parameters/OfferCriteria/CustomFormItems';
import useSearchPoolJob from '@/graphql/hooks/searchPoolJobs/useSearchPoolJob';
import { useClientMissionCustomFields } from '@/graphql/hooks/clients/useClientMissionCustomFields';
import { ArchiveIcon, UnarchiveIcon } from '@/assets/icons';
import ConfirmationModal from '@/components/modals/ConfirmationModal';
import {
  ARCHIVE_SEARCH_POOL_JOB,
  UNARCHIVE_SEARCH_POOL_JOB,
  UPDATE_SEARCH_POOL_JOB,
} from '@/graphql/searchPoolJob';
import useNotificationSystem from '@/hooks/common/useNotificationSystem';
import contextToProps from '@/hocs/contextToProps';
import useClientUsers from '@/graphql/hooks/clients/useClientUsers';
import GenericButton from '@/components/Common/GenericButton';
import { lowerCaseAndUnaccent } from '@/common';
import {
  combineDataWithCustomFields,
  createCustomFieldsPayload,
} from '@/common/customFields';
import { useMergedConfigurationParams } from '@/graphql/hooks/useMergedConfigurationParams';
import MissionCategoryContext from '@/context/MissionCategoryContext';
import useClientPermissions from '@/graphql/hooks/clients/useClientPermissions';
import MissionCustomFields from './MissionCustomFields/MissionCustomFields';
import MissionDepartmentsForm from './MissionDepartments';
import TargetsForm from './TargetsForm';

interface JobInformationsTabParams {
  clientId: string;
  searchPoolId: string;
  jobId: string;
}

const JobInformationsTab: React.FC<JobInformationsTabParams> = ({
  clientId,
  searchPoolId,
  jobId,
}) => {
  const { t } = useTranslation();
  const notification = useNotificationSystem();
  const [openModal, setOpenModal] = useState('');
  const [archiveMission] = useMutation(ARCHIVE_SEARCH_POOL_JOB);
  const [unArchiveMission] = useMutation(UNARCHIVE_SEARCH_POOL_JOB);
  const [editMission] = useMutation(UPDATE_SEARCH_POOL_JOB);
  const { data: jobData } = useSearchPoolJob('reveal', jobId);
  const [missionName, setMissionName] = useState(
    jobData?.searchPool?.job?.data?.title,
  );
  const [selectedFoldering, setSelectedFoldering] = useState({
    departmentId: jobData?.searchPool?.job?.foldering?.department?.id || '',
    sectionId: jobData?.searchPool?.job?.foldering?.section?.id || '',
    subsectionId: jobData?.searchPool?.job?.foldering?.subsection?.id || '',
  });
  const [linkedCompany, setLinkedCompany] = useState(
    jobData?.searchPool?.job?.targets?.companies?.[0]?.id,
  );
  const configurationParams = useMergedConfigurationParams();

  const [missionOwner, setMissionOwner] = useState(
    jobData?.searchPool?.job?.owner,
  );

  const [userOptions, setUserOptions] = useState([{}]);
  const { users } = useClientUsers(clientId);

  const { missionCustomFields } = useClientMissionCustomFields(clientId);

  const customFieldsInitialValues = useMemo(() => {
    const customDetails = combineDataWithCustomFields(
      missionCustomFields,
      jobData?.searchPool?.job,
    );

    return _.object(
      customDetails.map((o) => [o.clientCustomFieldId, o.rawValue ?? null]),
    );
  }, [missionCustomFields, jobData]);

  const formMethods = useForm({
    defaultValues: {
      customFields: customFieldsInitialValues,
    },
  });
  const { handleSubmit, reset } = formMethods;

  // Sadly, unless we load job & client mission fields in parent component
  // we need to use a useEffect to "reset" the form values to the initial values
  // https://github.com/react-hook-form/react-hook-form/discussions/9046
  useEffect(() => {
    reset({
      customFields: customFieldsInitialValues,
    });
  }, [reset, customFieldsInitialValues]);

  useEffect(() => {
    if (!users.length) return;
    const options = _.map(users, (user) => {
      return {
        key: user.id,
        text: `${user.firstname} ${user.lastname}`,
        value: user.email,
      };
    });
    setUserOptions(options);
  }, [users]);

  const onArchiveMission = async () => {
    try {
      const variables = { searchPoolId, input: { jobId } };
      await archiveMission({ variables });
      setOpenModal('');

      notification.success(t('reveal.missions.mission.modals.archiveSuccess'));

      // setRedirectTo(`/client/${clientId}/reveal/missions`);
    } catch (e) {
      console.error(e);

      notification.error(t('reveal.missions.mission.archiveError'));
    }
  };

  const onUnarchiveMission = async () => {
    try {
      const variables = { searchPoolId, input: { jobId } };
      await unArchiveMission({ variables });
      setOpenModal('');

      notification.success(
        t('reveal.missions.mission.modals.unarchiveSuccess'),
      );

      // setRedirectTo(`/client/${clientId}/reveal/missions`);
    } catch (e) {
      console.error(e);
      notification.error(t('reveal.missions.mission.modals.unarchiveError'));
    }
  };

  const onSave = useCallback(
    async (data) => {
      const { customFields: filledCustomFields } = data;
      try {
        const variables = {
          searchPoolId,
          input: {
            jobId,
            title: missionName,
            ...(missionOwner && {
              owner: {
                email: missionOwner?.email,
                firstname: missionOwner?.firstname,
                lastname: missionOwner?.lastname,
              },
            }),
            customFields: createCustomFieldsPayload(
              filledCustomFields,
              missionCustomFields,
            ),
            ...(selectedFoldering.departmentId && {
              departmentId: selectedFoldering.departmentId,
            }),
            ...(selectedFoldering.sectionId && {
              sectionId: selectedFoldering.sectionId,
            }),
            ...(selectedFoldering.subsectionId && {
              subsectionId: selectedFoldering.subsectionId,
            }),
            targets: {
              companies: linkedCompany ? [{ id: linkedCompany }] : [],
            },
          },
        };
        await editMission({ variables });

        notification.success(t('reveal.missions.mission.modals.editSuccess'));
      } catch (e) {
        console.error(e);

        notification.error(t('reveal.missions.mission.modals.editError'));
      }
    },
    [
      missionName,
      missionOwner,
      editMission,
      missionCustomFields,
      jobId,
      notification,
      searchPoolId,
      selectedFoldering,
      t,
      linkedCompany,
    ],
  );

  const missionCategory = useContext(MissionCategoryContext);
  const { permissions } = useClientPermissions(clientId);
  const { canHandleCompanyContacts } = permissions || {};

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(onSave)}>
        <Segment className='pure-criteria-form'>
          <div className='mission-settings-header'>
            <h2>{t('reveal.missions.mission.settingsTab.informations')}</h2>
            <GenericButton type='submit'>
              {t(
                'reveal.missions.mission.recommandations.calibration.saveButton',
              )}
            </GenericButton>
          </div>
          <div className='mission-settings'>
            <div className='mission-settings-fields'>
              <div className='form-box'>
                <div className='form-field-label'>
                  {t('reveal.missions.mission.settingsTab.missionName')}
                </div>
                <div className='form-field-input'>
                  <CustomInput
                    label='blacklistedCompanies'
                    currentValue={missionName}
                    onChange={(input: any) => setMissionName(input.value.value)}
                    placeholderText='Project name'
                  />
                </div>
              </div>
              <div className='form-box'>
                <div className='form-field-label'>
                  {t('reveal.missions.mission.settingsTab.owner')}
                </div>
                <div className='form-field-input'>
                  <Dropdown
                    selection
                    search={(options, query) => {
                      return _.filter(
                        options,
                        (option) =>
                          lowerCaseAndUnaccent(option.text as string).indexOf(
                            lowerCaseAndUnaccent(query),
                          ) >= 0,
                      );
                    }}
                    fluid
                    clearable
                    value={missionOwner?.email}
                    onChange={(e, data) => {
                      const newOwner = _.findWhere(users, {
                        email: data.value ? String(data.value) : undefined,
                      });
                      setMissionOwner(newOwner);
                    }}
                    // TODO: translations
                    options={userOptions}
                  />
                  {/* <CustomInput
                    label='Owner'
                    currentValue={`${missionOwner?.firstname} ${missionOwner?.lastname}`}
                    placeholderText='Owner'
                  /> */}
                </div>
              </div>
            </div>

            <MissionCustomFields
              customFields={missionCustomFields}
              disabled={
                configurationParams?.shouldDisableCustomFieldsEdition === 'true'
              }
            />
            <MissionDepartmentsForm
              foldering={selectedFoldering}
              onUpdateFoldering={setSelectedFoldering}
              disabled={
                configurationParams?.shouldDisableOrganisationEdition === 'true'
              }
            />

            {canHandleCompanyContacts && missionCategory === 'recruiting' && (
              <div className='mission-targets-container'>
                <h3>{t('reveal.missions.mission.settingsTab.targets')}</h3>
                <TargetsForm
                  linkedCompany={linkedCompany}
                  onLinkedCompany={setLinkedCompany}
                />
              </div>
            )}

            {configurationParams?.shouldHideArchiveProjectOption !== 'true' && (
              <div className='mission-settings-padding'>
                {jobData?.searchPool?.job?.isArchived ? (
                  <GenericButton
                    primacy='secondary'
                    onClick={() => setOpenModal('unarchive')}
                  >
                    <UnarchiveIcon className='btn-icon' />
                    {t('reveal.missions.mission.settingsTab.unarchiveMission')}
                  </GenericButton>
                ) : (
                  <GenericButton
                    primacy='secondary'
                    onClick={() => setOpenModal('archive')}
                  >
                    <ArchiveIcon className='btn-icon' />
                    {t('reveal.missions.mission.settingsTab.archiveMission')}
                  </GenericButton>
                )}
              </div>
            )}
          </div>
        </Segment>

        {openModal === 'archive' && (
          <ConfirmationModal
            open
            size='tiny'
            header={t('reveal.missions.mission.modals.archiveMissionTitle')}
            onSubmit={onArchiveMission}
            onCancel={() => setOpenModal('')}
          />
        )}

        {openModal === 'unarchive' && (
          <ConfirmationModal
            open
            size='tiny'
            header={t('reveal.missions.mission.modals.unarchiveMissionTitle')}
            onSubmit={onUnarchiveMission}
            onCancel={() => setOpenModal('')}
          />
        )}
      </Form>
    </FormProvider>
  );
};

export default _.compose(contextToProps)(JobInformationsTab);
