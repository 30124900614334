import React from 'react';
import { useTranslation } from 'react-i18next';

const Visual1 = () => {
  const { t, i18n } = useTranslation();

  const { resolvedLanguage } = i18n;

  const videoLink =
    resolvedLanguage?.slice(0, 2) === 'fr'
      ? 'https://www.loom.com/embed/3a7dd5daac564752a8f75e4f7ccaa7a0?sid=13346613-e29b-4ea5-b493-cd8707a9d9ae'
      : 'https://www.loom.com/embed/1756cdace3eb4175b587f014606c338a?sid=15169097-95fa-43bd-966b-7c73e704bbaa';

  return (
    <div className='visual1'>
      <div className='loom-embed'>
        <iframe
          title='loom-embed'
          src={videoLink}
          frameBorder='0'
          allowFullScreen
        />
      </div>
      <h3 className='embed-title'>
        {t('reveal.onboardingModal.visual1.caption')}
      </h3>
    </div>
  );
};

export default Visual1;
